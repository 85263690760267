import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { spliceDecimal } from "../../../../../../FunctionLibrary/FuntionList";
import { graphic } from 'echarts';

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false })
    }
    else {
      this.setState({ showChartList: true })
    }
  }

  handleChartType = (item) => {
    this.setState({ chartTypeName: item })
  }

  render() {
    let { CountRestrict, getDemographicIndexData, tempSortDistribution, currFilterName, getIndexScore, getIndexData, indexType } = this.props;

    let nameList = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];

    if (getIndexData) {
      nameList.push({
        value: "Overall",
        textStyle: { fontWeight: 'bold' }
      })
      weightage1.push({
        value: spliceDecimal(getIndexData["WellManagedStess"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#ffb3cc'
            },
            {
              offset: 1,
              color: '#ff4081'
            }])
        }
      });
      weightage2.push({
        value: spliceDecimal(getIndexData["OftenStressed"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#b3ffe2'
            },
            {
              offset: 1,
              color: '#00cd7e'
            }])
        }
      });
      weightage3.push({
        value: spliceDecimal(getIndexData["ModeratelyWell"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#fff0b3'
            },
            {
              offset: 1,
              color: '#ffcc00'
            }])
        }
      });
      weightage4.push({
        value: spliceDecimal(getIndexData["NeedImmediateattention"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#f1c0c0'
            },
            {
              offset: 1,
              color: '#e53935'
            }])
        }
      });
    }

    let tempSortData = tempSortDistribution;
    if (tempSortData && tempSortData.length > 0) {

      function showNaN(item, name) {
        let dataScore = CountRestrict(item.count) ? "NA" : spliceDecimal(item[name], 2);
        return dataScore
      }

      tempSortData.forEach((item) => {
        nameList.push({
          value: item.name,
          textStyle: { fontWeight: 'bold' }
        })
        weightage1.push({
          // value:(item["WellManagedStess"]).toFixed(2),
          value: showNaN(item, "WellManagedStess"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#ffb3cc'
              },
              {
                offset: 1,
                color: '#ff4081'
              }])
          }
        });
        weightage2.push({
          // value:(item["OftenStressed"]).toFixed(2),
          value: showNaN(item, "OftenStressed"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#b3ffe2'
              },
              {
                offset: 1,
                color: '#00cd7e'
              }])
          }
        });
        weightage3.push({
          // value:(item["ModeratelyWell"]).toFixed(2),
          value: showNaN(item, "ModeratelyWell"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#fff0b3'
              },
              {
                offset: 1,
                color: '#ffcc00'
              }])
          }
        });
        weightage4.push({
          // value:(item["NeedImmediateattention"]).toFixed(2),
          value: showNaN(item, "NeedImmediateattention"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#f1c0c0'
              },
              {
                offset: 1,
                color: '#e53935'
              }])
          }
        });
      })
    }


    return (

      <div className='overflow-y-auto min-h-auto max-h-[30rem]'>
        {true ?
          <>
            <ReactEcharts
              style={{
                height: (nameList.length < 4) ? '200px' : (nameList.length < 5) ? '300px' : (nameList.length > 20) ? (nameList.length * 60).toString() + 'px' : (nameList.length * 50).toString() + 'px',
              }}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  // formatter: function(a,b,c,d){
                  //   return (a && a[0] && a[0].name)?a[0].name+' = '+a[0].value+'%':''
                  // } 


                },
                legend: {
                  data: []
                },
              
                xAxis: {
                  type: 'value',
                  splitLine: {
                    lineStyle: {
                      color: '#f2f2f2'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#e6e6e6'
                    }
                  },
                  max: 100
                },
                yAxis: {
                  type: 'category',
                  data: nameList.reverse(),
                  axisLine: {
                    lineStyle: {
                      color: '#e6e6e6'
                    }
                  }
                },
                axisLabel: {
                  color: "#000",
                  interval: 0,
                  formatter: function (value) {
                    if (value.length > 15) {
                      return value.substring(0, 15) + "..";
                    } else {
                      return value;
                    }
                  }
                },
                series: [
                  {
                    name: 'WellManagedStess',
                    type: 'bar',
                    stack: '总量',
                    barWidth: '20px',
                    label: {
                      show: true,
                      position: 'insideRight',
                      color: "#000",
                      formatter: function (d) {
                        if (parseInt(d.value) < 7) {
                          return '';
                        }
                        else {
                          return d.value;
                        }
                      }
                    },
                    data: weightage1.reverse(),
                    inverse: true
                  },
                  {
                    name: 'OftenStressed',
                    type: 'bar',
                    stack: '总量',
                    barWidth: '20px',
                    label: {
                      show: true,
                      position: 'insideRight',
                      color: "#000",
                      formatter: function (d) {
                        if (parseInt(d.value) < 7) {
                          return '';
                        }
                        else {
                          return d.value;
                        }
                      }
                    },
                    data: weightage2.reverse(),
                    inverse: true
                  },
                  {
                    name: 'ModeratelyWell',
                    type: 'bar',
                    stack: '总量',
                    barWidth: '20px',
                    label: {
                      show: true,
                      position: 'insideRight',
                      color: "#000",
                      formatter: function (d) {
                        if (parseInt(d.value) < 7) {
                          return '';
                        }
                        else {
                          return d.value;
                        }
                      }
                    },
                    data: weightage3.reverse(),
                    inverse: true
                  },
                  {
                    name: 'NeedImmediateattention',
                    type: 'bar',
                    stack: '总量',
                    barWidth: '20px',
                    label: {
                      show: true,
                      position: 'insideRight',
                      color: "#000",
                      formatter: function (d) {
                        if (parseInt(d.value) < 7) {
                          return '';
                        }
                        else {
                          return d.value;
                        }
                      }
                    },
                    data: weightage4.reverse(),
                    inverse: true
                  }

                ],
                grid: {
                  left: '2%',
                  right: '2%',
                  bottom: '0%',
                  top: '10px',
                  containLabel: true,
                },
              }}
            />
          </>
          : null}
      </div>


    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
