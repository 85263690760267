import React, { Component } from "react";
import Header from "../Components/Header";

import BarIndexDistribution from "./chart/BarDistribution/BarIndexDistribution";
import HorizontalBarDistChart from "./chart/HorizontalBarChart/HorizontalBarDistChart";


// import HappinessHorizontalBarChart from "./chart/HorizontalBarChart/HappinessHorizontalBarChart";
// import EngagementHorizontalBarChart from "./chart/HorizontalBarChart/EngagementHorizontalBarChart";
// import StressHorizontalBarChart from "./chart/HorizontalBarChart/StressHorizontalBarChart";

// import CircleChart from "./chart/CircleChart";
// import ScoreCircleChart from "./chart/ScoreCircleChart";



// import HorizontalBarChart from "./chart/HorizontalBarChart/HorizontalBarChart";


import BarChart from "./chart/BarChart";
import GaugeScore from "./chart/GaugeScore";


export default class Engagement_Index_Report extends Component {
  render() {
    let {
      getOverallDistribution,
      getOverallDriver,
      getOverallIndexData,
      surveyLogo,
      isFilter,
      SummaryName,
      indexTemplate,
      getIndexV2Data,
      getIndexDistributionData,
      getIndexData,
      getAllBenchMarkData,
      getRespondantDetailsData,
      getEngagementIndexDriverData,
      GetParameterLabel,
      getAllBenchMarkStaticData,
      getSelectiveScores,
      surveyNAME,
      createdAt
    } = this.props;
    ////console.log("getRespondantDetailsData-->",getRespondantDetailsData);

    // ActivelyDisengaged: 4.436557231588288
    // Engagement: 78.88198757763976
    // HighlyEngaged: 27.24046140195209
    // ModeratelyEngaged: 51.641526175687666
    // Passive: 16.68145519077196

    let indexValue =
      getIndexData &&
        getIndexData[indexTemplate] &&
        parseFloat(getIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getIndexData[indexTemplate].toFixed(2))
        : 0;

    let indexOverallValue =
      getOverallIndexData &&
        getOverallIndexData[indexTemplate] &&
        parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        : 0;

    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true,
    };

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0];
      ParticipationObj["total"] = apiData["total"] ? apiData["total"] : 0;
      ParticipationObj["completed"] = apiData["completed"]
        ? apiData["completed"]
        : 0;
      ParticipationObj["mailSent"] = apiData["mailSent"]
        ? apiData["mailSent"]
        : 0;
      ParticipationObj["clicked"] = apiData["clicked"] ? apiData["clicked"] : 0;
      ParticipationObj["participated"] =
        apiData["completed"] && apiData["total"]
          ? ((apiData["completed"] * 100) / apiData["total"]).toFixed(2)
          : 0;
    }

    let indexType = 2;
    let Benchmark = 0;
    if (getAllBenchMarkData) {
      Benchmark =
        (typeof getAllBenchMarkData[indexType] !== "string" ||
          getAllBenchMarkData[indexType] instanceof Number) &&
          getAllBenchMarkData &&
          getAllBenchMarkData[indexType]
          ? getAllBenchMarkData[indexType].toFixed(2)
          : "";
    }

    let isScoreFormat = false;
    if (SummaryName === "Happiness" || SummaryName === "Stress") {
      isScoreFormat = true;
    }

    let getIndexDriverList = getEngagementIndexDriverData ? Object.keys(getEngagementIndexDriverData) : []


    return (
      <>
        {getIndexDriverList && getIndexDriverList.length > 0 &&
          <div className="relative py-6 px-8 page-break">
            <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
              <p class="font-medium italic text-base ">{surveyNAME}</p>
              <p class="font-medium italic text-base">{createdAt}</p>
            </div>
            <div className="">
              <Header surveyLogo={surveyLogo} />
              <div className>
                <div className="capitalize w-full   py-6 ">
                  <div className="text-indigo-700 opacity-90"></div>
                  <h1 className="text-2xl text-[#212121] font-medium  capitalize">
                    {SummaryName + " Summary"}
                  </h1>
                </div>
              </div>
            </div>
            <main className="w-full  grid grid-cols-2 gap-6 mx-auto pb-4">

              {true? (
                <div className=" col-span-2  space-y-4">
                  <h1 className="text-[#212121] font-medium text-xl capitalize">
                    {SummaryName + " Driver"}
                  </h1>


                  <HorizontalBarDistChart
                    getEngagementIndexDriverData={getEngagementIndexDriverData}
                    isFilter={isFilter}
                    GetParameterLabel={GetParameterLabel}
                    getOverallDriver={getOverallDriver}
                    getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                    getSelectiveScores={getSelectiveScores}
                  />

                  {/* 
                  {indexTemplate === 1 ? (
                    <HorizontalBarDistChart
                      getEngagementIndexDriverData={getEngagementIndexDriverData}
                      isFilter={isFilter}
                      GetParameterLabel={GetParameterLabel}
                      getOverallDriver={getOverallDriver}
                      getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                      getSelectiveScores={getSelectiveScores}

                    />
                  ) : null}

                  {indexTemplate === 2 ? (
                    <HorizontalBarDistChart

                      getEngagementIndexDriverData={getEngagementIndexDriverData}
                      isFilter={isFilter}
                      GetParameterLabel={GetParameterLabel}
                      getOverallDriver={getOverallDriver}
                      getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                      getSelectiveScores={getSelectiveScores}
                    />
                  ) : null}

                  {indexTemplate === 4 ? (
                    <HorizontalBarDistChart
                      getEngagementIndexDriverData={getEngagementIndexDriverData}
                      isFilter={isFilter}
                      GetParameterLabel={GetParameterLabel}
                      getOverallDriver={getOverallDriver}
                      getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                      getSelectiveScores={getSelectiveScores}
                    />
                  ) : null} */}


                </div>
              ) : null}
            </main>


          </div>}
      </>
    );
  }
}