import React, { Component } from "react";
import moment from "moment";
import Header from "../Components/Header";

export default class Engagement_Index_Report extends Component {
  render() {
    let {
      getEMPFilterLabel,
      FilterValues,
      CurrentSurveyId,
      surveyLogo,
      ReportFilterObj,
      surveyNAME,
      createdAt
    } = this.props;

    console.log("surveyNAME--------->", surveyNAME);


    let nameList = surveyNAME.split(" ")
    console.log("nameList--------->", nameList);

    return (
      <>
        <main className="bg-gradient-to-t from-[#F5F9FC] via-[#fff] to-[#F5F9FC]  ">
          <section className=" flex flex-col items-center justify-between space-y-10">
            <div className=" text-center ">
              {(CurrentSurveyId && CurrentSurveyId.toString() === "66263d1e9300643cda1e1d06") ?
                <div className="flex justify-between items-center pt-10 px-10 w-full"  >
                  <img
                    src={surveyLogo}
                    className="w-32 object-cover"
                    alt="default_survey_logo"
                  />
                  <img
                    src={"/img/managerreport/tata-logo2.png"}
                    className="h-12 object-cover"
                    alt="default_survey_logo"
                  />
                </div>
                :
                <div className="flex justify-center pt-10 px-10 w-full" >
                  <img
                    src={surveyLogo}
                    className="w-32 object-cover"
                    alt="default_survey_logo"
                  />
                </div>}

              <div className="space-y-14 text-center w-full pt-12">
                {nameList && nameList.length > 2 ?
                  <div className="!text-center flex flex-col justify-center items-center">

                    {surveyNAME ?
                      <div className="text-center text-[3rem] leading-none    text-[#212121] uppercase pt-4 font-bold  ">
                        {surveyNAME}
                      </div>
                      : null}

                    <div className="flex items-center justify-center space-x-2 pt-4">
                      <div className="w-2 h-2 rounded-full bg-[#2364AD]" />
                      <div className="w-32 h-0.5  bg-[#2364AD]">
                      </div>
                      <div className="text-4xl font-bold text-[#212121] px-2">
                        2024
                      </div>
                      <div className="w-32 h-0.5 bg-[#2364AD]">
                      </div>
                      <div className="w-2 h-2 rounded-full bg-[#2364AD]" />
                    </div>
                  </div>
                  :
                  <div className="!text-center flex flex-col justify-center items-center">
                    {nameList && nameList[0] ?
                      <div className="text-center text-[5rem] leading-none    text-[#212121] uppercase pt-4 font-bold  ">
                        {nameList && nameList[0]}
                      </div>
                      : null}
                    {nameList && nameList[1] ?
                      <div className="text-center text-[5rem] leading-none  text-[#2364AD] uppercase   ">
                        {nameList[1]}
                      </div>
                      : null}
                    <div className="flex items-center justify-center space-x-2 pt-4">
                      <div className="w-2 h-2 rounded-full bg-[#2364AD]" />
                      <div className="w-32 h-0.5  bg-[#2364AD]">
                      </div>
                      <div className="text-6xl font-bold text-[#212121] px-2">
                        2024
                      </div>
                      <div className="w-32 h-0.5 bg-[#2364AD]">
                      </div>
                      <div className="w-2 h-2 rounded-full bg-[#2364AD]" />
                    </div>
                  </div>
                }


                <div className=" flex justify-center items-center  relative  h-56 w-[80%] mx-auto p-2">

                </div>

              </div>
              <div class="text-center space-y-2  pt-14">
                {/* <p class="text-xl text-[#212121] font-bold">
                  HappyPlus Report
                </p> */}
                {/* <p class="text-base text-[#212121]/80">
                  This report is confidential and should not be distributed without
                  permission
                </p> */}
              </div>
              <div class="flex justify-center pt-6">
                <img
                  src="/img/managerreport/manager-report-cover.webp"
                  class="w-full h-full object-contain filter "
                />
              </div>
            </div>

          </section>
        </main>

      </>

    );
  }
}
