import * as React from 'react';

export default function IntroPage(props) {
  let { userName, createdAt, getSurveyData } = props;
  return (
    <>
      <section className="flex flex-col items-center justify-between space-y-10 ">
        <div className="flex flex-col items-center space-y-8 text-center">
          <div className="flex justify-center pt-6">
            <img src={"/img/logo/happypluslogo.png"} className="w-fit" />
          </div>
          <div className="py-2 space-y-4 text-center">
            <h1 className="text-5xl text-[#212121] font-normal text-center items-center justify-center uppercase ">{getSurveyData && getSurveyData.name ? getSurveyData.name : "360 Degree feedback survey"}</h1>
            <h1 className="text-lg font-medium text-[#212121]/80 italic">
              {userName}{" "}
              | {createdAt}
            </h1>
          </div>
          <div className="flex justify-center pt-6">
            <img src="/img/360feedback_
            cover.webp" className="w-full h-full mx-auto" />
          </div>
          <div className="text-center">
            <p className="text-lg text-[#212121] font-medium">
              {"Powered by HappyPlus © 2024"}
            </p>
            <p className="text-base font-medium text-[#212121]/70 italic">
              {"This report is confidential and should not be distributed without permission"}
            </p>
          </div>
        </div>
      </section>
    </>);
};