import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { spliceDecimal } from "../../../../../../FunctionLibrary/FuntionList";
import { graphic } from 'echarts';

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false })
    }
    else {
      this.setState({ showChartList: true })
    }
  }

  handleChartType = (item) => {
    this.setState({ chartTypeName: item })
  }

  render() {
    let { handleSelectEDA, tempSortData, currFilterName, getIndexScore, indexType, CountRestrict } = this.props;


    let nameList = []
    let scoreList = []

    if (getIndexScore) {
      nameList.push({
        value: "Overall Score",
        textStyle: { fontWeight: 'bold' }
      });
      scoreList.push({
        value: spliceDecimal(getIndexScore[indexType], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#62cff4'
            },
            {
              offset: 1,
              color: '#2c67f2'
            }])
        }
      })

    }




    if (tempSortData && tempSortData.length > 0) {
      tempSortData.forEach((data) => {
        let dataScore = CountRestrict(data.count) ? "NA" : spliceDecimal(data[indexType], 2);
        nameList.push({
          value: data.name,
          textStyle: { fontWeight: 'bold' }
        });
        scoreList.push({
          value: dataScore,
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#d9b9f8'
              },
              {
                offset: 1,
                color: '#8f34eb'
              }])
          }
        })

      })
    }


    function getCountByName(nameValue) {
      let count = 0;
      if (tempSortData && tempSortData.length > 0) {
        let getIndex = tempSortData.findIndex(prev => prev.name === nameValue);
        if (getIndex !== -1) {
          count = tempSortData && tempSortData[getIndex] && tempSortData[getIndex]["count"] ? tempSortData[getIndex]["count"] : 0
        }
      }
      return count
    }

    return ( 
      <div className='overflow-y-auto min-h-auto max-h-[30rem]'>
        {true ?
          <>
            <ReactEcharts
              onEvents={{
                'click': (params) => handleSelectEDA(params)
              }}
              style={{
                height: (nameList.length < 4) ? '200px' : (nameList.length < 5) ? '300px' : (nameList.length > 20) ? (nameList.length * 60).toString() + 'px' : (nameList.length * 50).toString() + 'px', 
              }}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: function (a, b, c, d) {
                    let nameLabel = a && a[0] && a[0].name ? a[0].name : "";
                    let valueLabel = a && a[0] && a[0].value ? a[0].value : "NA";
                    let valueScore = (nameLabel ? nameLabel + ' = ' + (valueLabel && valueLabel !== "NA" ? valueLabel + '%' : "NA") : '');
                    let htmlElement = '<p>' + valueScore + '</p>'
                    if (getCountByName(nameLabel)) {
                      htmlElement += '<p>' + 'Count: ' + getCountByName(nameLabel) + '</p>'
                    }
                    return htmlElement
                  }


                },
                legend: {
                  data: []
                },
                grid: {
                  left: '2%',
                  right: '2%',
                  bottom: '0%',
                  top:'10px',
                  containLabel: true,
                },
                xAxis: {
                  type: 'value',
                  splitLine: {
                    lineStyle: {
                      color: '#f2f2f2'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#e6e6e6'
                    }
                  },
                  max: 100
                },
                yAxis: {
                  type: 'category',
                  data: nameList.reverse(),
                  axisLine: {
                    lineStyle: {
                      color: '#e6e6e6'
                    }
                  }
                },
                axisLabel: {
                  color: "#000",
                  interval: 0,
                  formatter: function (value) {
                    if (value.length > 15) {
                      return value.substring(0, 15) + "..";
                    } else {
                      return value;
                    }
                  }
                },
                series: [
                  {
                    name: 'Score',
                    type: 'bar',
                    barWidth: '20px',
                    stack: '总量',
                    label: {
                      show: true,
                      position: 'insideRight',
                      color: "#ffffff"
                    },
                    data: scoreList.reverse()

                  },

                ]
              }}
            />
          </>
          : null}
      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
