import React, { Component } from "react";
import moment from "moment";
import Header from "../Components/Header";

export default class FilterSummary extends Component {
  render() {
    let {
      FilterValues,
      getEMPFilterLabel,
      surveyNAME,
      createdAt
    } = this.props;

    console.log("FilterValues--------->", FilterValues);

    return (

      <section className="py-6 px-8">
        {/* report header */}
        <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
        <p class="font-medium italic text-base ">{surveyNAME}</p>
            <p class="font-medium italic text-base">{createdAt}</p>
        </div>
        <div className="capitalize w-full py-6 space-y-4 flex flex-col">
          <h1 className="text-2xl text-[#212121] font-medium  capitalize w-full">
            Filter Summary
          </h1>
          <p className="text-[#212121]/80 italic">Selected filter name and it's values</p>

          <table className="divide-y-2 divide-white w-full">

            {FilterValues && FilterValues.length > 0 ?
              FilterValues.map((element) =>
                <tr className=" p-4 rounded-md space-y-4 odd:bg-[#F9F9F9] even:bg-[#EDEDED]">
                  <td className="p-2 px-4 border-r-2 border-white text-[#3D405B] font-medium w-4/12">{element.level}</td>
                  <td className="p-2 px-4 w-8/12">
                    <div className="flex flex-wrap gap-2 justify-start">
                      {element && element.valueList && element.valueList.length ?
                        element.valueList.map((element) =>
                          <div className="flex items-center flex-row gap-2 bg-[#E3E3E3] px-4 py-1 rounded-full">
                            <p className="text-sm font-medium">{element}</p></div>
                        ) : null}
                    </div>
                  </td>

                </tr>
              ) : null}


          </table>
        </div>
      </section>

    );
  }
}
