import React, { Component } from "react";
import { connect } from "react-redux";

function DevelopmentActionPlan(props) {
  let { userName, createdAt } = props;

  return (
    <section className="p-6 px-6 flex flex-col justify-between page-break">
      {/* report header */}
      <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
        <p className='font-medium italic text-base '>360 Feedback</p>
        <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
      </div>
      <div className="space-y-4 flex flex-col  ">
        <h1 className="text-2xl text-[#212121] font-medium capitalize">
          Personal Reflections
        </h1>

        <div className="text-slate-800 space-y-4 text-sm">
          <p className="italic " >
            This section is to help you reflect on your report and would be used as an aid
            aduring your one-on-one coaching session with your assigned coach. This reflection exercise should help you
            prioritize your strengths and areas of development as a first step to identifying and working on your development
            goals.
          </p>
        </div>

        <p className="text-base font-medium ">My reflections from the report:</p>


        <table className="w-full border">
          <thead>
            {/* <tr className=" ">
              <th className=""></th>
              <th className=""></th>
            </tr> */}
          </thead>
          <tbody>
            <tr>
              <td className="bg-gray-100 border">
                <p className="px-3 py-2 font-semibold">My Top 2 Strengths</p>
              </td>
              <td className="bg-gray-100 border">
                <p className="px-3 py-2 font-semibold">Why do I identify them as my Strengths</p>
              </td>
            </tr>
            <tr>
              <td className="bg-white border">
                <p className="px-3 py-4"></p>
              </td>
              <td className="bg-white border">
                <p className="px-3 py-4 font-semibold"></p>
              </td>
            </tr>
            <tr>
              <td className="bg-white border">
                <p className="px-3 py-4 font-semibold"></p>
              </td>
              <td className="bg-white border">
                <p className="px-3 py-4 font-semibold"></p>
              </td>
            </tr>
            <tr>
              <td className="bg-gray-100 border">
                <p className="px-3 py-2 font-semibold">My Top 2 Areas of Development</p>
              </td>
              <td className="bg-gray-100 border">
                <p className="px-3 py-2 font-semibold">Why do I identify them as my Areas of Development</p>
              </td>
            </tr>
            <tr>
              <td className="bg-white border">
                <p className="px-3 py-4 font-semibold"></p>
              </td>
              <td className="bg-white border">
                <p className="px-3 py-4 font-semibold"></p>
              </td>
            </tr>
            <tr>
              <td className="bg-white border">
                <p className="px-3 py-4 font-semibold"></p>
              </td>
              <td className="bg-white border">
                <p className="px-3 py-4 font-semibold"></p>
              </td>
            </tr>
          </tbody>
        </table>


        <p className="text-base font-medium ">My notes (Include your personal insights and thoughts along with any aspects that you would like to discuss with your coach):</p>


        <div className="space-y-10 py-10">
          <div className="border-b border border-gray-500"></div>
          <div className="border-b border border-gray-500"></div>
          <div className="border-b border border-gray-500"></div>
        </div>


        <p className="text-base font-medium text-center">Good Luck on your Development Journey!</p>



      </div>
    </section>
  );
}
export default DevelopmentActionPlan;
