import React from 'react';
import ReactEcharts from "echarts-for-react";
import OverallLabels from '../Components/OverallLabels';

export default function BarDistribution(props) {

  let { SummaryName, getSelectiveScores, getAllBenchMarkStaticData, List, labelObj, colorObj, DistributionData, getOverallDistribution } = props;


  let ScoreList = []

  if (DistributionData) {

    if (List && List.length > 0) {
      List.forEach((item) => {
        ScoreList.push({
          "overall": getOverallDistribution && getOverallDistribution[item] && parseFloat(getOverallDistribution[item]) ? parseFloat(getOverallDistribution[item]) : 0,
          "name": item,
          "score": DistributionData && DistributionData[item] && parseFloat(DistributionData[item]) ? parseFloat(DistributionData[item]) : 0
        })
      });
    }

  }

  let data = [];
  let names = []
  let Overall = [];


  let finalList = [];

  if (ScoreList && ScoreList.length > 0) {
    ScoreList.forEach((item) => {



      data.push({
        value: item.score && item.score.toFixed(2) ? item.score.toFixed(2) : 0,
        itemStyle: {
          color: colorObj && colorObj[item.name] ? colorObj[item.name] : '#757575'
        }
      })
      names.push(labelObj[item.name])

      Overall.push({
        "name": item.name,
        "score": item.overall,
        "color": colorObj && colorObj[item.name] ? colorObj[item.name] : '#757575'
      })


      finalList.push({
        label: labelObj[item.name],
        name: item.name,
        score: item && item.score && item.score.toFixed(2) ? item.score.toFixed(2) : 0,
        overall: item && item.overall && item.overall.toFixed(2) ? item.overall.toFixed(2) : 0,
        color: colorObj && colorObj[item.name] ? colorObj[item.name] : '#757575'
      })

    });
  }


  console.log("getAllBenchMarkStaticData--->", getAllBenchMarkStaticData)



  return (
    <>
      {/* <table className="w-full bg-[#F5F5F5]">
        <thead>
          <tr className="text-left text-[#212121] capitalize  border-b-2 border-white  text-sm  ">
            <th className="w-[30%] font-semibold p-2  text-left border-r-2 border-white">
              Dimension
            </th>
            <th className="w-[20%] font-semibold p-2 text-right border-r-2 border-white">
              Comparison
            </th>
            <th className="w-[50%] font-semibold p-2 text-left border-r-2 border-white  ">
              Score
            </th>
          </tr>
        </thead>
        <tbody className="divide-y-2 divide-white ">
          {finalList && finalList.length > 0 ?
            finalList.map((ele) =>
              <>
                <tr className="border-b bg-[#F5F5F5] text-[#212121] text-sm border-l border-r border-white ">
                  <td rowSpan={4} className="p-2 font-medium capitalize border-r-2 border-white ">
                    {ele && ele.label}
                  </td>
                  <td className="p-2 text-sm text-right border-r-2 border-white bg-[#E9E9E9]">
                    Score
                  </td>
                  <td className="p-2 text-sm text-right border-r-2 border-white bg-[#E9E9E9]">
                    <div className="h-6 w-full bg-[#CFD8DC]">
                      <div
                        className="w-full flex items-center h-6 bg-[#F5564A]" style={{ background: ele.color, width: ele && ele.score ? (parseFloat(ele.score)).toString() + "%" : "0%" }}
                      >
                        <span className="text-xs font-medium text-white px-2">
                          {ele && ele.score ? ele.score + "%" : "NA"}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-sm text-right border-r-2 border-white  ">
                    Overall
                  </td>
                  <td className="p-2 text-sm border-r-2 border-white">
                    <div className="h-6 w-full bg-[#CFD8DC]">
                      <div
                        className="w-full flex items-center  h-6 bg-[#FFC107]" style={{ background: ele.color, width: ele && ele.overall ? (parseFloat(ele.overall)).toString() + "%" : "0%" }}
                      >
                        <span className="text-xs font-medium text-white px-2">
                          {ele && ele.overall ? ele.overall + "%" : "NA"}
                        </span>
                      </div>
                    </div>

                  </td>
                </tr>

                <tr>
                  <td className="p-2 text-sm text-right border-r-2 border-white  ">
                    India HWA 2023
                  </td>
                  <td className="p-2 text-sm border-r-2 border-white">
                    <div className="h-6 w-full bg-[#CFD8DC]">
                      <div
                        className="w-full flex items-center  h-6 bg-[#FFC107]"
                        style={{
                          background: ele.color,
                          width: getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"] === "-" ? "0%" :
                            getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"]
                            + "%"
                        }}
                      >
                        <span className="text-xs font-medium text-white px-2">
                          {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"] === "-" ? "0%" :
                            getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"]
                            + "%"}
                        </span>
                      </div>
                    </div>

                  </td>
                </tr>

                <tr>
                  <td className="p-2 text-sm text-right border-r-2 border-white  ">
                    India
                  </td>
                  <td className="p-2 text-sm border-r-2 border-white">
                    <div className="h-6 w-full bg-[#CFD8DC]">
                      <div
                        className="w-full flex items-center  h-6 bg-[#FFC107]" style={{
                          background: ele.color,
                          width: getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"] === "-" ? "0%" :
                            getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"]
                            + "%"
                        }}
                      >
                        <span className="text-xs font-medium text-white px-2">
                          {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"] === "-" ? "0%" :
                            getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"]
                            + "%"}
                        </span>
                      </div>
                    </div>

                  </td>
                </tr>
              </>
            ) : null}


        </tbody>

      </table> */}

      <div className="mt-4">
        <table className="w-full ">
          <tbody className="divide-y-4 divide-white ">
            {finalList && finalList.length > 0 ?
              finalList.map((ele) =>
                (ele && ele.score) &&
                <>


                  <tr className="border-b  text-[#212121] text-sm border-l border-r border-white py-4">
                    <td className="w-[35%] text-sm text-right border-r-2 border-white  pr-4 ">
                      <b>{ele && ele.name ? labelObj[ele.name] : ''}</b>
                    </td>
                    <td className="flex text-sm border-r-2 border-white ">
                      <div className="flex h-6 w-full bg-[#212121]/10">
                        <div
                          className="w-full  items-center  h-6 bg-[#FFC107]" style={{ background: ele.color, width: ele && ele.score ? (parseFloat(ele.score)).toString() + "%" : "0%" }}
                        >
                        </div>

                      </div>
                      <span className="w-[12%] text-xs font-semibold  px-2 py-1">
                        <b>{ele && ele.score ? ele.score + "%" : "NA"}</b>
                      </span>
                    </td>
                  </tr>


                  {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"] ?
                    <tr>
                      <td className="w-[35%] text-sm text-right border-r-2 border-white  pr-4">
                        {"HW Benchmark - " + SummaryName}
                      </td>
                      <td className="flex text-sm border-r-2 border-white ">
                        <div className="flex h-6 w-full bg-[#212121]/10">
                          <div
                            className="w-full  items-center  h-6 bg-[#FFC107]" style={{ background: ele.color, width: getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"] ? getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"] + "%" : "0%" }}
                          >
                          </div>

                        </div>
                        <span className="w-[12%] text-xs font-semibold  px-2 py-1">
                          {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["HWScore"] + "%"}
                        </span>
                      </td>
                    </tr>
                    : null}



                  {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"] ?
                    <tr>
                      <td className="w-[35%]  text-sm text-right border-r-2 border-white  pr-4">
                        {"Overall Average - " + SummaryName}
                      </td>
                      <td className="flex text-sm border-r-2 border-white ">
                        <div className="flex h-6 w-full bg-[#212121]/10">
                          <div
                            className="w-full  items-center  h-6 bg-[#FFC107]" style={{ background: ele.color, width: getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"] ? getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"] + "%" : "0%" }}
                          >
                          </div>

                        </div>
                        <span className="w-[12%] text-xs font-semibold  px-2 py-1">
                          {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "distribution")["OverallScore"] + "%"}
                        </span>
                      </td>
                    </tr>
                    : null}

                  <tr>
                    <td className="w-[35%]  text-sm text-right border-r-2 border-white  pr-4">
                    </td>
                    <td className="flex text-sm border-r-2 border-white">
                      <div className="flex h-2 w-full ">
                        <div
                          className="w-full  items-center  h-2 bg-[#FFFFFF]" style={{}}
                        >
                        </div>

                      </div>
                      <span className="text-xs font-medium bg-[#FFFFFF]  px-2 py-1">
                      </span>
                    </td>
                  </tr>
                </>
              ) : null}


          </tbody>

        </table>

      </div>


      {/* <div className="!w-full">
        <ReactEcharts
          style={{
            height: '180px',
          }}
          option={{
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            grid: {
              left: '2%',
              right: '5%',
              bottom: '3%',
              top: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                data: names,
                axisTick: {
                  alignWithLabel: true
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                max: 100,
                splitLine: {
                  show: false
                },
              }
            ],
            series: [
              {
                name: 'Distribution',
                type: 'bar',
                barWidth: '30px',
                label: {
                  show: true,
                  position: 'top',
                  color: "#000",
                  textStyle: {
                    fontSize: 13,
                    fontWeight: 'bold'
                  },
                },
                data: data
              }
            ]
          }}
        />
      </div>


      <OverallLabels Overall={Overall} labelObj={labelObj} /> */}
    </>
  );
}
