import React from "react";
import moment from "moment";

export default function SummaryDetails(props) {

  let { EditButtonFn, EditDetailsFn, GetParameterLabel, getLeadershipReportScoreData, getLeadershipParaCategoryData, getLeadershipTextDetailsData } = props;
  let { userName, createdAt, domainThemeDetails } = props;


  function chunkList15(array) {
    const chunkSize = 15;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }


  function chunkList(array) {
    const chunkSize = 10;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }

  function BoldText(text) {
    return text ? '<b>' + text + '</b>' : text
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      //console.log("descX",descX)
      //console.log("temp",temp)
      descNew = temp.split("@").filter((prev) => prev !== "");
      // //console.log("descNew",descNew)
    }
    return descNew;
  }

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }



  console.log("--------------------...");
  console.log(getLeadershipReportScoreData, getLeadershipParaCategoryData);


  const getParaList = (validParaList, list, type) => {
    let paraNewList = []
    if (validParaList && validParaList.length > 0 && list && list.length > 0) {
      validParaList.forEach((ele) => {
        let getIndex = list.findIndex(prev => prev.parameterBucketName.toString() === ele.toString());
        if (getIndex !== -1) {
          let temp = {
            "bucket": ele,
            "dimensions": list[getIndex]["dimensions"]
          }
          paraNewList.push(temp);
        }
      });
    }
    if (type === "array") {
      return paraNewList
    } else {
      return paraNewList && paraNewList.length > 0 ? paraNewList.length : 0
    }
  }

  let orderArray = ["SELF", "OTHERS", "TEAM", "RESULTS", "COMMUNITY"];
  let finalList = []
  if (getLeadershipParaCategoryData && getLeadershipParaCategoryData.length > 0) {
    finalList = getLeadershipParaCategoryData.map((ele) => ({
      "category": ele._id,
      "parameters": getParaList(ele.parameters, getLeadershipReportScoreData, "array"),
      "length": getParaList(ele.parameters, getLeadershipReportScoreData, "length") + 1
    })).sort((a, b) => {
      return orderArray.indexOf(a.category) - orderArray.indexOf(b.category);
    });
  }


  console.log("finalList----->", finalList)

  const getLabelScore = (inner, index, type) => {
    if (inner && inner.dimensions && inner.dimensions.length > 0) {
      let data = inner && inner.dimensions && inner.dimensions[index] ? inner.dimensions[index] : {};
      if (type === "name") {
        return data && data["parameterDimensionName"] ? data["parameterDimensionName"] : ""
      }
      else if (type == "score") {
        let total = 0;
        let sum = 0;
        if (data && data.answers && data.answers.length > 0) {
          data.answers.forEach((ans) => {
            sum += Number(ans.answer) * Number(ans.count);
            total += (6 * Number(ans.count));
          })
        }
        return (total > 0 ? ((sum * 100) / total).toFixed(0) : 0)
      }
    }
    return ""
  }

  const calc_total_score = (inner, type) => {
    let total = 0;
    let sum = 0;

    if (inner && inner.dimensions && inner.dimensions.length > 0) {

      [0, 1].forEach((k_, index) => {
        let data = inner && inner.dimensions && inner.dimensions[index] ? inner.dimensions[index] : {};
        if (data && data.answers && data.answers.length > 0) {
          data.answers.forEach((ans) => {
            sum += Number(ans.answer) * Number(ans.count);
            total += (6 * Number(ans.count));
          })
        }
      })

    }

    if (type === "rounded") {
      return (total > 0 ? ((sum * 100) / total).toFixed(0) : 0)
    } else {
      return (total > 0 ? ((sum * 100) / total) : 0)
    }
  }

  const sortListByScore = (parameterList) => {
    if (parameterList && parameterList.length > 0) {
      return parameterList.sort((b, a) => (calc_total_score(a) - calc_total_score(b)))
    }
    return []
  }

  const getBucketScore = (parameterList) => {
    if (parameterList && parameterList.length > 0) {
      parameterList.map(() => { })
      return parameterList.sort((b, a) => (calc_total_score(a) - calc_total_score(b)))
    }
    return []
  }

  const getInnerDetails = (paraDimen, list) => {
    console.log("------------------Top------------------")
    console.log(paraDimen, list)
    console.log("-------------------End-----------------")
    if (list && list.length > 0) {
      let getIndex = list.findIndex((prev => prev.parameterDimensionName.toString() === paraDimen.toString()))
      if (getIndex !== -1) {
        return list[getIndex]
      }
    }
    return {}
  }
  const getWithDetails = (dimenList, detailList) => {

    if (dimenList && dimenList.length > 0) {
      return dimenList.map((dimen) => ({
        "parameterDimensionName": dimen.parameterDimensionName,
        "details": getInnerDetails(dimen.parameterDimensionName, detailList)
      }))
    }
    return []
  }

  const elements4 = Array.from({ length: 1 });


  const getMatchCategory = (category, list, field) => {
    const capitalizeWords = (str) => {
      function capitalize(str) {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      }
      return str.split(' ').map(word => capitalize(word)).join(' ');
    }

    if (field === "textColor") {
      // if ("Leading " + capitalizeWords(category) === "Leading Self") {
      //   return "#000000"

      // } else {
      //   return "#FFFFFF"

      // }

      return "#212121"

    } else {

      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.attribute === "Leading " + capitalizeWords(category));
        if (getIndex !== -1) {
          return list[getIndex][field]
        }
      }
    }
    return ""
  }



  const normalisedScore = (inner, index, type) => {
    let scoreX = getLabelScore(inner, index, type);
    let score1 = getLabelScore(inner, 0, type);
    let score2 = getLabelScore(inner, 1, type);

    let newScore = (Number(scoreX) * 100) / (Number(score1) + Number(score2));
    return newScore > 0 ? newScore.toFixed(0) : 0
  }


  const visibleContent = (inner, index, type) => {
    let scoreX = normalisedScore(inner, index, type);
    let score1 = normalisedScore(inner, 0, type);
    let score2 = normalisedScore(inner, 1, type);

    if ((Number(score1) === Number(score2)) && !index) {
      return 2
    } else if (Number(scoreX) > 50) {
      return 1
    } else {
      return 0
    }

  }

  const getCommonContent = (inner) => {
    let element = {}
    if (inner && inner.dimensions && inner.dimensions.length > 0) {
      getWithDetails(inner.dimensions, getLeadershipTextDetailsData).forEach((ele, dimen_index) => {
        if (ele && ele.details && ele.details.isCommon) {
          element = ele;
        }
      })

    }
    return element
  }



  return (
    <>
      {finalList && finalList.length > 0 && finalList.map((category, cat_index) =>
        <>
          {category && category.parameters && category.parameters.length > 0 && category.parameters.map((inner, para_index) =>
            <>
              {inner && inner.dimensions && inner.dimensions.length > 0 && getWithDetails(inner.dimensions, getLeadershipTextDetailsData).map((element, dimen_index) =>

                <>
                  {(visibleContent(inner, dimen_index, "score") === 1) &&
                    <main className="p-10 px-12 space-y-6 bg-white page-break">
                      <div className='page-break space-y-3'>
                        <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
                          <h1 className="text-lg font-semibold">Leadership Report</h1>
                          <p className="text-lg font-semibold">{userName + " | " + createdAt}</p>
                        </div>
                        <table className="w-full bg-[#F1F5F9] pt-5 mt-5">
                          <tbody className=" ">
                            {elements4.map((_, index) => (
                              <>
                                <tr className="text-sm bg-[#F1F5F9]">
                                  <td className="py-1 px-4 border-r-2 border-b-2 border-white text-lg font-bold w-44 text-left">Domains</td>
                                  <td className="py-1 px-0 w-fit border-b-2 border-white bg-[#FFC107]" style={{ color: getMatchCategory(category.category, domainThemeDetails, "textColor"), background: getMatchCategory(category.category, domainThemeDetails, "colorOne") }}>
                                    <div className="space-y-2 px-4 flex flex-col">
                                      <span className="pr-2 font-bold text-xl"> {"LEADING " + category.category}</span>
                                      <span className="text-sm">
                                        {getMatchCategory(category.category, domainThemeDetails, "description")}
                                      </span>
                                    </div>
                                  </td>
                                </tr>

                                <tr className="text-sm bg-[#F1F5F9]">
                                  <td className="py-1 px-4 border-r-2 border-b-2 border-white text-lg font-bold w-44 text-left whitespace-nowrap">Sub-Domains</td>
                                  <td className="px-4 w-fit border-b-2 border-white bg-[#FFF8E1]" style={{ background: getMatchCategory(category.category, domainThemeDetails, "colorOne") }}>
                                    <div className="flex items-center justify-center space-x-0">
                                      <div className="flex items-center justify-start w-96">
                                        <span className="pr-2 font-bold whitespace-nowrap">{GetParameterLabel(inner.bucket)}</span>
                                      </div>
                                      <div className="bg-[#DFE5E8] w-full h-4 overflow-hidden flex justify-start">
                                        <div className="bg-[#FFC107] w-[50%] h-4" style={{ width: calc_total_score(inner, "rounded") + "%", background: getMatchCategory(category.category, domainThemeDetails, "colorTwo") }} />
                                      </div>
                                      <span className="pl-3 font-bold">{Math.round(calc_total_score(inner)) + "%"}</span>
                                    </div>
                                  </td>
                                </tr>

                                <tr className="text-sm bg-[#F1F5F9]">
                                  <td className="py-1 px-4  border-r-2 border-b-2 border-white text-lg font-bold w-44 text-left">Qualities</td>
                                  <td className="px-4 w-fit border-b-2 border-white">
                                    <div className="flex items-center justify-center space-x-0">
                                      <div className="flex items-center justify-start w-96">
                                        <span className="pr-2 font-bold whitespace-nowrap">{GetParameterLabel(element.parameterDimensionName)}</span>
                                      </div>
                                      <div className="bg-[#DFE5E8] w-full h-4 overflow-hidden flex justify-start">
                                        <div className="bg-[#9C27B0] w-[50%] h-4" style={{ width: normalisedScore(inner, dimen_index, "score") + "%" }} />
                                      </div>
                                      <span className="pl-3 font-bold">{Math.round(normalisedScore(inner, dimen_index, "score")) + "%"}</span>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>

                        <div className="w-full space-y-1 page-break mt-4">
                          <h1 className="text-lg font-bold text-[#212121] uppercase">SELF SNAPSHOT</h1>
                          <p className="text-[#212121] text-xs font-normal">
                            {EditDetailsFn(element, "desc", <div dangerouslySetInnerHTML={{ __html: (element && element.details && element.details.desc ? element.details.desc : ""), }} />)}
                            {/* {EditDetailsFn(element, "desc", <div dangerouslySetInnerHTML={{ __html: BoldText(element && element.details && element.details.desc ? element.details.desc : ""), }} />)} */}
                          </p>
                        </div>

                        {getBulletPoints(element, "desc1") &&
                          getBulletPoints(element, "desc1").length > 0
                          ? chunkList15(getBulletPoints(element, "desc1")).map(
                            (textList) => (
                              <>
                                <div className="w-full space-y-2 page-break mt-4">
                                  <h1 className="text-xl font-bold text-[#212121] uppercase">
                                    {EditButtonFn("Key Takeaway", "Key Takeaway")}
                                  </h1>
                                  <ul className="text-[#212121] list-disc space-y-2 pl-6 text-xs">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <li>
                                          {EditDetailsFn(element, "desc1" + (index6 + 1), text)}
                                        </li>
                                      ))
                                      : null}
                                  </ul>
                                </div>
                              </>
                            )
                          )
                          : null}

                        {getBulletPoints(element, "desc2") &&
                          getBulletPoints(element, "desc2").length > 0
                          ? chunkList15(getBulletPoints(element, "desc2")).map(
                            (textList) => (
                              <>
                                <div className="w-full space-y-2 page-break mt-4">
                                  <h1 className="text-lg font-bold text-[#212121] uppercase">
                                    {EditButtonFn("Self Development", "Self Development")}
                                  </h1>
                                  <ul className="text-[#212121] list-disc space-y-2 pl-6 text-xs">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <li>
                                          {EditDetailsFn(element, "desc2" + (index6 + 1), text)}
                                        </li>
                                      ))
                                      : null}
                                  </ul>
                                </div>
                              </>
                            )
                          )
                          : null}
                      </div>
                    </main>}



                  {(visibleContent(inner, dimen_index, "score") === 2) &&
                    <main className="p-10 px-12 space-y-6 bg-white page-break">
                      <div className='page-break space-y-3'>
                        <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
                          <h1 className="text-lg font-semibold">Leadership Report</h1>
                          <p className="text-lg font-semibold">{userName + " | " + createdAt}</p>
                        </div>
                        <table className="w-full bg-[#F1F5F9] pt-5 mt-5">
                          <tbody className=" ">
                            {elements4.map((_, index) => (
                              <>
                                <tr className="text-sm bg-[#F1F5F9]">
                                  <td className="p-1 px-4 border-r-2 border-b-2 border-white text-lg font-bold w-44 text-left">Domains</td>
                                  <td className="p-1 px-0 w-fit border-b-2 border-white bg-[#FFC107]" style={{ color: getMatchCategory(category.category, domainThemeDetails, "textColor"), background: getMatchCategory(category.category, domainThemeDetails, "colorOne") }}>
                                    <div className="space-y-2 px-4 flex flex-col">
                                      <span className="pr-2 font-bold text-xl"> {"LEADING " + category.category}</span>
                                      <span className="text-sm">
                                        {getMatchCategory(category.category, domainThemeDetails, "description")}
                                      </span>
                                    </div>
                                  </td>
                                </tr>

                                <tr className="text-sm bg-[#F1F5F9]">
                                  <td className="p-1 px-4 border-r-2 border-b-2 border-white text-lg font-bold w-44 text-left">Sub-Domains</td>
                                  <td className="px-4 w-fit border-b-2 border-white bg-[#FFF8E1] py-1" style={{ background: getMatchCategory(category.category, domainThemeDetails, "colorOne") }}>
                                    <div className="flex items-center justify-center space-x-0">
                                      <div className="flex items-center justify-start w-96">
                                        <span className="pr-2 font-bold whitespace-nowrap">{GetParameterLabel(inner.bucket)}</span>
                                      </div>
                                      <div className="bg-[#CFD8DC] w-full h-4 overflow-hidden flex justify-start">
                                        <div className="bg-[#FFC107] w-[50%] h-4" style={{ width: calc_total_score(inner, "rounded") + "%", background: getMatchCategory(category.category, domainThemeDetails, "colorTwo") }} />
                                      </div>
                                      <span className="pl-3 font-bold w-20">{Math.round(calc_total_score(inner)) + "%"}</span>
                                    </div>
                                  </td>
                                </tr>

                                <tr className="text-sm bg-[#F1F5F9]">
                                  <td className="p-1 px-4 border-r-2 border-b-2 border-white text-lg font-bold w-44 text-left">Qualities</td>
                                  <td className="space-y-2 py-1">
                                    <div className="flex items-center justify-center space-x-0 border-b-2 border-white pb-2 px-4">
                                      <div className="flex items-center justify-start w-96">
                                        <span className="pr-2 font-bold whitespace-nowrap">{GetParameterLabel(getLabelScore(inner, 0, "name"))}</span>
                                      </div>
                                      <div className="bg-[#CFD8DC] w-full h-4 overflow-hidden flex justify-start">
                                        <div className="bg-[#009688] w-[50%] h-4" style={{ width: normalisedScore(inner, 0, "score") + "%" }} />
                                      </div>
                                      <span className="pl-3 font-bold w-20">{normalisedScore(inner, 0, "score") + "%"}</span>
                                    </div>
                                    <div className="flex items-center justify-center space-x-0 px-4">
                                      <div className="flex items-center justify-start w-96">
                                        <span className="pr-2 font-bold whitespace-nowrap">{GetParameterLabel(getLabelScore(inner, 1, "name"))}</span>
                                      </div>
                                      <div className="bg-[#CFD8DC] w-full h-4 overflow-hidden flex justify-start">
                                        <div className="bg-[#9C27B0] w-[50%] h-4" style={{ width: normalisedScore(inner, 1, "score") + "%" }} />
                                      </div>
                                      <span className="pl-3 font-bold w-20">{normalisedScore(inner, 1, "score") + "%"}</span>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>

                        <div className="w-full space-y-1 page-break mt-4">
                          <h1 className="text-lg font-bold text-[#212121] uppercase">SELF SNAPSHOT</h1>
                          <p className="text-[#212121] text-xs font-normal">
                            {EditDetailsFn(getCommonContent(inner), "desc5", <div dangerouslySetInnerHTML={{ __html: (getCommonContent(inner) && getCommonContent(inner).details && getCommonContent(inner).details.desc5 ? getCommonContent(inner).details.desc5 : ""), }} />)}
                          </p>
                        </div>

                        {getBulletPoints(getCommonContent(inner), "desc6") &&
                          getBulletPoints(getCommonContent(inner), "desc6").length > 0
                          ? chunkList15(getBulletPoints(getCommonContent(inner), "desc6")).map(
                            (textList) => (
                              <>
                                <div className="w-full space-y-2 page-break mt-4">
                                  <h1 className="text-xl font-bold text-[#212121] uppercase">
                                    {EditButtonFn("Key Takeaway", "Key Takeaway")}
                                  </h1>
                                  <ul className="text-[#212121] list-disc space-y-2 pl-6 text-xs">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <li>
                                          {EditDetailsFn(getCommonContent(inner), "desc6" + (index6 + 1), text)}
                                        </li>
                                      ))
                                      : null}
                                  </ul>
                                </div>
                              </>
                            )
                          )
                          : null}

                        {getBulletPoints(getCommonContent(inner), "desc7") &&
                          getBulletPoints(getCommonContent(inner), "desc7").length > 0
                          ? chunkList15(getBulletPoints(getCommonContent(inner), "desc7")).map(
                            (textList) => (
                              <>
                                <div className="w-full space-y-2 page-break mt-4">
                                  <h1 className="text-lg font-bold text-[#212121] uppercase">
                                    {EditButtonFn("Self Development", "Self Development")}
                                  </h1>
                                  <ul className="text-[#212121] list-disc space-y-2 pl-6 text-xs">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <li>
                                          {EditDetailsFn(getCommonContent(inner), "desc7" + (index6 + 1), text)}
                                        </li>
                                      ))
                                      : null}
                                  </ul>
                                </div>
                              </>
                            )
                          )
                          : null}
                      </div>
                    </main>}
                </>


              )}
            </>
          )}
        </>
      )}


      {/* {finalList && finalList.length > 0 && finalList.map((category, cat_index) =>
        <>
          {category && category.parameters && category.parameters.length > 0 && category.parameters.map((inner, para_index) =>
            <>
              {inner && inner.dimensions && inner.dimensions.length > 0 && getWithDetails(inner.dimensions, getLeadershipTextDetailsData).map((element, dimen_index) =>
                <main className="p-10 px-6 space-y-6 bg-white page-break">
                  <div className='page-break'>
                    <div className="flex items-center justify-between w-full py-4 border-b">
                      <h1 className="text-lg font-bold">Leadership Report</h1>
                      <p className="text-lg font-bold">{userName + " | " + createdAt}</p>
                    </div>

                    <div className="flex flex-col space-y-6 w-full pt-5">

                      <div className="flex flex-col space-y-6 w-full ">
                        <div className="space-y-3">
                          <div className='bg-[#FFB300] px-6 py-2 text-white uppercase font-bold flex w-fit rounded-tr-lg'>
                            {"LEADING " + category.category}
                          </div>
                          <div className="p-2 text-left  flex itmes-center space-x-2 flex-shrink-0 ">
                            <h1 className="text-2xl font-bold capitalize text-[#212121]">
                              {(para_index + 1).toString() + ". " + GetParameterLabel(inner.bucket)}
                            </h1>
                            <span className="pr-2 font-bold">{calc_total_score(inner) + "%"}</span>
                          </div>
                          <div className="bg-[#FFB30029] w-full h-5 overflow-hidden flex justify-start">
                            <div className="bg-[#FFB300] w-[50%] h-5" style={{ width: calc_total_score(inner, "rounded") + "%" }} />
                          </div>
                        </div>

                        <div className="w-full">
                          <h2 className="p-2 text-base font-bold text-[#212121] text-left uppercase">Qualities</h2>
                          <div className="text-sm w-full flex items-center    ">
                            <div className="p-2 text-left  flex itmes-center space-x-2 flex-shrink-0 ">
                              <span>
                                {GetParameterLabel(element.parameterDimensionName)}
                              </span>
                              <span className="pr-2 font-bold">{getLabelScore(inner, dimen_index, "score") + "%"}</span>
                            </div>
                            <div className="flex items-center justify-center space-x-0 w-full">
                              <div className="bg-[#DFE5E8] w-[100%] h-5 overflow-hidden flex justify-start">
                                <div className="bg-[#9C27B0] w-[50%] h-5" style={{ width: getLabelScore(inner, dimen_index, "score") + "%" }} />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full space-y-1 page-break">
                          <h1 className="text-xl font-bold text-[#212121] uppercase">SELF SNAPSHOT</h1>
                          <p className="text-[#212121]">
                            {EditDetailsFn(element, "desc", <div dangerouslySetInnerHTML={{ __html: BoldText(element && element.details && element.details.desc ? element.details.desc : ""), }} />)}
                          </p>
                        </div>

                        {getBulletPoints(element, "desc1") &&
                          getBulletPoints(element, "desc1").length > 0
                          ? chunkList15(getBulletPoints(element, "desc1")).map(
                            (textList) => (
                              <>
                                <div className="w-full space-y-4 page-break">
                                  <h1 className="text-xl font-bold text-[#212121] uppercase">
                                    {EditButtonFn("Self Development", "Self Development")}
                                  </h1>
                                  <ul className="text-[#212121] list-disc space-y-4 pl-6">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <li>
                                          {EditDetailsFn(element, "desc1" + (index6 + 1), text)}
                                        </li>
                                      ))
                                      : null}
                                  </ul>
                                </div>
                              </>
                            )
                          )
                          : null}

                        {getBulletPoints(element, "desc2") &&
                          getBulletPoints(element, "desc2").length > 0
                          ? chunkList15(getBulletPoints(element, "desc2")).map(
                            (textList) => (
                              <>
                                <div className="w-full space-y-4 page-break">
                                  <h1 className="text-xl font-bold text-[#212121] uppercase">
                                    {EditButtonFn("Key Takeaway", "Key Takeaway")}
                                  </h1>
                                  <ul className="text-[#212121] list-disc space-y-4 pl-6">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <li>
                                          {EditDetailsFn(element, "desc2" + (index6 + 1), text)}
                                        </li>
                                      ))
                                      : null}
                                  </ul>
                                </div>
                              </>
                            )
                          )
                          : null}

                      </div>

                    </div>

                  </div>
                </main>
              )}
            </>
          )}
        </>
      )} */}

    </>
  );
}
