import React from "react";
import { GoDotFill } from "react-icons/go";

function Feedback(props) {

  let { task, handleTextFieldInput, crrFeedbackList, responseList, saveFeedbackText } = props;

  // let textValue = "";
  // if (crrFeedbackList && crrFeedbackList.length > 0) {
  //   let getIndex = crrFeedbackList.findIndex(prev => prev.taskId === task.id);
  //   if (getIndex !== -1) {
  //     textValue = crrFeedbackList[getIndex]["text"];
  //   }
  // }
  const getTextValue = (list, taskId) => {
    let textValue = "";
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.taskId.toString() === taskId.toString());
      if (getIndex !== -1) {
        textValue = list[getIndex]["feedback"];
      }
    }
    return textValue
  }

  return (
    <>

      <div className='my-6 border rounded-lg overflow-hidden'>
        <div className='flex items-center justify-between w-full p-4 bg-[#E6F5F3] border-b'>
          <h2 className='text-lg font-semibold'>{task && task.desc ? task.desc : ""}</h2>
          <button className='text-[#009688]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
          </button>
        </div>

        <div className='px-4 space-y-4 py-4'>
          <div className="space-y-1 ">
            <textarea rows={4} onChange={(e) => handleTextFieldInput(task.id, e.target.value)}
              value={getTextValue(responseList, task.id)}
              className='border p-4 w-full rounded-lg' placeholder='Write here...'></textarea>
            <p className='text-[#212121]/70 font-medium text-right lg:text-sm text-sm'>500 Character Max.</p>
          </div>
          <button onClick={() => saveFeedbackText(task.id, getTextValue(responseList, task.id))} className='flex items-center space-x-2 bg-[#009688] px-6 py-2 rounded-md text-white w-fit ml-auto lg:text-base text-sm'>
            <span>Save Changes</span>
          </button>
        </div>

      </div>

    </>
  );

}
export default Feedback;