import React from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


function Home(props) {

  let { getGrowPlusBucket_UserData, clientProfile, onLeadingClick, getGrowPlusTaskStatus_UserData } = props;

  const percentage = 66;

  let profileName = "";
  if (clientProfile && clientProfile.profile && clientProfile.profile.name) {
    profileName = clientProfile.profile.name
  }

  const checkStatusPerc = (list, bucketId, field) => {
    let perc = 0;
    if (list && list.length > 0) {
      let getIndex = list.findIndex(prev => prev.bucketId.toString() === bucketId.toString());
      if (getIndex !== -1) {
        perc = list && list[getIndex] && list[getIndex][field] ? list[getIndex][field] : 0
      }
    }
    return perc > 0 ? parseFloat(perc) : 0
  }


  return (

    <div className="pt-8">
      <section className="xl:w-11/12 xl:px-0 w-full px-4 mx-auto space-y-8">
        <div className="pt-4">
          <h1 className="text-3xl font-semibold pb-2">{profileName ? "Greetings!" + profileName : "Greetings!"}</h1>
          <p className="text-base text-[#212121]/70">Let get started, you are almost there to see the positive change.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">

          {
            getGrowPlusBucket_UserData && getGrowPlusBucket_UserData.length > 0 ?
              getGrowPlusBucket_UserData.map((item, index) =>
                <>
                  <div key={item.id} className="bg-white rounded-2xl border overflow-hidden">
                    <div className="w-full flex justify-center items-center" style={{ background: item.colorCode }}>
                      <img src={"img/grow/bucket/" + item.icon} alt={item.name} className="lg:h-44" />
                    </div>
                    <div className="text-center space-y-6">

                      <div className="flex justify-center items-center">
                        <div className="flex items-center justify-center bg-white rounded-full w-24 h-24 -mt-12">
                          {/* <CircularProgressbar value={checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "percent")} text={`${checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "percent")}%`} /> */}
                          {/* <CircularProgressbar
                            value={Math.round(checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "percent"))}
                            text={`${Math.round(checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "percent"))}%`}
                          /> */}
                          <CircularProgressbar
                            value={Math.round(checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "percent"))}
                            text={`${Math.round(checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "percent"))}%`}
                            styles={{
                              path: {
                                // Bar color
                                stroke: '#009688', // Customize this color for the bar
                                strokeWidth: '9'
                              },
                              text: {
                                // Text color
                                fill: '#212121', // Customize this color for the text
                                fontSize: '24px', // Optionally adjust the font size
                                fontWeight: '600'
                              },
                              trail: {
                                // Background color of the progress bar
                                stroke: '#e0e0e0', // Customize the trail color
                                strokeWidth: '9'
                              }
                            }}
                          />

                        </div>
                      </div>

                      <div className="space-y-2 ">
                        <h2 className="text-xl font-semibold text-[#212121]">{item && item.name}</h2>
                        <div className="flex items-center justify-center font-medium space-x-2 text-[#212121]/70">
                          <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">  <path d="M6.5 2.5H18.5V8.5L14.5 12.5L18.5 16.5V22.5H6.5V16.5L10.5 12.5L6.5 8.5V2.5ZM16.5 17L12.5 13L8.5 17V20.5H16.5V17ZM12.5 12L16.5 8V4.5H8.5V8L12.5 12ZM10.5 6.5H14.5V7.25L12.5 9.25L10.5 7.25V6.5Z" fill="#212121" fillOpacity="0.54" /></svg>
                          <span>{checkStatusPerc(getGrowPlusTaskStatus_UserData, item.id, "totalMonths")} Months</span>
                        </div>
                      </div>
                      <div className="flex items-center w-full border-t divide-x">
                        {/* <button className="w-full px-2 py-3 text-base font-medium cursor-pointer">See Overview</button> */}
                        <button onClick={() => onLeadingClick(item.id)} className="w-full px-4 py-3 cursor-pointer text-base bg-white font-medium hover:bg-[#E6F5F3]">Start</button>
                      </div>
                    </div>
                  </div>
                </>
              ) : null
          }

        </div>

      </section>

      {/* <div className="py-3 justify-center flex items-center text-[#3D405B] border-t fixed bottom-0 w-full"><p>All Rights Reserved | Copyright © TalentPlus.</p></div> */}
    </div>

  );
}

export default Home;