import React from "react";
import moment from "moment";

export default function Summary(props) {
  let { domainThemeDetails, GetParameterLabel, elements4, getLeadershipReportScoreData, getLeadershipParaCategoryData } = props;
  let { userName, createdAt } = props;


  console.log("--------------------...");
  console.log(getLeadershipReportScoreData, getLeadershipParaCategoryData);


  const getParaList = (validParaList, list, type) => {
    let paraNewList = []
    if (validParaList && validParaList.length > 0 && list && list.length > 0) {
      validParaList.forEach((ele) => {
        let getIndex = list.findIndex(prev => prev.parameterBucketName.toString() === ele.toString());
        if (getIndex !== -1) {
          let temp = {
            "bucket": ele,
            "dimensions": list[getIndex]["dimensions"]
          }
          paraNewList.push(temp);
        }
      });
    }
    if (type === "array") {
      return paraNewList
    } else {
      return paraNewList && paraNewList.length > 0 ? paraNewList.length : 0
    }
  }


  let orderArray = ["SELF", "OTHERS", "TEAM", "RESULTS", "COMMUNITY"];
  let finalList = []
  if (getLeadershipParaCategoryData && getLeadershipParaCategoryData.length > 0) {
    finalList = getLeadershipParaCategoryData.map((ele) => ({
      "category": ele._id,
      "parameters": getParaList(ele.parameters, getLeadershipReportScoreData, "array"),
      "length": getParaList(ele.parameters, getLeadershipReportScoreData, "length") + 1
    })).sort((a, b) => {
      return orderArray.indexOf(a.category) - orderArray.indexOf(b.category);
    });
  }


  console.log("finalList----->", finalList)

  const getLabelScore = (inner, index, type) => {
    if (inner && inner.dimensions && inner.dimensions.length > 0) {
      let data = inner && inner.dimensions && inner.dimensions[index] ? inner.dimensions[index] : {};
      if (type === "name") {
        return data && data["parameterDimensionName"] ? data["parameterDimensionName"] : ""
      }
      else if (type == "score") {
        let total = 0;
        let sum = 0;
        if (data && data.answers && data.answers.length > 0) {
          data.answers.forEach((ans) => {
            sum += Number(ans.answer) * Number(ans.count);
            total += (6 * Number(ans.count));
          })
        }
        return (total > 0 ? ((sum * 100) / total).toFixed(0) : 0)
      }
    }
    return ""
  }

  const calc_total_score = (inner) => {
    let total = 0;
    let sum = 0;

    if (inner && inner.dimensions && inner.dimensions.length > 0) {

      [0, 1].forEach((k_, index) => {
        let data = inner && inner.dimensions && inner.dimensions[index] ? inner.dimensions[index] : {};
        if (data && data.answers && data.answers.length > 0) {
          data.answers.forEach((ans) => {
            sum += Number(ans.answer) * Number(ans.count);
            total += (6 * Number(ans.count));
          })
        }
      })

    }

    return (total > 0 ? ((sum * 100) / total) : 0)
  }

  const sortListByScore = (parameterList) => {
    if (parameterList && parameterList.length > 0) {
      return parameterList.sort((b, a) => (calc_total_score(a) - calc_total_score(b)))
    }
    return []
  }


  const getMatchCategory = (category, list, field) => {
    const capitalizeWords = (str) => {
      function capitalize(str) {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      }
      return str.split(' ').map(word => capitalize(word)).join(' ');
    }

    if (field === "textColor") {
      if ("Leading " + capitalizeWords(category) === "Leading Self") {
        return "#000000"

      } else {
        return "#FFFFFF"

      }
    } else {

      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.attribute === "Leading " + capitalizeWords(category));
        if (getIndex !== -1) {
          return list[getIndex][field]
        }
      }
    }
    return ""
  }

  const normalisedScore = (inner, index, type) => {
    let scoreX = getLabelScore(inner, index, type);
    let score1 = getLabelScore(inner, 0, type);
    let score2 = getLabelScore(inner, 1, type);

    let newScore = (Number(scoreX) * 100) / (Number(score1) + Number(score2));
    return newScore > 0 ? newScore.toFixed(0) : 0
  }


  return (
    <>
      <main className="p-10 px-12 space-y-6 bg-white page-break">
        <div className='page-break'>
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">Leadership Report</h1>
            <p className="text-lg font-semibold">{userName + " | " + createdAt}</p>
          </div>

          <div className="flex flex-col space-y-6 w-full pt-5">

            <h1 className="text-2xl font-medium capitalize bg-[#212121]/90 text-white p-4">Leadership Summary</h1>

            <table className="w-full bg-[#F1F5F9]">
              <thead className="bg-[#F1F5F9]">
                <th className="p-2  px-4 text-sm font-bold text-[#212121] text-left border-r-2 border-b-2 border-white ">Domains</th>
                <th className="p-2 text-sm font-bold text-[#212121] border-r-2 border-b-2 border-white text-left ">Sub-Domains</th>
                <th className="p-2 text-sm font-bold text-[#212121] text-right border-b-2 border-white">Qualities</th>
                <th className="p-2 text-sm font-bold text-[#212121] border-b-2 border-white"> </th>
                <th className="p-2 text-sm font-bold text-[#212121] text-left border-r-2 border-b-2 border-white ">Qualities</th>

              </thead>
              <tbody className=" ">
                {finalList && finalList.length > 0 && finalList.map((ele, index) => (
                  <>
                    <tr className="text-sm bg-[#FFC107] " style={{ color: getMatchCategory(ele.category, domainThemeDetails, "textColor"), background: getMatchCategory(ele.category, domainThemeDetails, "colorTwo") }}>
                      <td className="px-1 py-2 border-r-2 border-b-2 border-white text-center text-sm uppercase font-bold " rowSpan={ele.length}>
                        <span>Leading</span><br />
                        <span>{ele && ele.category ? ele.category : ""}</span>
                      </td>
                    </tr>

                    {ele && ele.parameters && ele.parameters.length > 0 && sortListByScore(ele.parameters).map((inner, index) => (
                      <tr className="text-[10px] capitalize">
                        <td className="p-1 text-left font-bold border-r-2 border-b-2 border-white bg-[#FFF8E1]" style={{ background: getMatchCategory(ele.category, domainThemeDetails, "colorOne") }}>{inner && inner.bucket ? (index + 1).toString() + ". " + GetParameterLabel(inner.bucket) : ""}</td>

                        <td className="p-1 text-left border-b-2 border-white">{GetParameterLabel(getLabelScore(inner, 0, "name"))}</td>

                        <td className="p-1 px-0 w-fit border-b-2 border-white">
                          <div className="flex items-center justify-center space-x-0">
                            <span className="pr-2 font-bold">{normalisedScore(inner, 0, "score") + "%"}</span>
                            <div className="bg-[#DFE5E8] w-[5rem] h-4 overflow-hidden flex justify-end">
                              <div className="bg-[#009688] w-[30%] h-4" style={{ width: normalisedScore(inner, 0, "score") + "%" }} />
                            </div>
                            <div className="bg-[#DFE5E8] w-[5rem] h-4 overflow-hidden flex justify-start">
                              <div className="bg-[#9C27B0] w-[30%] h-4" style={{ width: normalisedScore(inner, 1, "score") + "%" }} />
                            </div>
                            <span className="pl-2 font-bold">{normalisedScore(inner, 1, "score") + "%"}</span>
                          </div>
                        </td>

                        <td className="p-2 text-left border-r-2 border-b-2 border-white ">{GetParameterLabel(getLabelScore(inner, 1, "name"))}</td>
                      </tr>
                    ))}
                  </>
                ))}

              </tbody>
            </table>

          </div>

        </div>
      </main>
    </>
  );
}
