import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { get360DimensionRelationScoreData, GetParameterLabel, GetParameterDesc, getColorByRelation,
      sequence_list, sequence_rel 
     } = this.props;

    function getListSorted(list) {
      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev.relation === "Self")
        let List2 = list.filter(prev => prev.relation !== "Self")
        let RelationListMainA = List1.concat(List2)
        return RelationListMainA
      } else {
        return []
      }
    }

    let get360DimensionRelationScoreList = []
    if (get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0) {
      get360DimensionRelationScoreList = get360DimensionRelationScoreData.map((element) => (element))
      get360DimensionRelationScoreList.sort((a, b) => ((a && a.item && a.item._id ? a.item._id : "").charCodeAt(0) - (b && b.item && b.item._id ? b.item._id : "").charCodeAt(0)))
    }

    let ValueList = [];
    let DimensionList = [];
    let RelationList = [];
    let RelationListMain = [];

    if (get360DimensionRelationScoreList && get360DimensionRelationScoreList.length > 0) {
      get360DimensionRelationScoreList.forEach((item) => {
        let dimension1 = item && item._id ? item._id : "";

        let dimension = dimension1;


        let temp = {
          "dimen_xyz": dimension
        }

        let miniList = []
        if (item && item.total && item.total.length > 0) {
          item.total.forEach((inner) => {
            if (inner && inner._id && inner._id.relation) {
              miniList.push({
                "relation": inner._id.relation,
                "mean": inner && inner.mean ? inner.mean : 0
              })
            }
          })
        }
        let miniListSorted = getListSorted(miniList)





        if (miniListSorted && miniListSorted.length > 0) {
          miniListSorted.forEach((inner) => {
            if (inner && inner.relation) {

              let scoreMean1 = inner && inner.mean ? inner.mean : 0;

              let scoreMean = scoreMean1.toFixed(2);

              temp[inner.relation] = scoreMean;

              let getIndex2 = RelationList.findIndex(prev => prev.name === inner.relation);
              if (getIndex2 === -1) {
                let tempx = {
                  name: inner.relation,
                  value: [scoreMean]
                }
                RelationList.push(tempx);
                RelationListMain.push(tempx)

              } else {
                RelationList[getIndex2]["value"].push(scoreMean)
              }



            }
          })
        }


        DimensionList.push({ name: dimension, max: 6 })



        ValueList.push(temp)
      })
    }





    // //console.log('-------->>>>sourceList; ',sourceList)
    // //console.log('-------->>>>sample; ',[
    //   ['product', '2015', '2016', '2017'],
    //   ['Matcha Latte', 43.3, 85.8, 93.7],
    //   ['Milk Tea', 83.1, 73.4, 55.1],
    //   ['Cheese Cocoa', 86.4, 65.2, 82.5],
    //   ['Walnut Brownie', 72.4, 53.9, 39.1]
    // ])
    // var first = "Self";
    // sourceList.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });

    // function getListSorted(list){
    //   if(list && list.length>0){
    //     let List1 = list.filter(prev=>prev ==="Self")
    //     let List2 = list.filter(prev=>prev !=="Self")
    //     let RelationListMainA = List1.concat(List2)
    //     return RelationListMainA
    //   }else{
    //     return []
    //   }
    // }
    // let sourceListA = getListSorted(sourceList)

    function getStarArray(element, item) {
      let value = (element[item.name]);
      let floorValue = value > 0 ? Math.floor(value) : 0;
      let decimal = value > 0 ? (parseFloat(value) - parseFloat(floorValue)) : 0;
      let finalList = []
      let list = [1, 2, 3, 4, 5, 6];
      if (list && list.length > 0) {
        list.forEach((x) => {
          if ((x - 1) < floorValue) {
            finalList.push("fullstar")
          } else {
            if ((x - 1) == floorValue && decimal > 0) {
              finalList.push("halfstar")
            } else {
              finalList.push("emptystar")

            }
          }
        })
      }
      console.log("finalList--x->", finalList)
      return finalList
    }

    let colorList = ["#E57373", "#F06292", "#BA68C8", "#BA68C8", "#9575CD", "#7986CB", "#64B5F6", "#4FC3F7", "#4DD0E1", "#4DB6AC", "#81C784", "#DCE775", "#FFB74D", "#FF8A65", "#90A4AE", "#757575"]
    var color = colorList[Math.floor(Math.random() * colorList.length)];
    let colorRelList = []
    if (RelationList && RelationList.length > 0) {
      RelationList.forEach((item) => {
        colorRelList.push({
          "name": item.name,
          "color": color
        })
      })
    }

    function getColorByName(name) {
      let defaultColor = "#FFD54F";
      let getIndex = colorRelList.findIndex(prev => prev.name === name)
      if (getIndex !== -1) {
        defaultColor = colorRelList && colorRelList[getIndex] && colorRelList[getIndex]["color"] ? colorRelList[getIndex]["color"] : "#FFD54F"
      }
      return defaultColor
    }


    const chunkSize = RelationList && RelationList.length > 0 ? (RelationList.length > 7 ? 1 : (RelationList.length > 5 ? 2 : 3)) : 3;
    let ListMain = []
    for (let i = 0; i < ValueList.length; i += chunkSize) {
      const chunk = ValueList.slice(i, i + chunkSize);
      ListMain.push(chunk)
      // do whatever
    }
    let { userName, createdAt } = this.props;

    const sort2Order = (list, sequence) => {
      console.log('Sorting list based on sequence:', sequence);
      if (list && list.length > 0) {
        return list.sort((a, b) => sequence.indexOf(a.name) - sequence.indexOf(b.name));
      }
      return [];
    };

    return (
      <>
        {ListMain && ListMain.length > 0 ?
          ListMain.map((ValueListInner, indx) =>
            <section className=' p-6 px-6 flex flex-col justify-between page-break'>
              {/* report header */}
              <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                <p className='font-medium italic text-base '>360 Feedback</p>
                <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
              </div>
              <div className='space-y-4 flex flex-col  '>

                <h1 className='text-2xl text-[#212121] font-medium  capitalize'>{"Summary of Impact Themes " + (indx > 0 ? "(Continued)" : "")} </h1>

                <div className='space-y-4 text-sm text-slate-800'>
                  <p className='text-[#212121]/80 italic'> Given below is a summary of your feedback ratings for each of the themes. The graphs indicate the average
                    ratings for each theme in the review segmented by rater groups.</p>

                  {ValueListInner && ValueListInner.length > 0 ?
                    ValueListInner.map((element, index) =>
                      (true) ?
                        <table className={'w-full  ' + (index > 0 ? 'pb-2' : '')}>
                          <thead className='text-sm capitalize bg-[#8BC34A]/25'>
                            <tr>
                              <th className='w-4/12 p-4 py-3 text-sm font-medium text-left border-r-2 border-white'>
                                {GetParameterLabel(element.dimen_xyz)}
                              </th>
                              <th className='w-4/12 p-4 py-3 text-sm font-medium text-left border-r-2 border-white'>
                                Relationship
                              </th>
                              <th className='w-4/12 p-4 py-3 text-sm font-medium text-left'>
                                Ratings
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y'>
                            <tr className='bg-[#F5F5F5]'>
                              <td className='p-4 border-r-2 border-white  '>
                                <div className='flex items-center  '>
                                  <p className='italic '>{GetParameterDesc(element.dimen_xyz)}</p>
                                </div>
                              </td>
                              <td className='border-r-2 border-white divide-y-2 divide-white whitespace-nowrap '>
                                {RelationList && RelationList.length > 0 ?
                                  sort2Order(RelationList,sequence_rel).map((item, index) =>
                                    <div key={index} className='flex items-center justify-between h-12 p-2 py-2 space-x-4 odd:bg-[#F5F5F5] even:bg-[#E9E9E9]'>
                                      <div className='flex items-center space-x-3'>
                                        <div className='flex items-center justify-center w-8 h-8 text-white uppercase bg-yellow-500 rounded-full'
                                          style={{ background: getColorByRelation(item.name) }}
                                        >{item && item.name ? item.name[0] : ""}</div>
                                        <p className='font-medium'>{item.name}</p>
                                      </div>
                                    </div>
                                  ) : null}
                              </td>

                              <td className='divide-y-2 divide-white '>
                                {RelationList && RelationList.length > 0 ?
                                  sort2Order(RelationList,sequence_rel).map((item, index) =>
                                    <div className='flex items-center h-12 p-4 py-2 space-x-4 odd:bg-[#F5F5F5] even:bg-[#E9E9E9]'>
                                      <div className='flex items-center w-full space-x-2'>
                                        {getStarArray(element, item) && getStarArray(element, item).length ?
                                          getStarArray(element, item).map((option, optionindex) =>
                                            option === "fullstar" ?
                                              <img src="/img/questiontype/star/star-filled.png" alt="star Filled" className='w-6' />
                                              :
                                              (option === "halfstar" ?
                                                <img src="/img/questiontype/star/half-star.png" alt="half star Filled" className='w-6' />
                                                :
                                                <img src="/img/questiontype/star/star-unfilled.png" alt="star Unfilled" className='w-6' />)
                                          ) : null}

                                      </div>
                                      <p className='text-[#212121] font-medium text-base'>{element[item.name]}</p>
                                    </div>
                                  ) : null}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        : null
                    ) : null}

                </div>


              </div>

              {/* <div className='flex items-center justify-between py-4'>
          <img src="/img/logo/happypluslogo.png" class=" w-24 object-cover" alt="default_survey_logo" />
          <p className='text-slate-600'>Page 1</p>
          <div/>
        </div> */}

            </section>
          ) : null}

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
