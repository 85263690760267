import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {

  let { item, Benchmark } = props;





  return (
    <>
      <div className='2xl:!w-[450px] 2xl:!h-[270px]  lg:!w-[400px] lg:!h-[250px] md:!w-[320px] md:!h-[210px] !w-[300px] !h-[200px] -mt-4 '>

        {item && item.score ?
          <ReactSpeedometer className="meter"
            fluidWidth={true}
            value={item.score}
            minValue={0}
            maxValue={100}
            needleColor="#4db8ff"
            segments={1}
            segmentColors={[
              "#5d6cbe",
              "#e3e6e3"
            ]}
            ringWidth={60}
            customSegmentStops={[0, Number(item.score), 100]}
            // fluidWidth={true}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
          />
          :
          <div className='text-gray-400 text-lg text-center font-medium'>Data Not Available</div>}

        {/* {Benchmark && Benchmark.score || true ?
          <div className="gauge-outer">
            <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 1.8)))' }}></div>
          </div>
          : null} */}


      </div>

    </>
  );
}
