import React, { Component } from "react";
import Header from "../Components/Header";

export default class Behaviors extends Component {
  render() {
    let {
      getQuesOverallData,
      setPDF,
      SummaryName,
      getQuestionData,
      GetParameterLabel,
      CountRestrict,
      surveyLogo,
      indexTemplate,
      surveyNAME,
      createdAt
    } = this.props;
    let QuestionData = getQuestionData;
    let paraList = [];
    let count = 0;
    let rowSpan = 0;
    let NewParaList = [];

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        if (
          paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
          -1
        ) {
          paraList.push(ques.parameterDimensionName);
          count += 1;
        } else {
        }
      });

      let obj = {};
      paraList.forEach((para) => {
        obj[para] = 0;
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1;
          }
        });
      });

      NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);
      //   ////console.log('data NewParaList: ',NewParaList)

      rowSpan = QuestionData.length / count;

      // ////console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)
    }
    // ////console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // ////console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total =
          ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];

        ////console.log('ques--->', ques)
        ////console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ques------------- 5, 6--->',(ques))

        let score = parseFloat(
          (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
        );

        NewQuesList.push({
          ...ques,
          score: CountRestrict(ques.count) ? "NA" : score,
        });
      });
    }

    NewQuesList.sort((a, b) => b.score - a.score);
    function calcFavourability(quesZ) {
      let total =
        quesZ["1"] +
        quesZ["2"] +
        quesZ["3"] +
        quesZ["4"] +
        quesZ["5"] +
        quesZ["6"];
      let scoreX = parseFloat(
        (((quesZ["5"] + quesZ["6"]) * 100) / total).toFixed(2)
      );
      return scoreX;
    }

    function calcOverall(queItem) {
      let overallX = 0;
      if (getQuesOverallData && getQuesOverallData.length > 0) {
        let getIndex = getQuesOverallData.findIndex(
          (prev) => prev.name === queItem.name
        );
        let overallQue = getQuesOverallData[getIndex];
        overallX = calcFavourability(overallQue);
      }
      return overallX;
    }

    function getDiffScore(overall2, score2) {
      let overall = parseFloat(overall2);
      let score = parseFloat(score2);

      if (score >= overall) {
        return {
          "score": "+" + ((score - overall).toFixed(2)).toString(),
          "color": "#4CAF50"
        }
      } else if (score < overall) {
        return {
          "score": "-" + ((overall - score).toFixed(2)).toString(),
          "color": "#F44336"
        }
      } else {
        return {
          "score": "-",
          "color": "#757575"
        }
      }

    }


    function chunkList(array) {
      const chunkSize = 7;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      //console.log("list--->", list);
      return list;
    }




    return (
      <>
        {NewQuesList && NewQuesList.length > 0 ?
          chunkList(NewQuesList).map((NewQues, indexX) =>
            <div className="py-6 px-8 page-break">
              {/* report header */}
              <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
              <p class="font-medium italic text-base ">{surveyNAME}</p>
                              <p class="font-medium italic text-base">{createdAt}</p>
              </div>
              <div className=" ">
                <Header surveyLogo={surveyLogo} />

                <div className>
                  <div className="w-full  py-6 capitalize ">
                    <h1 className="text-2xl text-[#212121] font-medium capitalize">
                      {SummaryName + " Behaviors Item" + (indexX > 0 ? " (Continue)" : "")}{" "}
                    </h1>
                  </div>
                </div>
              </div>

              <main className="w-full  ">
                {setPDF ? (
                  <>
                    <table
                      className="w-full bg-[#F5F5F5]"
                      id={"Behaviors" + indexTemplate.toString()}
                    >
                      <tbody className="divide-y-2 divide-white ">
                        <tr className="text-left text-[#212121] capitalize  border-b  text-sm border-l border-t border-white ">
                          <th className="w-[20%] font-medium p-2  text-left border-r-2 border-white">
                            Dimention
                          </th>
                          <th className="w-[40%] font-medium p-2  text-left border-r-2 border-white">
                            Question
                          </th>
                          <th className="w-[15%] font-medium p-2 text-left border-r-2 border-white">
                            Comparison
                          </th>
                          <th className="w-[25%] font-medium p-2 text-left border-r-2 border-white  ">
                            Score
                          </th>
                          <th className="w-[20%] font-medium p-2 text-center border-r-2 border-white  ">
                            Difference
                          </th>
                        </tr>

                        {NewQues && NewQues.length > 0
                          ? NewQues.map((question, index) => (
                            <>
                              {index % 12 === 0 && index !== 0 ? (
                                <tr className="border-b  text-[#212121] text-sm border-l border-r border-white ">
                                  <th className="w-[20%] font-medium p-2  text-left border-r border-white">
                                    Dimention
                                  </th>
                                  <th className="w-[40%] font-medium p-2  text-left border-r border-white">
                                    Question
                                  </th>
                                  <th className="w-[15%] font-medium p-2 text-left border-r border-white">
                                    Comparison
                                  </th>
                                  <th className="w-[25%] font-medium p-2 text-left border-r border-white  ">
                                    Score
                                  </th>
                                  <th className="w-[20%] font-medium p-2 text-center border-r border-white  ">
                                    Difference
                                  </th>
                                </tr>

                              ) : null}

                              <tr className="border-b bg-[#F5F5F5] text-[#212121] text-sm border-l border-r border-white ">
                                <td rowSpan={2} className="p-2 font-medium capitalize border-r-2 border-white ">
                                  {question && question.parameterDimensionName
                                    ? GetParameterLabel(
                                      question.parameterDimensionName
                                    )
                                    : ""}
                                </td>
                                <td rowSpan={2} className="p-2 text-sm border-r-2 border-white">
                                  {question.name}
                                </td>
                                <td className="p-2 text-sm text-right border-r-2 border-white">
                                  Score
                                </td>
                                <td className="p-2 text-sm border-r-2 border-white  ">
                                  <div className="h-6 w-full bg-[#CFD8DC]">
                                    <div
                                      className="w-full flex items-center h-6 bg-blue-400"
                                      style={{
                                        background: "#1E88E5",
                                        width:
                                          (question &&
                                            question.score &&
                                            question.score !== "NA"
                                            ? question.score
                                            : 0) + "%",
                                      }}
                                    >
                                      <span className="text-xs font-medium text-white px-2">
                                        {question && question.score
                                          ? question.score
                                          : 0}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </td>


                                <td rowSpan={2} className="p-2 text-sm text-center border-r-2 border-white  ">
                                  <div className="  px-2 text-sm font-medium text-center text-[#4CAF50]"
                                    style={{
                                      color: getDiffScore(calcOverall(question), question && question.score
                                        ? question.score
                                        : 0).color
                                    }}>

                                    {getDiffScore(calcOverall(question), question && question.score
                                      ? question.score
                                      : 0).score}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="p-2 text-sm text-right border-r-2 border-white  ">
                                  Overall
                                </td>
                                <td className="p-2 text-sm border-r-2 border-white">
                                  <div className="h-6 w-full bg-[#CFD8DC]">
                                    <div
                                      className="w-full flex items-center  h-6 bg-[#9C27B0]"
                                      style={{
                                        background: "#9C27B0",
                                        width:
                                          (calcOverall(question) !== "NA"
                                            ? calcOverall(question)
                                            : 0) + "%",
                                      }}
                                    >
                                      <span className="text-xs font-medium text-white px-2">
                                        {calcOverall(question)}
                                        %
                                      </span>
                                    </div>
                                  </div>

                                </td>
                              </tr>
                            </>
                          ))
                          : null}


                        {indexX < chunkList(NewQuesList).length - 1 ? (
                          <tr className="text-lg text-gray-500 capitalize bg-white">
                            <td
                              colSpan={5}
                              className="font-normal p-2 text-sm text-left text-[#3D405B]/70 "
                            >
                              Continue...
                            </td>
                          </tr>
                        ) : null}

                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    {/* PPT Table */}
                    <table id={"Behaviors" + indexTemplate.toString()}>
                      <thead>
                        <tr>
                          <th
                            data-pptx-min-width="2.6"
                            style={{
                              textAlign: "left",
                              width: "20%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Dimension
                          </th>

                          <th
                            data-pptx-min-width="2.6"
                            style={{
                              textAlign: "left",
                              width: "50%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Question
                          </th>
                          <th
                            data-pptx-min-width="0.8"
                            style={{
                              width: "10%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Favorability Score
                          </th>
                          <th
                            data-pptx-min-width="0.8"
                            style={{
                              width: "10%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Overall
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {NewQuesList && NewQuesList.length > 0
                          ? NewQuesList.map((question, index) => (
                            <tr>
                              <td
                                data-pptx-min-width="2.6"
                                style={{
                                  textAlign: "left",
                                  width: "20%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#dbefff" : "#ffffff",
                                }}
                              >
                                {question && question.parameterDimensionName
                                  ? GetParameterLabel(
                                    question.parameterDimensionName
                                  )
                                  : ""}
                              </td>
                              <td
                                data-pptx-min-width="2.6"
                                style={{
                                  textAlign: "left",
                                  width: "50%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#dbefff" : "#ffffff",
                                }}
                              >
                                {" "}
                                {question.name}
                              </td>
                              <td
                                data-pptx-min-width="2.6"
                                style={{
                                  textAlign: "center",
                                  width: "10%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#dbefff" : "#ffffff",
                                }}
                              >
                                {(question && question.score ? question.score : 0) +
                                  "%"}
                              </td>

                              <td
                                data-pptx-min-width="0.8"
                                style={{
                                  textAlign: "center",
                                  width: "10%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#dbefff" : "#ffffff",
                                }}
                              >
                                {" "}
                                {calcOverall(question)}%{" "}
                              </td>
                            </tr>
                          ))
                          : null}




                      </tbody>
                    </table>
                  </>
                )}
              </main>
            </div>
          ) : null}
      </>

    );
  }
}
