import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CreateCampaign(props) {
  let { getSurveyData, handleExitSurveyAutofill,
    EmpFilterData, recursiveSettings, campaignRecursiveFreqList, handleCampaignRecursiveType, campaignRecursiveTypeList, handleRecursiveType,

    showBatchRange,handleNewTemplate, handleBatchRangeDropdown, selectBatchRange, formBatchRange, AllEmpTotal, campaignFormData, handleTestUserCheck, handleCampaignInput, handleMessageInput, handleCampaign360Submit, employees,
    allEmployeesList, handleCampaignEmployees, EmpIndexA, EmpIndexB, prevEmpPage, nextEmpPage, SelectEmpPage,
    currentEmpPage, SelectAllEmployees, selectAllEmp, LanguageObj, CurrentLanguage,

    currFilterLevel, showLevelFilter, handleEmpLevelDropdown, handleLevelSelect,
    currFilterValue, showValueFilter, handleEmpValueDropdown, handleValueSelect, allCustomerList,

    employeeValueSearch, handleEmployeeValueSearch, handleEmployeeValueSearchFinal, getIndexByFilterDMGData, handleGetEmployees,
    campaignValidationList, handleRecursiveCampaignInput, handleType, TypeList

  } = props;


  // let [showSizeDrop, handleSizeDrop] = React.useState(false)
  let [showCampaignTypeDropdown, handleshowCampaignTypeDropdown] = React.useState(false);

  let [showTypeDropdown, handleshowTypeDropdown] = React.useState(false);

  let [showCampaignRecursiveDropdown, handleshowCampaignRecursiveDropdown] = React.useState(false);

  let [showRecursiveTypeDropdown, handleshowRecursiveTypeDropdown] = React.useState(false);


  let CustomerList = allCustomerList;


  //  let NeglectedList = ["name","list","isTest", "isDisable", "Age","Alternatenumber", "emp_id","actionPlanStage", "ManagerPID","id","mngr_id","createdAt", "companyId", "currentStage"]
  let NeglectedList = ["name", "list"]

  let EmployeesList = [];

  let EmpLevelList = [];
  let EmpValueList = [];
  let EmpValueObj = {};

  if (allEmployeesList && allEmployeesList.length > 0) {

    let tempLevelList = getIndexByFilterDMGData ? Object.keys(getIndexByFilterDMGData) : {};
    if (tempLevelList && tempLevelList.length > 0) {
      tempLevelList.forEach((temp) => {
        if (NeglectedList.findIndex(prev => prev === temp) === -1) {
          EmpLevelList.push(temp)
        }
      });
    }

    if (EmpLevelList) {
      EmpLevelList.forEach((filter) => {
        EmpValueObj[filter] = [];

        allEmployeesList.forEach((emp) => {
          let getIndex = EmpValueObj[filter].findIndex(prev => prev === emp[filter]);
          if (getIndex === -1 && emp && emp[filter]) {
            EmpValueObj[filter].push(emp[filter]);
          }
        });
      });
    }


    // if(currFilterLevel!=="Select Filter" && currFilterValue!=="Select Value"){
    //   allEmployeesList.forEach((emp)=>{
    //     if(emp && (emp[currFilterLevel] === currFilterValue)){
    //       EmployeesList.push(emp);
    //     }
    //    });
    // }
    // else{
    //   EmployeesList = allEmployeesList;
    // }
    EmployeesList = allEmployeesList && allEmployeesList.length > 0 ? allEmployeesList.filter(prev => prev.isDisable === false) : [];;



  }
  if (currFilterLevel && getIndexByFilterDMGData && getIndexByFilterDMGData[currFilterLevel]) {
    EmpValueList = getIndexByFilterDMGData[currFilterLevel];
  }




  //--------------------------------------------------------------------------------

  let User_List = [];

  if (campaignFormData.campaign_type === "CUSTOMER") {
    User_List = CustomerList;
  }
  else {
    User_List = EmployeesList;
  }

  // console.log('<<<<<<<<-EmployeesList:----------> ',EmployeesList);
  // console.log('<<<<<<<<-CustomerList:----------> ',CustomerList);


  //--------------------------------------------------------------------------------

  let IndexList = []
  let pageCount = 7
  let IndexListIndex = []
  let indexA = 0
  let indexB = 0

  if (User_List && User_List.length > 0) {


    if (User_List && User_List.length > 0) {
      User_List.forEach((item, index) => {
        if (index % pageCount === 0) {
          IndexList.push(index)
        }
        if (index === (User_List.length - 1)) {
          IndexList.push(index)
        }
      })
    }

    console.log('IndexList: ', IndexList)

    if (IndexList && IndexList.length > 0) {
      IndexList.forEach((item, index) => {
        if (index !== (IndexList.length - 1)) {
          IndexListIndex.push(index + 1)
        }
      })
    }





    if (IndexListIndex && IndexListIndex.length > 0) {
      IndexListIndex.forEach((item, index) => {
        if (item === currentEmpPage) {

          if (index < (IndexList.length - 2)) {
            indexA = IndexList[index];
            indexB = IndexList[index] + (pageCount - 1)
          }
          else {
            indexA = IndexList[index];
            indexB = IndexList[IndexList.length - 1]
          }
        }
      })
    }

  }


  // console.log('IndexListIndex: ',IndexListIndex)

  //--------------------------------------------------------------------------------

  let initSize = formBatchRange && formBatchRange.size ? formBatchRange.size : 1000;

  function getBatchRange(total) {
    let rangeList = []
    if (total && total > 0) {
      [...Array(total).keys()].forEach((item, index) => {
        if (index % initSize === 0) {
          rangeList.push(index)
        }
        if (index === ([...Array(total).keys()].length - 1)) {
          rangeList.push(index)
        }
      })
    }
    let RangeNameList = []
    if (rangeList && rangeList.length > 0) {
      rangeList.forEach((item, index) => {
        if ((index + 1) !== rangeList.length) {
          let front = (item + 1).toString();
          let end = (item + initSize).toString();
          if ((index + 1) === (rangeList.length - 1)) {
            end = total.toString()
          }
          let temp = {
            "label": front + " - " + end,
            "rangeNo": index + 1
          }
          RangeNameList.push(temp);
        }
      });
    }
    return RangeNameList
  }


  let RangeLabelList = getBatchRange(AllEmpTotal);

  console.log('Range: ', RangeLabelList)
  console.log('AllEmpTotal: ', AllEmpTotal)

  //  let sizeList = [200,500,1000,2000,3000,5000]

  // let sizeList = [1000]



  let { campaignTypeList, handleCampaignType } = props;



  ////console.log("employeeValueSearch: ", employeeValueSearch)



  function getLauchTypeLabel(value) {
    let getIndex = campaignRecursiveFreqList.findIndex(prev => prev.value === value);
    let label = ""
    if (getIndex !== -1) {
      label = campaignRecursiveFreqList[getIndex]["label"]
    }
    return label
  }


  function getRECURISIVETypeLabel(value) {
    let getIndex = campaignRecursiveTypeList.findIndex(prev => prev.value === value);
    let label = ""
    if (getIndex !== -1) {
      label = campaignRecursiveTypeList[getIndex]["label"]
    }
    return label
  }

  let isAutoFillShow = false;
  if (getSurveyData && getSurveyData.isRiskSurveyType && getSurveyData.isRiskSurveyType === "DEFAULTEXIT") {
    isAutoFillShow = true
  }


  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }


  return (

    <main className="w-full md:p-6 p-4  bg-[#f9fafb] h-auto ">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 ">

        {/* form */}
        <div className="w-full px-4 space-y-4 bg-white border rounded-md lang md:px-6">

          <div className='xl:text-xl text-lg font-medium text-[#3D405B] -mx-6 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 '>
            <div>
              <h2 className="text-xl font-medium bg-white">Create Campaign</h2>
            </div>
            {isAutoFillShow ?
              <>
                <div className=''>
                  <div onClick={() => handleExitSurveyAutofill()} className='p-1 px-2 text-white bg-blue-500 rounded-lg cursor-pointer'>Autofill</div>
                </div>

              </>
              : null}
          </div>


          <div className="space-y-4">
            <div className="space-y-1">
              <label className="text-sm font-normal text-gray-900">Campaign Name</label>
              <input type="text" name="title" value={campaignFormData.title} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Title"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
            </div>


            <div className="space-y-1">
                <div className="flex justify-between items-center">

                  <div className="flex"></div>

                  <div className="flex">
                    <div className="p-2 font-bold ml-6 text-green-500">New Template</div>
                    <div className="p-2">
                      {campaignFormData.isNewTemplate ? (
                        <>
                          <div
                            onClick={() => handleNewTemplate(!campaignFormData.isNewTemplate)}
                            className="rounded-full w-11 h-5 p-0.5 bg-blue-400 cursor-pointer"
                          >
                            <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => handleNewTemplate(!campaignFormData.isNewTemplate)}
                            className="rounded-full w-11 h-5 p-0.5 bg-gray-200 cursor-pointer"
                          >
                            <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                </div>
              </div>


            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-1">
                <label className="text-sm font-normal text-gray-900">Campaign Type</label>
                <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                  <span className="w-full pr-4 text-sm font-medium text-slate-800" onClick={() => handleshowCampaignTypeDropdown(!showCampaignTypeDropdown)}>{
                    campaignFormData && campaignFormData.campaign_type ? capitalizeFirstLetter(campaignFormData.campaign_type) : "Select Type"
                  }</span>
                  <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowCampaignTypeDropdown(!showCampaignTypeDropdown)}>arrow_drop_down</span>

                  {showCampaignTypeDropdown ?
                    <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                      {campaignTypeList && campaignTypeList.length > 0 ?
                        campaignTypeList.map((item) =>
                          (item !== campaignFormData.campaign_type) ?
                            <span onClick={() => {
                              handleCampaignType(item);
                              handleshowCampaignTypeDropdown(!showCampaignTypeDropdown);
                            }}
                              className={(campaignFormData.campaign_type === item) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                                "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                              {capitalizeFirstLetter(item)}</span>
                            : null
                        )
                        : null}
                    </div>
                    : null}
                </div>
              </div>


              <div className="space-y-1">
                <label className="text-sm font-normal text-gray-900">Campaign Mode</label>

                <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                  <span className="w-full pr-4 text-sm font-medium text-slate-800" onClick={() => handleshowTypeDropdown(!showTypeDropdown)}>{
                    campaignFormData && campaignFormData.type ? capitalizeFirstLetter(campaignFormData.type) : "Mode"
                  }</span>
                  <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowTypeDropdown(!showTypeDropdown)}>arrow_drop_down</span>

                  {showTypeDropdown ?
                    <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                      {TypeList && TypeList.length > 0 ?
                        TypeList.map((item) =>
                          (item !== campaignFormData.type) ?
                            <span onClick={() => {
                              handleType(item);
                              handleshowTypeDropdown(!showTypeDropdown);
                            }}
                              className={(campaignFormData.type === item) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                                "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                              {capitalizeFirstLetter(item)}</span>
                            : null
                        )
                        : null}
                    </div>
                    : null}
                </div>
              </div>
            </div>

            {campaignFormData.type === "EMAIL" ?
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="space-y-1">
                  <label className="text-sm font-normal text-gray-900">Email Subject</label>
                  <input type="text" name="subject" value={campaignFormData.subject} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["subject_" + CurrentLanguage] ? LanguageObj["subject_" + CurrentLanguage] : "Subject"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                </div>
                <div className="space-y-1">
                  <label className="text-sm font-normal text-gray-900">Email Name</label>
                  <input type="text" name="emailName" value={campaignFormData.emailName} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["emailName_" + CurrentLanguage] ? LanguageObj["emailName_" + CurrentLanguage] : "Name"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                </div>
                <div className="space-y-1 md:col-span-2">
                  <label className="text-sm font-normal text-gray-900">Email Message</label>
                  {/* <textarea type="text" name="discption" rows={4} className="w-full p-2 px-2 text-sm text-gray-900 border border-b rounded outline-none resize-none" defaultValue={""} /> */}
                  <ReactQuill className="w-12/12 " theme="snow" value={campaignFormData.message}
                    onChange={handleMessageInput} placeholder={"Message"} />

                </div>
                <div className='flex items-center justify-end md:col-span-2'>
                  <div onClick={() => handleMessageInput(" [variable] ", 2)}
                    className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3]/10 text-[#2196f3] rounded-md '>
                    Add Reviewee Variable</div>
                </div>

              </div>
              : null}


            <div className="grid grid-cols-2 gap-4 pt-4">

              <div className="space-y-1 ">
                <label className="text-sm font-normal text-gray-900">Team Name</label>
                <input type="text" name="teamname" value={campaignFormData.teamname} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["team_name_" + CurrentLanguage] ? LanguageObj["team_name_" + CurrentLanguage] : "Team"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
              </div>
              <div className="w-full space-y-1">
                <label className="text-sm font-normal text-gray-900">No. of employees:</label>
                {campaignFormData.campaign_type === "RECURSIVE" ?
                  <input value={"All"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                  :
                  <input value={employees && employees.length > 0 ? employees.length : 0} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                }
              </div>
              <div className="w-40 mb-1.5 whitespace-nowrap">
                <label className="text-sm text-gray-500 capitalize cursor-pointer">
                  <input
                    id="mandatory-check"
                    value={campaignFormData.isTest}
                    checked={campaignFormData.isTest}
                    type="checkbox"
                    onChange={() => { }}
                    className="mr-2 cursor-pointer"
                    onClick={() => handleTestUserCheck(!campaignFormData.isTest)}
                  />

                  test user</label>
              </div>
            </div>
            {/* <button className="flex items-center justify-center w-full p-2 px-3 text-xs font-medium text-center capitalize rounded text-sky-500">
            <span className="mr-2 material-symbols-outlined">add</span> employee</button> */}
            <div className="flex justify-center pt-2">
              <span className="px-1 text-xs font-medium text-red-500">{campaignValidationList && campaignValidationList.length > 0 ? "Please enter :- " + campaignValidationList.map(element => { return element }).join(", ") : ""}</span>
            </div>


          </div>
          <div className="flex justify-start py-4 pt-0">
            <button
              onClick={() => handleCampaign360Submit()}
              className="bg-[#2196F3] text-white   py-3 px-6   text-base  justify-center  flex items-center uppercase font-medium rounded-md"
            >
              Publish
              <span
                className="px-2 material-symbols-outlined"
                style={{ fontSize: "1.2rem" }}
              >
                send
              </span>
            </button>
          </div>

        </div>

        {campaignFormData.campaign_type === "NORMAL" || campaignFormData.campaign_type === "" ?

          <div
            className="relative w-full px-4 mr-4 space-y-4 bg-white border rounded-md md:px-6 "  >
            <div className="xl:text-xl text-lg font-medium text-[#3D405B] -mx-6 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4  ">

              <h2 className="text-xl font-medium ">Relation List</h2>
              <div className="flex items-center px-2 py-2 space-x-2 text-gray-500 bg-white border rounded-full md:w-auto w-60">
                <input type="text" value={employeeValueSearch} onChange={handleEmployeeValueSearch} placeholder="Search..." className="text-xs focus:outline-none" />
                <span className="font-bold cursor-pointer material-symbols-outlined" onClick={() => handleEmployeeValueSearchFinal()} style={{ fontSize: '1.2rem' }}>search </span>

              </div>
            </div>

            <div className=""   >
              <div className="overflow-auto lang" >
                <div className="">
                  <table className="border whitespace-nowrap">
                    <thead>
                      <tr className="bg-white">
                        <th className="px-4 py-3 border-b text-left text-xs leading-4 font-semibold tracking-wider text-[#212121] ">
                          <input type="checkbox" checked={selectAllEmp} onClick={() => SelectAllEmployees(selectAllEmp, EmployeesList)} name="list-check" className="mr-4" />
                          Reviewer Name</th>
                        <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121] ">
                          Reviewer Email</th>
                        <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121] ">
                          Reviewer emp_id</th>
                        <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121] ">
                          Reviewee emp_id</th>
                        <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121] ">
                          Reviewer Relation</th>
                        <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121] ">
                          Relation Type</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {EmployeesList && EmployeesList.length > 0 ?
                        EmployeesList.map((item, index) =>
                          (index >= indexA && index <= indexB && (!item.isDisable)) ?

                            <tr>
                              <td className="px-4 py-4 border-b border-gray-200">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 ">
                                    <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item["id"]) ? true : false} onClick={() => handleCampaignEmployees({
                                      "name": item["reviewer_name"],
                                      "id": item["id"]
                                    })} onChange={() => { }} />
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-xs leading-5 text-[#3D405B]">{item["reviewer_name"]}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 border-b border-gray-200">
                                <div className="leading-5 text-[#3D405B] text-xs">{item["reviewer_email"]}</div>
                              </td>
                              <td className="px-6 py-4 border-b border-gray-200">
                                <div className="leading-5 text-[#3D405B] text-xs">{item["reviewer_emp_id"]}</div>
                              </td>
                              <td className="px-6 py-4 border-b border-gray-200">
                                <div className="leading-5 text-[#3D405B] text-xs">{item["reviewee_emp_id"]}</div>
                              </td>
                              <td className="px-6 py-4 border-b border-gray-200">
                                <div className="leading-5 text-[#3D405B] text-xs">{item["reviewer_relation"]}</div>
                              </td>
                              <td className="px-6 py-4 border-b border-gray-200">
                                <div className="leading-5 text-[#3D405B] text-xs">{item["relation_type"]}</div>
                              </td>
                            </tr>

                            : null
                        )
                        : null}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="flex justify-center items-center space-x-6 text-[#3D405B] text-sm font-medium p-6">
              <div class="flex border divide-x rounded-md ">
                <button
                  onClick={() => prevEmpPage()}
                  class="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
                >
                  Previous
                </button>

                {IndexListIndex && IndexListIndex.length > 0
                  ? IndexListIndex.map((item, index) =>
                    index >= EmpIndexA && index <= EmpIndexB ? (
                      item === currentEmpPage ? (
                        <button
                          onClick={() => SelectEmpPage(item)}
                          class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] "
                        >
                          {item}
                        </button>
                      ) : (
                        <button
                          onClick={() => SelectEmpPage(item)}
                          class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition "
                        >
                          {item}
                        </button>
                      )
                    ) : null
                  )
                  : null}

                <button
                  onClick={() => nextEmpPage(IndexListIndex)}
                  class="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          : null}

        {campaignFormData.campaign_type === "CUSTOMER" ?

          <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">
            <h2 className="xl:text-xl text-lg font-medium text-[#3D405B] -mx-6 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4  ">Customer list</h2>



            <div className="flex flex-col ">
              <div className="overflow-x-auto ">
                <div className="inline-block min-w-full overflow-hidden align-middle">
                  <table className="min-w-full whitespace-nowrap">
                    <thead>
                      <tr className="bg-white">
                        <th className="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                          <input type="checkbox" name="list-check" className="mr-4" />Name</th>
                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                          Email Address</th>
                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                          Mobile No.</th>
                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center border-b border-gray-200 text-slate-800">
                          Status</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">


                      {CustomerList && CustomerList.length > 0 ?
                        CustomerList.map((item, index) =>
                          (index >= indexA && index <= indexB) ?

                            <tr>
                              <td className="px-4 py-4 border-b border-gray-200">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 ">
                                    <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id) ? true : false} onClick={() => handleCampaignEmployees(item)} onChange={() => { }} />
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-xs leading-5 text-gray-500">{(item && item.name ? item.name : 'Not Available').slice(0, 15)}y</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 border-b border-gray-200">
                                <div className="text-xs leading-5 text-gray-500">{item && item.email ? item.email : 'Not Available'}</div>
                              </td>
                              <td className="px-6 py-4 text-xs font-medium text-gray-500 border-b border-gray-200">
                                {item && item.mobile ? item.mobile : 'Not Available'}
                              </td>
                              <td className="px-6 py-4 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200">
                                {item.isDisable ?
                                  <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-red-100 text-green-500">InActive</span>
                                  :
                                  <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-green-100 text-green-500">Active</span>
                                }
                              </td>
                            </tr>

                            : null
                        )
                        : null}

                    </tbody>
                  </table>
                </div>
              </div>


              <div className="flex items-center justify-center p-4 text-center bg-white rounded-b-md ">
                <span onClick={() => prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_left</span>
                {IndexListIndex && IndexListIndex.length > 0 ?
                  IndexListIndex.map((item, index) =>
                    (index >= EmpIndexA && index <= EmpIndexB) ?
                      (item === currentEmpPage) ?
                        <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border rounded-full cursor-pointer">{item}</span>
                        :
                        <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full cursor-pointer">{item}</span>
                      : null
                  )

                  : null}
                <span onClick={() => nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_right</span>
              </div>


            </div>
          </div>

          : null}

        {campaignFormData.campaign_type === "KIOSK" ?

          <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">



          </div>

          : null}

        {campaignFormData.campaign_type === "RECURSIVE" ?

          <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">
            <h2 className=" xl:text-xl text-lg font-medium text-[#3D405B] -mx-6 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 ">Recursive Options</h2>
            <div className="grid gap-4 capitalize bg-white rounded md:grid-cols-2 ">

              <div className="space-y-1">
                <label className="text-xs font-medium text-gray-500">Recursive Type</label>
                {/* <div className="flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer"> <span>Select</span> <span className="material-symbols-outlined">arrow_drop_down</span></div> */}

                <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 bg-white border rounded cursor-pointer">
                  <span className="w-full pr-4 text-sm font-medium text-slate-800" onClick={() => handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown)}>{
                    recursiveSettings && recursiveSettings.recursiveType ? capitalizeFirstLetter(getRECURISIVETypeLabel(recursiveSettings.recursiveType)) : 'Select Recursive Type'
                  }</span>
                  <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown)}>arrow_drop_down</span>

                  {showRecursiveTypeDropdown ?
                    <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                      {campaignRecursiveTypeList && campaignRecursiveTypeList.length > 0 ?
                        campaignRecursiveTypeList.map((item) =>
                          <span onClick={() => {
                            handleRecursiveType(item.value);
                            handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown);
                          }}
                            className={(recursiveSettings.isLaunchNow === item.value) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                              "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                            {capitalizeFirstLetter(item.label)}</span>
                        )
                        : null}
                    </div>
                    : null}
                </div>
              </div>

              <div className="space-y-1">
                <label className="text-xs font-medium text-gray-500">Launch Type</label>
                {/* <div className="flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer"> <span>Select</span> <span className="material-symbols-outlined">arrow_drop_down</span></div> */}

                <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 bg-white border rounded cursor-pointer">
                  <span className="w-full pr-4 text-sm font-medium text-slate-800" onClick={() => handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown)}>{
                    recursiveSettings && recursiveSettings.isLaunchNow ? getLauchTypeLabel(true) : capitalizeFirstLetter(getLauchTypeLabel(false))
                  }</span>
                  <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown)}>arrow_drop_down</span>

                  {showCampaignRecursiveDropdown ?
                    <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                      {campaignRecursiveFreqList && campaignRecursiveFreqList.length > 0 ?
                        campaignRecursiveFreqList.map((item) =>
                          <span onClick={() => {
                            handleCampaignRecursiveType(item.value);
                            handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown);
                          }}
                            className={(recursiveSettings.isLaunchNow === item.value) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                              "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                            {capitalizeFirstLetter(item.label)}</span>
                        )
                        : null}
                    </div>
                    : null}
                </div>
              </div>

              {!recursiveSettings.isLaunchNow ?
                <div className="space-y-1">
                  <label className="text-xs font-medium text-gray-500">Launch Date</label>
                  <input type="date" name="date" value={recursiveSettings.date} checked={recursiveSettings.isLaunchNow} onChange={handleRecursiveCampaignInput} placeholder={"Survey Date"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                </div>
                : null}

              <div className="space-y-1">
                <label className="text-xs font-medium text-gray-500">Recursive Frequency</label>
                <input type="number" name="frequency" value={recursiveSettings.frequency} onChange={handleRecursiveCampaignInput} placeholder={"Survey Date"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
              </div>


              {recursiveSettings.recursiveType === "RECURSIVE_EVENT" ?
                <div className="space-y-1">
                  <label className="text-xs font-medium text-gray-500">Employee Field Logic</label>
                  <input type="text" disabled={true} value={"(currentDate - Employee_startDate) = " + recursiveSettings.frequency} placeholder={"Survey Date"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                </div>
                : null}
            </div>



            <div className="w-full space-y-4 overflow-y-auto rounded-md">
              <h2 className="text-xl font-medium ">Employees list</h2>
              <div className="flex flex-wrap gap-2 " >
                <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
                  <div className="flex items-center space-x-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-filter"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#fff"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        fill="none"
                      ></path>
                      <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
                    </svg>
                    <span
                      className="text-sm text-white "
                      onClick={() => handleEmpLevelDropdown(showLevelFilter)}
                    >
                      {currFilterLevel === "Select Filter"
                        ? "Filter"
                        : currFilterLevel}
                    </span>
                  </div>
                  <span
                    className="text-white cursor-pointer material-symbols-outlined"
                    onClick={() => handleEmpLevelDropdown(showLevelFilter)}
                  >
                    arrow_drop_down
                  </span>

                  {showLevelFilter ? (
                    <div className="absolute left-0 z-10 w-48 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs h-60">
                      <span
                        onClick={() => handleLevelSelect("All")}
                        className={
                          false
                            ? "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                            : "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                        }
                      >
                        {"All"}
                      </span>
                      {EmpFilterData && EmpFilterData.length > 0
                        ? EmpFilterData.map((item, index) => (
                          <span
                            onClick={() => handleLevelSelect(item.name)}
                            className={
                              currFilterLevel === item.name
                                ? "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                                : "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                            }
                          >
                            {item.name}
                          </span>
                        ))
                        : null}
                    </div>
                  ) : null}
                </div>

                {currFilterLevel !== "Select Filter" ? (
                  <>
                    <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                      <span
                        style={{ height: "20px" }}
                        className=" text-sm text-[#3D405B]/70 font-medium "
                        onClick={() =>
                          handleEmpValueDropdown(showValueFilter)
                        }
                      >
                        {currFilterValue}
                      </span>
                      <span
                        className="text-gray-500 cursor-pointer material-symbols-outlined"
                        onClick={() =>
                          handleEmpValueDropdown(showValueFilter)
                        }
                      >
                        arrow_drop_down
                      </span>

                      {showValueFilter ? (
                        <div className="absolute left-0 z-20 w-56 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs h-60 scollar-xs-blue">
                          {EmpValueList && EmpValueList.length > 0
                            ? EmpValueList.map((item, index) =>
                              item.name !== "ManagerPID" ? (
                                <span
                                  onClick={() => handleValueSelect(item)}
                                  className={
                                    currFilterValue === item
                                      ? "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100"
                                      : "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"
                                  }
                                >
                                  {item}
                                </span>
                              ) : null
                            )
                            : null}
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}

                <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                  <span
                    className=" text-sm text-[#3D405B]/70 font-medium "
                    onClick={() => handleBatchRangeDropdown(!showBatchRange)}
                  >
                    {formBatchRange && formBatchRange.label
                      ? formBatchRange.label
                      : "Select Batch"}
                  </span>
                  <span
                    className="text-gray-500 cursor-pointer material-symbols-outlined "
                    onClick={() => handleBatchRangeDropdown(!showBatchRange)}
                  >
                    arrow_drop_down
                  </span>

                  {showBatchRange ? (
                    <div className="absolute left-0 w-full overflow-hidden font-bold text-gray-500 bg-white rounded-md shadow-md top-10">
                      {RangeLabelList && RangeLabelList.length > 0
                        ? RangeLabelList.map((item, index) => (
                          <span
                            onClick={() => selectBatchRange(item)}
                            className={
                              formBatchRange.rangeNo === item.rangeNo
                                ? "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100 font-bold"
                                : "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100 font-bold"
                            }
                          >
                            {item.label}
                          </span>
                        ))
                        : null}
                    </div>
                  ) : null}
                </div>

                <button
                  onClick={() => handleGetEmployees()}
                  className="bg-[#2196f3] hover:bg-[#1E88E5] transition-all duration-200 w-auto md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md"
                >
                  Employees
                </button>
              </div>
              <div className="flex items-center justify-between ">
                <h1 className="text-sm text-[#3D405B]/70 ">
                  Current Showing :
                  <b className="block font-medium text-slate-800 md:inline-block">
                    {" "}
                    {EmployeesList && EmployeesList.length > 0
                      ? EmployeesList.length
                      : 0}{" "}
                    of {AllEmpTotal ? AllEmpTotal : 0}
                  </b>
                </h1>

                <div className="flex justify-between space-x-4">
                  <div className="flex items-center w-full px-2 py-2 space-x-2 text-gray-500 bg-white border rounded-full md:w-auto">
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "1.2rem" }}
                    >
                      search{" "}
                    </span>
                    <input
                      type="text"
                      value={employeeValueSearch}
                      onChange={handleEmployeeValueSearch}
                      placeholder="Search..."
                      className="text-xs focus:outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="overflow-x-auto ">
                  <div className="inline-block min-w-full overflow-hidden align-middle">
                    <table className="min-w-full whitespace-nowrap">
                      <thead>
                        <tr className="bg-white">
                          <th className="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                            Name</th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                            Email Address</th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                            Mobile No.</th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center border-b border-gray-200 text-slate-800">
                            Status</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">


                        {EmployeesList && EmployeesList.length > 0 ?
                          EmployeesList.map((item, index) =>
                            (index >= indexA && index <= indexB) ?

                              <tr>
                                <td className="px-4 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-gray-500">{(item && item.name ? item.name : 'Not Available').slice(0, 15)}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-gray-500">{item && item.email ? item.email : 'Not Available'}</div>
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-gray-500 border-b border-gray-200">
                                  {item && item.mobile ? item.mobile : 'Not Available'}
                                </td>
                                <td className="px-6 py-4 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200">
                                  {item.isDisable ?
                                    <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-red-100 text-green-500">InActive</span>
                                    :
                                    <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-green-100 text-green-500">Active</span>
                                  }
                                </td>
                              </tr>

                              : null
                          )
                          : null}

                      </tbody>
                    </table>
                  </div>
                </div>


                <div className="flex items-center justify-center p-4 text-center bg-white rounded-b-md ">
                  <span onClick={() => prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_left</span>
                  {IndexListIndex && IndexListIndex.length > 0 ?
                    IndexListIndex.map((item, index) =>
                      (index >= EmpIndexA && index <= EmpIndexB) ?
                        (item === currentEmpPage) ?
                          <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border rounded-full cursor-pointer">{item}</span>
                          :
                          <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full cursor-pointer">{item}</span>
                        : null
                    )

                    : null}
                  <span onClick={() => nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_right</span>
                </div>


              </div>
            </div>


          </div>

          : null}

      </div>

    </main>

  );
}
