import React, { Component } from "react";
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      getDemographicIndexEngCompOverallData,
      getEMPFilterLabel,
      GetParameterLabel,
      getOrgIndexV2Data,
      getSurveyData,
      getDemographicIndexMultiFilterEngCompData,
      surveyLogo,
      CountRestrict,
      surveyNAME,
      createdAt
    } = this.props;

    let FinalData = [];
    if (
      getDemographicIndexMultiFilterEngCompData &&
      getDemographicIndexMultiFilterEngCompData.length > 0
    ) {
      getDemographicIndexMultiFilterEngCompData.forEach((item) => {
        let tempData = {
          xAxis: item.xAxis,
          Data: [],
        };
        let innerList = [];
        if (item && item.Data && item.Data.length > 0) { 
          item.Data.forEach((inner) => {
            console.log("inner[1]",inner[1])
            if (inner &&
              inner[1] &&
              inner[1] !== "NA" &&
              inner[1].toFixed(2) && !CountRestrict(inner.count)) {
              let score = CountRestrict(inner.count) ? "NA" : inner[2];
              innerList.push([inner.name, score]);
            }


          });
        }

        if (item.xAxis.trim() === "Grade") {
          let ageLabelList = [
            "L1",
            "L2",
            "L3",
            "L4",
            "L5",
            "L6",
            "L7",
            "L8",
            "L9",
            "L10",
            "L11",
            "L12",
            "L13",
            "L14",
            "L15",
            "L16",
            "L17",
            "L18",
            "L19",
            "L20",
            "L7A",
            "SA",
            "Sa",
          ];

          innerList.sort(
            (a, b) => ageLabelList.indexOf(a[0]) - ageLabelList.indexOf(b[0])
          );
        } else if (item.xAxis.trim() === "Age") {
          let ageLabelList = [
            "25 Yrs & Below",
            "Between 25 to 30 yrs",
            "Between 31 to 34 yrs",
            "Between 35 to 44 yrs",
            "Between 45 to 55 yrs",
            "Above 55 Yrs",
          ];

          innerList.sort(
            (a, b) => ageLabelList.indexOf(a[0]) - ageLabelList.indexOf(b[0])
          );
        } else {
          innerList.sort(
            (a, b) =>
              (a && a[0] && a[0].toString()
                ? a[0].toString().charCodeAt(0)
                : "") -
              (b && b[0] && b[0].toString()
                ? b[0].toString().charCodeAt(0)
                : "")
          );
        }

        tempData["Data"] = innerList;
        FinalData.push(tempData);
      });
    }

    function calcOverall(axisItem, dataItem) {
      let overallX = 0;
      if (
        getDemographicIndexEngCompOverallData &&
        getDemographicIndexEngCompOverallData.length > 0
      ) {
        let getIndex1 = getDemographicIndexEngCompOverallData.findIndex(
          (prev) => prev.xAxis === axisItem
        );
        if (getIndex1 !== -1) {
          let overallData = getDemographicIndexEngCompOverallData[getIndex1];
          let innerOverallList =
            overallData && overallData.Data ? overallData.Data : [];
          let getIndex2 = innerOverallList.findIndex(
            (prev) => prev.name === dataItem
          );
          let innerData = innerOverallList[getIndex2];
          overallX = innerData && innerData[2] ? innerData[2] : 0;
        }
      }
      // ////console.log("item-ssssss--------->",item)
      return overallX && overallX.toFixed(2) ? overallX.toFixed(2) : 0;
    }






    function chunkList(array) {
      const chunkSize = 10;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      //console.log("list--->", list);
      return list;
    }


    return (
      <>
        {FinalData && FinalData.length > 0 ?
          chunkList(FinalData).map((NewQues, indexX) =>
            <>
              {NewQues && NewQues.length > 0 ?
                NewQues.map((item, index0) => (
                  <>

                    {item && item.Data && item.Data.length > 0 ?
                      chunkList(item.Data).map((itemXData, indexXY) =>
                        <>
                          <section className=" px-8 py-6 page-break">
                            <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
                              <p class="font-medium italic text-base ">{surveyNAME}</p>
                              <p class="font-medium italic text-base">{createdAt}</p>
                            </div>

                            <main className="w-full">
                              <div className="text-left ">
                                <div className="  ">
                                  <Header surveyLogo={surveyLogo} />
                                  <div className>
                                    <div className="w-full  py-6 capitalize ">
                                      <h1 className="text-2xl text-[#212112] font-medium  w-full   capitalize">
                                        {"Engagement Comparison - " +
                                          getEMPFilterLabel(item.xAxis) + (indexXY > 0 ? " (Continue)" : "")}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <table className="w-full">
                                <tbody className="divide-y-2 divide-white " >
                                  {itemXData && itemXData.length > 0
                                    ? itemXData.map((itemData, index) => (
                                      <>
                                        {index % 15 === 0 ? (
                                          <>
                                            {index ? (
                                              <tr className="text-gray-500 capitalize text-lg  ">
                                                <th
                                                  className="w-12 font-bold p-2  text-left text-gray-700 "
                                                  colSpan={5}
                                                >
                                                  Continue...
                                                </th>
                                              </tr>
                                            ) : null}
                                            <tr className="text-left text-[#212121] capitalize border-white border-b bg-[#DBEEFD] text-sm border-l border-t">
                                              <th className="w-[25%] font-medium p-2 text-left border-r-2 border-white" >
                                                {getEMPFilterLabel(item.xAxis)}
                                              </th>
                                              <th className="w-[15%] font-medium p-2 text-right border-r-2 border-white" >
                                                Compare
                                              </th>
                                              <th className="w-[35%] font-medium p-2 text-left border-r-2 border-white" >
                                                Score
                                              </th>
                                              <th className="w-[15%] font-medium p-2 text-center border-r-2 border-white" >
                                                Difference
                                              </th>
                                            </tr>
                                          </>
                                        ) : null}

                                        <tr className="border-b-2 text-[#212121] text-sm border-l border-white bg-[#f5f5f5]">
                                          <td className="p-2 font-medium capitalize border-r-2 border-white" rowSpan={2}>
                                            {GetParameterLabel(itemData[0])}
                                          </td>
                                          <td className="p-2 text-sm text-right border-r-2 border-white bg-[#e9e9e9]">
                                            score
                                          </td>
                                          <td className="p-2 text-sm border-r-2 border-white bg-[#e9e9e9]" >
                                            <div className="h-6 w-full bg-[#CFD8DC]">
                                              <div
                                                className="w-full h-6 bg-blue-400 flex items-center"
                                                style={{
                                                  background: "#1E88E5",
                                                  width:
                                                    (itemData &&
                                                      itemData[1] &&
                                                      itemData[1] !== "NA"
                                                      ? itemData[1]
                                                      : "0") + "%",
                                                }}
                                              >
                                                <span className="text-sm text-white px-2">  {itemData &&
                                                  itemData[1] &&
                                                  itemData[1] !== "NA" &&
                                                  itemData[1].toFixed(2)
                                                  ? itemData[1].toFixed(2) + "%"
                                                  : "NA"}</span>
                                              </div>
                                            </div>

                                          </td>
                                          <td rowSpan={2} className=" px-2 text-sm font-medium text-center text-[#4CAF50] ">+88.29 </td>
                                        </tr>
                                        <tr className="bg-[#f5f5f5]">
                                          <td className="p-2 text-sm text-right border-r-2 border-white">
                                            Overall
                                          </td>
                                          <td className="p-2 text-sm border-r-2 border-white" >

                                            <div className="h-6 w-full bg-[#CFD8DC]">
                                              <div
                                                className="w-full h-6 bg-blue-400 flex items-center"
                                                style={{
                                                  background: "#9C27B0",
                                                  width:
                                                    (itemData &&
                                                      itemData[1] &&
                                                      itemData[1] !== "NA"
                                                      ? itemData[1]
                                                      : "0") + "%",
                                                }}
                                              >
                                                <span className="text-sm text-white px-2">  {itemData &&
                                                  itemData[1] &&
                                                  itemData[1] !== "NA" &&
                                                  itemData[1].toFixed(2)
                                                  ? itemData[1].toFixed(2) + "%"
                                                  : "NA"}</span>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                    : null}
                                </tbody>
                              </table>





                              {indexXY < chunkList(item.Data).length - 1 ? (
                                <div className="text-lg text-gray-500 capitalize bg-white">
                                  <div
                                    colSpan={5}
                                    className="font-normal p-2 text-sm text-left text-[#3D405B]/70 "
                                  >
                                    Continue...
                                  </div>
                                </div>
                              ) : null}




                            </main>
                          </section>


                        </>
                      ) : null}

                  </>
                ))
                : null}

            </>
          ) : null}
      </>

    );
  }
}
export default Employee;
