import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions, userActions } from "../../../_actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileBox: false,
      showDropdown: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getGrowProfile());
  }

  openProfile = (check) => {
    this.setState({ showProfileBox: !check });

    setTimeout(() => {
      this.setState({ showProfileBox: false });
    }, 4000);
  };
  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  };

  handleOverview = () => {
    let { onOverviewFromHeader } = this.props;
    console.log("working-------------------------------------------------------------------------")
    onOverviewFromHeader();
  };



  render() {
    let {
      dashboard,
      handleRedirect
    } = this.props;
    let { showProfileBox } = this.state;
    let { GrowProfileData } = dashboard;

    let ProfileName = "";
    let ProfileEmail = "";

    let ProfileGender = "";
    if (GrowProfileData && GrowProfileData.name) {
      let nameGender = "";
      if (GrowProfileData && GrowProfileData.Gender) {
        nameGender = GrowProfileData.Gender;
      } else if (GrowProfileData && GrowProfileData.gender) {
        nameGender = GrowProfileData.gender;
      }

      ProfileGender = nameGender;
      ProfileName = GrowProfileData.name;
      ProfileEmail = GrowProfileData.email;
    }


    // console.log("GrowProfileDataGrowProfileDataGrowProfileDataGrowProfileData", ProfileEmail)

    return (
      <>
        <div className="border-b w-full">
          <div className="xl:w-11/12 mx-auto xl:px-0 px-4 flex justify-between items-center">
            <div className="flex items-center lg:space-x-6 space-x-4">
              <img src='/img/grow/groplus-logo.svg' alt="groplus-logo" className="h-8" />
            </div>

            <div className="flex items-center justify-between py-3">
              <div className="relative flex items-center ">

                <div onClick={() => this.openProfile(showProfileBox)} className="ml-2 lg:flex items-center cursor-pointer">
                  {ProfileName ? (
                    <div className="flex justify-center items-center bg-gray-200 rounded-full p-1 h-12 w-12 text-xl font-semibold">
                      <p className="text-center">{ProfileName.split(' ').map(name => name.charAt(0)).join('').toUpperCase()}</p>
                    </div>
                  ) : (
                    <img className="w-16 h-16 rounded-full bg-gray-200 object-cover" src="img/profile/user1.png" alt="Your avatar" />
                  )}
                  {/* <span className="material-symbols-outlined lg:flex hidden">arrow_drop_down</span> */}
                </div>
                {showProfileBox ? (
                  <div className="bg-white rounded-lg border shadow-xl absolute top-12 z-40 right-0 w-68">
                    <div className="flex justify-center items-center space-x-3 p-4">
                      <div>
                        {ProfileName ? (
                          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1 h-12 w-12 text-xl font-semibold">
                            <p className="text-center">{ProfileName.split(' ').map(name => name.charAt(0)).join('').toUpperCase()}</p>
                          </div>
                        ) : (
                          <img className="w-16 h-16 rounded-full bg-gray-200 object-cover" src="img/profile/user1.png" alt="Your avatar" />
                        )}

                      </div>
                      <div className="">
                        <p>{ProfileName}</p>
                        <p className="text-xs text-gray-400">{ProfileEmail}</p>
                      </div>
                    </div>
                    <div className="py-2 px-4 hover:bg-gray-100 border-b">
                      <span onClick={() => handleRedirect()} className="text-gray-500 text-sm cursor-pointer">Manage Profile</span>
                    </div>
                    <div className="hover:bg-red-100 px-4 py-4">
                      <span onClick={() => this.handleLogout()} className="text-sm hover:text-red-600 text-red-500 hover:font-medium block cursor-pointer">Logout</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard,
  };
}
export default connect(mapStateToProps)(Header);
