import React, { Component } from "react";
import { connect } from "react-redux";
import Commentary from "../Commentary/Commentary";
import Filter from "../Filters/IndexFilter/Filter";
import { CSVLink } from "react-csv";
import { spliceDecimal } from "../../FunctionLibrary/FuntionList";
import { graphic } from 'echarts';

class EngagementBehaviour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: "none",
      currFilterName: "none",

      showHeatMap: true,

      currentPage: 1,

      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
    };
  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  componentDidMount() { }

  handleViewType = (check) => {
    this.setState({ showHeatMap: !check });
  };

  storeFilterName = (val) => {
    this.setState({ currFilterName: val });
  };

  handleDistributionFilterValueSave = (val) => {
    this.setState({ currFilterValue: val });
  };
  handleDistributionFilterNameSave = (val) => {
    this.setState({ currFilterName: val });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePageReset = () => {
    this.setState({ currentPage: 1 });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterBehaviour } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    handleMultifilterBehaviour(finalFilter, true, name);
  };

  render() {
    let {
      name,
      indexType,
      optionType,
      getCommentData,
      loading,
      CountRestrict,
      getEMPFilterLabel,

      EmpFilterData,
      FilteredIndexData,
      getDistributionIndexData,
      handleDistributionFilter,
      getIndexDriverData,
      getIndexFilterData,
      getIndexByFilterDMGData
    } = this.props;

    // let list = []
    let level = "";

    if (
      FilteredIndexData &&
      FilteredIndexData.list &&
      FilteredIndexData.list.length > 0
    ) {
      // list = FilteredIndexData.list;
      level = FilteredIndexData.level;
    }

    let {
      currFilterName,
      showHeatMap,
      currentPage,
      currentSelectValue,
      FilterValues,
    } = this.state;

    let filterNames = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((item) => {
        filterNames.push(item.name);
      });
    }
    let CurrentFilterName = "";
    if (currFilterName !== "none") {
      CurrentFilterName = currFilterName;
    } else if (filterNames && filterNames.length > 0) {
      CurrentFilterName = filterNames[0];
    }

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    let DistributionIndexData = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }

      //  DistributionIndexDataScore=getIndexDriverData;
      //  DemographicDataDistribution=getIndexDistributionData;
      DistributionIndexData = getDistributionIndexData;

      // getDistributionIndexData && getIndexDriverData
    }

    let { GetParameterLabel, ErrorIndexObj, IndexDimensions } = this.props;

    let XAxis = IndexDimensions;;
    console.log("IndexDimensions--sss->", IndexDimensions)









    let YAxis = [];
    let XAxisName = [];
    let DataValues = [];
    let list = [];
    let overallData = {};

    if (getIndexDriverData) {
      overallData = {
        name: "Overall",
      };

      if (XAxis && XAxis.length > 0) {
        XAxis.forEach((dimen) => {
          XAxisName.push(dimen + "Avg")

          overallData[dimen + "Avg"] = getIndexDriverData && getIndexDriverData[dimen + "Count"] ? getIndexDriverData[dimen + "Count"] : 0
        })
      }

      if (list.findIndex((prev) => prev.name === "Overall") === -1) {
        list.push(overallData);
      }
    }

    if (getDistributionIndexData && getDistributionIndexData.length > 0) {
      getDistributionIndexData.forEach((hello) => {
        list.push(hello);
      });

      list.forEach((item, index) => {
        XAxisName.forEach((axis, axisIndex) => {
          if (index !== list.length - 1) {
            DataValues.push({
              value: [axisIndex, index, spliceDecimal(parseFloat(item[axis]), 2)],
              itemStyle: {
                color:
                  parseFloat(overallData[axis]) > parseFloat(item[axis])
                    ? parseFloat(item[axis]) === 0
                      ? "#ffb3b3"
                      : "rgba(230, 0, 0," + parseFloat(item[axis]) / 100 + ")"
                    : parseFloat(item[axis]) === 0
                      ? "#c2f0c2"
                      : "rgba(46, 184, 46," + parseFloat(item[axis]) / 100 + ")",
              },
            });
          } else {
            DataValues.push({
              value: [axisIndex, index, spliceDecimal(parseFloat(item[axis]), 2)],
              itemStyle: {
                color:
                  parseFloat(item[axis]) === 0
                    ? "#b3ecff"
                    : "rgba(0, 172, 230," + parseFloat(item[axis]) / 100 + ")",
              },
            });
          }
        });
      });
    }

    if (list && list.length > 0) {
      list.forEach((driver) => {
        YAxis.push(driver.name);
      });
    }

    // //console.log('XAxis: ',XAxis)
    // //console.log('YAxis: ',YAxis)
    // //console.log('DataValues: ',DataValues)
    //console.log('list: ', list)

    // list.reverse()

    // function getColor(data, index) {
    //   let Color = '';
    //   if(data.name==='Overall'){
    //     return Color = '#bdddf2'
    //   }
    //   else{
    //     if(overallData[index] <= data[index]){
    //       return Color = '#b2edc9'
    //     }
    //     else if(overallData[index] === data[index]){
    //       return Color = 'yellow'
    //     }
    //     else{
    //       return Color = '#f2bdbd'
    //     }
    //   }

    // }

    function showNaN(item, name) {
      let dataScore = CountRestrict(
        item && item.count ? item.count : "NA",
        item.name === "Overall"
      )
        ? "NA"
        : name && item && item[name]
          ? spliceDecimal(item[name], 2)
          : "NA";
      return dataScore;
    }

    function getColor(data, index) {
      if (data.name === "Overall" || showNaN(data, index) === "NA") {
        return { Color: "#f1f1f1", diffTextColor: "#000" };
      } else {
        let diffNo = parseInt(
          Math.abs(parseFloat(overallData[index]) - parseFloat(data[index]))
        );
        // //////console.log("diffNo:", diffNo)
        if (parseFloat(overallData[index]) <= parseFloat(data[index])) {
          //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: "linear-gradient(90deg, #33cc33, #85e085)", diffTextColor: "#ffffff" };
          } else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: "linear-gradient(90deg, #70db70, #c1f0c1)", diffTextColor: "#00000" };
          } else {
            return { Color: "linear-gradient(90deg, #adebad, #eafaea)", diffTextColor: "#00000" };
          }
        } else {
          //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: "linear-gradient(90deg, #f5babd, #fce9ea)", diffTextColor: "#000000" };
          } else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: "linear-gradient(90deg, #ea95a1, #f2bfc6)", diffTextColor: "#000000" };
          } else {
            return { Color: "linear-gradient(90deg, #d03e4d, #e1848d)", diffTextColor: "#ffffff" };
          }
        }
      }
    }

    // <HeatMap
    //   handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
    //   handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
    //   getDistributionIndexData={DistributionIndexData}
    //   getIndexDriverData={getIndexDriverData}
    //   currFilterName={CurrentFilterName}
    //   FilterValues={FilterValues}
    //   GetParameterLabel={GetParameterLabel}
    //   CountRestrict={CountRestrict}
    //   ErrorIndexObj={ErrorIndexObj}
    // />

    let ScoreExcel = [];

    let DimensionList = XAxis;

    if (DimensionList && DimensionList.length > 0) {
      DimensionList.forEach((dimen, index) => {
        let tempData = {};

        if (FilterValues && FilterValues.length > 0) {
          tempData[currFilterName ? currFilterName : "Dimension"] = dimen;
          if (list && list.length > 0) {
            list.forEach((item, index) => {
              tempData[item.name ? item.name : "Unavailable"] = item[
                dimen + "Avg"
              ]
                ? showNaN(item, dimen + "Avg")
                : "NA";
            });
          }
        } else {
          tempData["Dimension"] = dimen;
          if (list && list.length > 0) {
            list.forEach((item, index) => {
              if (index === 0) {
                tempData[item.name ? item.name : "Unavailable"] = item[
                  dimen + "Avg"
                ]
                  ? showNaN(item, dimen + "Avg")
                  : "NA";
              }
            });
          }
        }

        ScoreExcel.push(tempData);
      });
    }


    function isAllObjFieldsNotNaN(item, dimensionList, str) {

      let scoreList = []
      if (dimensionList && dimensionList.length > 0) {
        dimensionList.forEach((dimen) => {
          scoreList.push(
            item && item[dimen + str] ? item[dimen + str] : "NA"
          )
        })
      }
      console.log("scoreList---->", scoreList)
      console.log("item---->", item.name)



      // let count = 0;
      // let isAllNA = false;
      // if(dimensionList && dimensionList.length>0){
      //   dimensionList.forEach((dimen)=>{
      //     let value = item && item[dimen+str]?(item[dimen+str]):"NA";
      //     console.log("value1----->",item[dimen+str])
      //     console.log("value2----->",showNaN(item[dimen+str]))

      //     if(value === "NA"){
      //       count++
      //       console.log("count-1---->",count)

      //     }
      //   })

      //   console.log("count-2--->",count)

      //   isAllNA = dimensionList.length === count?true:false
      // }
      return true;
    }


    return (
      <>
        <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          <div className="flex flex-wrap gap-2 ">
            {ScoreExcel ? (
              <CSVLink
                data={ScoreExcel}
                filename={name + "BehaviourSummary.csv"}
              >
                <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M10 12l4 4m0 -4l-4 4" />
                  </svg>

                  <span>Download Excel</span>
                </button>
              </CSVLink>
            ) : (
              "..."
            )}

            {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />
          </div>
        </div>

        {true ? (
          <>
            {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}
            {/* 
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}

            {/* </div> */}
            {/* 
            {FilterValues && FilterValues.length > 0 ?
              <div className="items-center h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex justify-left">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null} */}

            <div onClick={() => this.closeFilter(false)} className="">
              <div className="w-full">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">
                  <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                      {name} Behaviour - All Score (%)
                    </h1>
                    {/* <div className="justify-between mb-4 md:flex">
                    <div className="text-lg font-semibold">{name} Behaviour - All Score (%)</div>
                    <div className="flex items-center text-xs">


                    </div>
                  </div> */}

                    {ErrorIndexObj && ErrorIndexObj["getIndexDriverError"] ? (
                      <>
                        <div className="my-24 text-2xl font-medium text-center text-gray-400">
                          {ErrorIndexObj["getIndexDriverError"]}
                        </div>
                      </>
                    ) : (
                      <>
                        {getDistributionIndexData && getIndexDriverData ? (
                          <>
                            {!(FilterValues && FilterValues.length > 0) ? (
                              <>
                                {list && list.length > 0 ? (
                                  <div className="w-full customscroll2   overflow-x-auto whitespace-nowrap">
                                    <table className="w-full border-collapse ">
                                      <thead>
                                        <tr className="md:text-base text-sm border border-b ">
                                          {XAxis && XAxis.length > 0 ?
                                            XAxis.map((dimen1) =>
                                              <th className="p-3 font-medium text-gray-800 border">
                                                {GetParameterLabel(dimen1)}
                                              </th>
                                            ) : null}
                                          {/*                                           
                                          <th className="p-3 font-semibold text-gray-800">
                                            {GetParameterLabel("Pride")}
                                          </th>
                                          <th className="p-3 font-semibold text-gray-800">
                                            {GetParameterLabel("Presence")}
                                          </th>
                                          <th className="p-3 font-semibold text-gray-800">
                                            {GetParameterLabel("Performance")}
                                          </th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {list && list.length > 0
                                          ? list.map((item, index) =>
                                            index === 0 ? (
                                              <tr className="mb-10 text-xs text-center  text-[#3D405B] bg-white lg:mb-0  h-20">
                                                {item.name !== "Overall" ? (
                                                  <td
                                                    className="p-3 text-left border border-b bg-gradient-to-r from-sky-100 to-sky-50"
                                                    style={{
                                                      fontWeight:
                                                        item.name ===
                                                          "Overall"
                                                          ? "bold"
                                                          : null,
                                                    }}
                                                  >
                                                    {item.name
                                                      ? item.name
                                                      : "Unavailable"}
                                                  </td>
                                                ) : null}
                                                {XAxis && XAxis.length > 0 ?
                                                  XAxis.map((dimen1) =>
                                                    <td
                                                      className="p-3 md:text-lg text-base font-semibold text-[#3D405B] border border-b bg-gradient-to-r from-[#2196F3]/20 to-[#2196F3]/10 "
                                                      style={{
                                                        background: getColor(
                                                          item,
                                                          dimen1 + "Avg"
                                                        ),
                                                      }}
                                                    >
                                                      {item && item[dimen1 + "Avg"] ? spliceDecimal(item[dimen1 + "Avg"], 2) : "NA"}
                                                    </td>
                                                  ) : null} 


                                              </tr>
                                            ) : null
                                          )
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {ErrorIndexObj &&
                                  ErrorIndexObj["getDemographicIndexError"] ? (
                                  <>
                                    <div className="my-24 text-2xl font-medium text-center text-gray-400">
                                      {
                                        ErrorIndexObj[
                                        "getDemographicIndexError"
                                        ]
                                      }
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {list && list.length > 0 ? (
                                      <div className="w-full space-y-6 ">
                                        <div className="w-full overflow-hidden customscroll2 overflow-x-auto whitespace-nowrap">
                                          <table className="w-full border ">
                                            <thead>
                                              {list && list.length > 0
                                                ? list.map((item, index) =>
                                                  index === 0 ? (
                                                    <tr className="text-xs text-gray-800 bg-gray-100 border-b">
                                                      <th className="px-4 py-2 font-bold border-r">
                                                        <div className="flex items-center font-semibold justify-left">
                                                          {currFilterName}
                                                        </div>
                                                      </th>

                                                      {XAxis && XAxis.length > 0 ?
                                                        XAxis.map((dimen1) =>
                                                          <th className="px-4 py-2 font-bold border-r">
                                                            <div className="flex items-center justify-center">
                                                              {GetParameterLabel(
                                                                dimen1
                                                              )}{" "}
                                                            </div>
                                                          </th>
                                                        ) : null}
                                                    </tr>
                                                  ) : null
                                                )
                                                : null}
                                            </thead>
                                            <tbody>
                                              {list && list.length > 0
                                                ? list.map((item, index) =>
                                                  index !== 0 || true ? (
                                                    !CountRestrict(item && item.count ? item.count : 0, item.name === "Overall") ?
                                                      <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
                                                        <td
                                                          className="p-3 font-semibold text-left border border-b"
                                                          style={{
                                                            fontWeight:
                                                              item.name ===
                                                                "Overall"
                                                                ? "bold"
                                                                : null,
                                                          }}
                                                        >
                                                          {item.name
                                                            ? item.name
                                                            : "Unavailable"}

                                                        </td>


                                                        {XAxis && XAxis.length > 0 ?
                                                          XAxis.map((dimen1) =>
                                                            <td
                                                              className="p-3 border border-b"
                                                              style={{
                                                                background: getColor(
                                                                  item,
                                                                  dimen1 + "Avg"
                                                                ).Color,
                                                                color: getColor(
                                                                  item,
                                                                  dimen1 + "Avg"
                                                                ).diffTextColor,
                                                              }}
                                                            >
                                                              {item && item[dimen1 + "Avg"]
                                                                ? showNaN(
                                                                  item,
                                                                  dimen1 + "Avg"
                                                                )
                                                                : "NA"}
                                                            </td>
                                                          ) : null}


                                                      </tr>
                                                      : null
                                                  ) : null
                                                )
                                                : null}
                                            </tbody>
                                          </table>
                                        </div>
                                        <p className="text-sm text-[#212121]/70">
                                          * We are not showing data where
                                          employee count is less than 5
                                        </p>
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : // <HeatMap
                          //   handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
                          //   handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
                          //   getDistributionIndexData={DistributionIndexData}
                          //   getIndexDriverData={getIndexDriverData}
                          //   currFilterName={CurrentFilterName}
                          //   FilterValues={FilterValues}
                          //   GetParameterLabel={GetParameterLabel}
                          //   CountRestrict={CountRestrict}
                          //   ErrorIndexObj={ErrorIndexObj}
                          // />
                          null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400">
                We have not calculated this section!
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(EngagementBehaviour);
