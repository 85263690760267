import React, { Component } from "react";
import ScoreCircleChart from "./chart/ScoreCircleChart";

import Header from "../Components/Header";

import BarChart from "./chart/BarChart";
import GaugeScore from "./chart/GaugeScore";

class ManagerOverall extends Component {
  render() {
    let {
      getQuesOverallData,
      setPDF,
      getOverallIndexData,
      getQuestionFilterData,
      getIndexData,
      indexTemplate,
      GetParameterLabel,
      CountRestrict,
      surveyLogo,
      getSelectiveScores,
      getAllBenchMarkStaticData,
      surveyNAME,
      createdAt,
      getRespondantDetailsData
    } = this.props;

    let QuestionData = getQuestionFilterData;

    //console.log('getQuestionFilterData--->', getQuestionFilterData)

    let paraList = [];

    let count = 0;
    let rowSpan = 0;
    let NewParaList = [];

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        if (
          paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
          -1
        ) {
          paraList.push(ques.parameterDimensionName);
          count += 1;
        } else {
        }
      });

      let obj = {};
      paraList.forEach((para) => {
        obj[para] = 0;
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1;
          }
        });
      });

      NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);
      //   console.log('data NewParaList: ',NewParaList)

      rowSpan = QuestionData.length / count;

      // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)
    }
    // console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total =
          ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];

        let score = parseFloat(
          (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
        );

        NewQuesList.push({
          ...ques,
          score: CountRestrict(ques.count) ? "NA" : score,
        });
      });
    }

    NewQuesList.sort((a, b) => b.score - a.score);

    //console.log('ORG ==== getIndexData ===== > : ',getIndexData)

    // let indexValue = CountRestrict(getIndexData && getIndexData["count"]?getIndexData["count"]:0)?"NA" : (getIndexData && getIndexData[indexTemplate] && parseFloat(getIndexData[indexTemplate].toFixed(2))?parseFloat(getIndexData[indexTemplate].toFixed(2)):0) ;
    // console.log('ORG ==== count ===== > : ',getIndexData && getIndexData["count"]?getIndexData["count"]:0)
    // console.log('ORG ==== getIndexData ===== > : ',getIndexData)
    let indexValue =
      getIndexData &&
        getIndexData[indexTemplate] &&
        parseFloat(getIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getIndexData[indexTemplate].toFixed(2))
        : "NA";

    let indexOverallValue =
      getOverallIndexData &&
        getOverallIndexData[indexTemplate] &&
        parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        : 0;

    let { SummaryName } = this.props;


    console.log('indexValue ===== > : ',indexValue)
    console.log('indexOverallValue ===== > : ',indexOverallValue)

    function calcFavourability(quesZ) {
      let total =
        quesZ["1"] +
        quesZ["2"] +
        quesZ["3"] +
        quesZ["4"] +
        quesZ["5"] +
        quesZ["6"];
      let scoreX = parseFloat(
        (((quesZ["5"] + quesZ["6"]) * 100) / total).toFixed(2)
      );
      return scoreX;
    }

    function calcOverall(queItem) {
      let overallX = 0;
      if (getQuesOverallData && getQuesOverallData.length > 0) {
        let getIndex = getQuesOverallData.findIndex(
          (prev) => prev.name === queItem.name
        );
        let overallQue = getQuesOverallData[getIndex];
        overallX = calcFavourability(overallQue);
      }
      return overallX;
    }

    let isScoreFormat = false






    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true,
    };

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0];
      ParticipationObj["total"] = apiData["total"] ? apiData["total"] : 0;
      ParticipationObj["completed"] = apiData["completed"]
        ? apiData["completed"]
        : 0;
      ParticipationObj["mailSent"] = apiData["mailSent"]
        ? apiData["mailSent"]
        : 0;
      ParticipationObj["clicked"] = apiData["clicked"] ? apiData["clicked"] : 0;
      ParticipationObj["participated"] =
        apiData["completed"] && apiData["total"]
          ? ((apiData["completed"] * 100) / apiData["total"]).toFixed(2)
          : 0;
    }



    return (
      <>

        <div className="px-8 py-6 page-break">
          {/* report header */}
          <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
            <p class="font-medium italic text-base ">{surveyNAME}</p>
            <p class="font-medium italic text-base">{createdAt}</p>
          </div>
          <div className=" ">
            <Header surveyLogo={surveyLogo} />



            <div className>
              <div className="capitalize w-full   py-6 ">
                <div className="text-indigo-700 opacity-90"></div>
                <h1 className="text-2xl text-[#212121] font-medium  capitalize">
                  {SummaryName
                    ? SummaryName +
                    "s Play a Pivotal Role In Driving Employee Experience"
                    : "This Index Play a Pivotal Role In Driving Employee Experience"}{" "}
                </h1>
              </div>
            </div>
          </div>

          <main className="w-full ">
            <div className=" space-y-8  ">
              {/* 1 */}
              <div className=" w-full">

                <h1 className="text-[#212121] text-lg font-medium capitalize">
                  {SummaryName + " Score"}
                </h1>
                <div className=" grid grid-cols-2 gap-4">

                  <div className="flex justify-center items-center">
                    {indexValue > 0 ?
                      <GaugeScore
                        score={indexValue.toString()}
                        benchmark={40}
                      />
                      : null}

                  </div>

                  {/* <ScoreCircleChart indexValue={indexValue.toString()} /> */}
                  <div className="space-y-4">

                    <h1 class="text-[#212121] text-xl font-medium capitalize">
                      Response Rate
                    </h1>
                    <div className="flex flex-col  ">
                      <div className="w-full flex justify-start">
                        <BarChart ParticipationObj={ParticipationObj} />
                      </div>
                    </div>
                    <div className="space-y-4 w-full">
                      <p className="text-[#212121] font-medium text-sm">
                        <b>{ParticipationObj["total"]}</b> Sent of  <b> {ParticipationObj["completed"]}</b> Response have completed the survey</p>
                    </div>

                    <table className="w-full bg-[#f5f5f5]">
                      <thead>
                        <tr className="border-b-2 border-white  divide-x-2 divide-white whitespace-nowrap text-sm">
                          <th className="w-[30%] p-2 px-2 text-[#212121] font-semibold text-right">
                            Comparison
                          </th>
                          <th className="w-[40%] p-2 px-2 text-[#212121] font-semibold text-left">
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y-2  divide-white">
                        <tr className=" divide-x-2 divide-white text-sm">
                          <td className="p-2 text-[#212121] font-medium text-right ">Score</td>
                          <td className="p-2 text-[#212121] font-medium ">
                            <div className="h-6 w-full bg-[#CFD8DC]"><div className="w-full flex items-center  h-6 bg-[#1E88E5]">
                              <span className="text-xs font-medium text-white px-2"> 
                                {indexValue.toString() + (isScoreFormat ? "" : "%")}{" "}</span></div></div>
                          </td>
                          {/* <td rowSpan={2} className="  px-2 text-sm font-medium text-center text-[#4CAF50]">+12</td> */}
                        </tr>
                        <tr className=" divide-x-2 divide-white text-sm">
                          <td className="p-2 text-[#212121] font-medium text-right">
                            Overall
                          </td>
                          <td className="p-2 text-[#212121] font-medium">  <div className="h-6 w-full bg-[#CFD8DC]">
                            <div className="w-full flex items-center  h-6 bg-[#9C27B0]">
                              <span className="text-xs font-medium text-white px-2">
                                {indexOverallValue.toString() + (isScoreFormat ? "" : "%")}{" "}</span></div></div></td>
                        </tr>

                        <tr className=" divide-x-2 divide-white text-sm">
                          <td className="p-2 text-[#212121] font-medium text-right">
                          HW Benchmark
                          </td>
                          <td className="p-2 text-[#212121] font-medium">  <div className="h-6 w-full bg-[#CFD8DC]">
                            <div className="w-full flex items-center  h-6 bg-[#009688]">
                              <span className="text-xs font-medium text-white px-2">
                                {getSelectiveScores(getAllBenchMarkStaticData, SummaryName, "index")["HWScore"] + (isScoreFormat ? "" : "%")}{" "}</span></div></div></td>
                        </tr>

                        <tr className=" divide-x-2 divide-white text-sm">
                          <td className="p-2 text-[#212121] font-medium text-right">
                          Overall Average
                          </td>
                          <td className="p-2 text-[#212121] font-medium">  <div className="h-6 w-full bg-[#CFD8DC]">
                            <div className="w-full flex items-center  h-6 bg-[#c51162]">
                              <span className="text-xs font-medium text-white px-2">
                                {getSelectiveScores(getAllBenchMarkStaticData, SummaryName, "index")["OverallScore"] + (isScoreFormat ? "" : "%")}{" "}</span></div></div></td>
                        </tr>


                      </tbody>
                    </table>
                  </div>

                </div>

              </div>

              {/* {setPDF ? (
                <>
                  <table className="w-full">
                    <tbody>
                      <tr className="text-left text-[#212121] capitalize border-white border-b bg-[#DBEEFD] text-sm border-l border-t">
                        <th className="w-[15%] font-medium p-2  text-left border-r-2 border-white">
                          Dimension
                        </th>
                        <th className="w-[30%] font-medium p-2  text-left border-r-2 border-white">
                          Question
                        </th>
                        <th className="w-[15%] font-medium p-2  text-left border-r-2 border-white">
                          Comparison
                        </th>
                        <th className="w-[40%] font-medium p-2  text-left border-r-2 border-white">
                          Score
                        </th>
                        <th className="w-[15%] font-medium p-2  text-left border-r-2 border-white">
                          Difference
                        </th>
                      </tr>

                      {NewQuesList && NewQuesList.length > 0
                        ? NewQuesList.map((question, index) => (
                          <>
                            {index % 8 === 0 && index !== 0 ? (
                              <>
                                <tr className="border-b text-[#3D405B] text-sm border-l border-white">
                                  <th
                                    className="font-normal p-2 text-sm text-left text-[#3D405B]/70 "
                                    colSpan={5}
                                  >
                                    Continue...
                                  </th>
                                </tr>

                                <div className="page-break" />

                                <tr className="border-b border-l border-white text-[#3D405B] text-sm ">
                                  <td colSpan={5} className="text-left ">
                                    <div className="pt-4 -mx-8">
                                      <Header surveyLogo={surveyLogo} />

                                      <div className>
                                        <div className="capitalize w-full px-8 py-6 ">
                                          <div className="text-indigo-700 opacity-90">
                                          </div>
                                          <h1 className="text-xl text-[#212121] font-medium pb-2">
                                            {SummaryName + " Score"}{" "}
                                          </h1>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr className="text-left text-[#212121] capitalize border-white border-b bg-[#DBEEFD] text-sm border-l border-t">
                                  <th className="w-[20%] font-medium p-2 text-left border-r border-white">
                                    Dimension
                                  </th>
                                  <th className="w-[20%] font-medium p-2 text-left border-r border-white">
                                    Question
                                  </th>
                                  <th className="w-[20%] font-medium p-2 text-left border-r border-white">
                                    favorability score
                                  </th>
                                  <th className="w-[20%] font-medium p-2 text-left border-r border-white">
                                    overall
                                  </th>
                                </tr>
                              </>
                            ) : null}

                            <tr className="border-b-2 bg-[#f5f5f5] text-[#212121] text-sm border-l border-r border-white ">
                              <td rowSpan={2} className="  text-sm  p-2 border-r-2 border-white ">
                                {question && question.parameterDimensionName
                                  ? GetParameterLabel(
                                    question.parameterDimensionName
                                  )
                                  : ""}
                              </td>
                              <td rowSpan={2} className="  text-sm  p-2 border-r-2 border-white ">
                                {question.name}
                              </td>
                              <td className="p-2 text-sm text-right border-r-2 border-white bg-[#e9e9e9]">
                                Score
                              </td>
                              <td className="  text-sm  p-2 border-r-2 border-white bg-[#e9e9e9] ">
                                <div className="h-6 w-full bg-[#CFD8DC]">
                                  <div
                                    className="w-full flex items-center h-6 bg-blue-400"
                                    style={{
                                      background: "#1E88E5",
                                      width:
                                        (question &&
                                          question.score &&
                                          question.score !== "NA"
                                          ? question.score
                                          : 0) + "%",
                                    }}
                                  >
                                    <span className="text-xs font-medium text-white px-2">
                                      {question && question.score
                                        ? question.score
                                        : 0}
                                      %
                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td rowSpan={2} className="  px-2 text-sm font-medium text-center text-[#4CAF50]  ">
                                +{calcOverall(question)}
                              </td>
                            </tr>
                            <tr className="border-b-2 bg-[#f5f5f5] text-[#212121] text-sm border-l border-r border-white ">
                              <td className="p-2 text-sm text-right border-r-2 border-white  ">
                                Overall
                              </td>
                              <td className="p-2 text-sm border-r-2 border-white">
                                <div className="h-6 w-full bg-[#CFD8DC]">
                                  <div
                                    className="w-full flex items-center  h-6 bg-[#9C27B0]"
                                    style={{
                                      background: "#9C27B0",
                                      width:
                                        (question &&
                                          question.score &&
                                          question.score !== "NA"
                                          ? question.score
                                          : 0) + "%",
                                    }}
                                  >
                                    <span className="text-xs font-medium text-white px-2">
                                      {question && question.score
                                        ? question.score
                                        : 0}
                                      %
                                    </span>
                                  </div>
                                </div>

                              </td>
                            </tr>
                          </>
                        ))
                        : null}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <table id={"Behavior2" + indexTemplate}>
                    <thead>
                      <tr>
                        <th
                          data-pptx-min-width="2.6"
                          style={{
                            width: "15%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Dimension
                        </th>
                        <th
                          data-pptx-min-width="3.6"
                          style={{
                            width: "50%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Question
                        </th>
                        <th
                          data-pptx-min-width="0.8"
                          style={{
                            width: "10%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Favorability Score
                        </th>
                        <th
                          data-pptx-min-width="0.8"
                          style={{
                            width: "10%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Overall
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {NewQuesList && NewQuesList.length > 0
                        ? NewQuesList.map((question, index) => (
                          <tr>
                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "15%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {question && question.parameterDimensionName
                                ? GetParameterLabel(
                                  question.parameterDimensionName
                                )
                                : ""}
                            </td>
                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "50%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {" "}
                              {question.name}
                            </td>

                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "10%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {(question && question.score
                                ? question.score
                                : 0) + "%"}
                            </td>

                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "10%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {calcOverall(question)}%
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </>
              )} */}
            </div>
          </main>
        </div>
      </>
    );
  }
}
export default ManagerOverall;
