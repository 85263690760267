import React from 'react';
import GaugeScore from './Charts/GaugeScore';


export default function ScoreBenchmark(props) {

  let {

    item,

    DistributionData,
    Benchmark,
    cronBachAlpha,
    indexOverallValue,
    isCrole,
    name


  } = props;


  //  let Benchmark ={
  //    score:0
  //  }


  //console.log('Benchmark: ',Benchmark)

  return (
    <div className='flex md:flex-row flex-col items-center xl:gap-10 gap-0'>
      <div className='w-full'>
        <div className="flex justify-center ">
          <GaugeScore item={item} Benchmark={Benchmark} />
        </div>
        {isCrole ?
          <div className="flex justify-center pb-2">
            <div className="text-sm items-center text-center mx-2 mt-12"><p className="text-gray-500 font-semibold">
              {indexOverallValue && indexOverallValue.toString() ? "Organization " + name + " Score: " + indexOverallValue.toString() + "%" : "NaN"}</p></div>
          </div>
          : null}
      </div>
      <div className='w-full space-y-6'>
        <div className='space-y-2 '>
          <p className='text-[#212121]/70 font-medium'>HWB Benchmark : Manger index</p>
          <div className="h-8 w-full bg-[#F1F5F9]">
            <div
              className="w-[60%] flex items-center h-8 bg-[#47a6f3]"
            >
              <span className="px-2 text-xs font-medium text-white"> 66%</span>
            </div>
          </div>
        </div>
        <div className='space-y-2 '>
          <p className='text-[#212121]/70 font-medium'>Overall Average : Manger index</p>
          <div className="h-8 w-full bg-[#F1F5F9]">
            <div
              className="w-[60%] flex items-center h-8 bg-[#5d6cbe]"

            >
              <span className="px-2 text-xs font-medium text-white"> 66%</span>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
}
