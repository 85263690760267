import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import OverallDistLabels from '../Components/OverallDistLabels';

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false })
    }
    else {
      this.setState({ showChartList: true })
    }
  }

  handleChartType = (item) => {
    this.setState({ chartTypeName: item })
  }

  render() {
    let { getSelectiveScores, getAllBenchMarkStaticData,
      getOverallDriver, getEngagementIndexDriverData, GetParameterLabel } = this.props;


    let getIndexDriverList = getEngagementIndexDriverData ? Object.keys(getEngagementIndexDriverData) : []

    let nameList = [];
    let scoreList = [];
    let Overall = []

    let valueCount = 0



    let finalList = []
    if (getIndexDriverList && getIndexDriverList.length > 0) {
      getIndexDriverList.forEach((item) => {
        if (item && item !== "count" && item !== "name" && (parseInt(item) !== 1 && parseInt(item) !== 2 && parseInt(item) !== 3 && parseInt(item) !== 4 && parseInt(item) !== 5 && parseInt(item) !== 6 && parseInt(item) !== 7 && parseInt(item) !== 8)) {
          valueCount++
          nameList.push({
            value: GetParameterLabel(item.replace("Count", "")),
            textStyle: { fontWeight: 'bold' }
          })
          scoreList.push({
            value: getEngagementIndexDriverData && getEngagementIndexDriverData[item] && getEngagementIndexDriverData[item].toFixed(2) ? getEngagementIndexDriverData[item].toFixed(2) : 0,
            itemStyle: { color: '#0099ff' }
          })

          Overall.push({
            "name": GetParameterLabel(item.replace("Count", "")),
            "score": getOverallDriver && getOverallDriver[item] && getOverallDriver[item].toFixed(2) ? getOverallDriver[item].toFixed(2) : 0
          })



          finalList.push({
            "name": GetParameterLabel(item.replace("Count", "")),
            "score": getEngagementIndexDriverData && getEngagementIndexDriverData[item] && getEngagementIndexDriverData[item].toFixed(2) ? getEngagementIndexDriverData[item].toFixed(2) : 0,
            "overall": getOverallDriver && getOverallDriver[item] && getOverallDriver[item].toFixed(2) ? getOverallDriver[item].toFixed(2) : 0,
            "colorScore": '#1E88E5',
            "colorOverall": '#673AB7'
          })

        }

      });
    }



    return (
      <>

        <table className="w-full bg-[#F5F5F5]">
          <thead>
            <tr className="text-left text-[#212121] capitalize  border-b-2 border-white  text-sm  ">
              <th className="w-[25%] font-semibold p-2  text-left border-r-2 border-white">
                Dimension
              </th>
              <th className="w-[25%] font-semibold p-2 text-right border-r-2 border-white">
                Comparison
              </th>
              <th className="w-[50%] font-semibold p-2 text-left border-r-2 border-white  ">
                Score
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2 divide-white ">
            {finalList && finalList.length > 0 ?
              finalList.map((ele) =>
              (ele && ele.score?true:false) && 
                <>
                  <tr className="border-b bg-[#F5F5F5] text-[#212121] text-sm border-l border-r border-white ">
                    <td rowSpan={2 +
                      (getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["HWScore"] > 0 ? 1 : 0) +
                      (getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["OverallScore"] > 0 ? 1 : 0)
                    } className="p-2 font-medium capitalize border-r-2 border-white ">
                      {ele && ele.name}
                    </td>
                    <td className="p-2 text-sm text-right border-r-2 border-white bg-[#E9E9E9]">
                      Score
                    </td>
                    <td className="p-2 text-sm text-right border-r-2 border-white bg-[#E9E9E9]">
                      <div className="h-6 w-full bg-[#CFD8DC]">
                        <div
                          className="w-full flex items-center h-6 bg-[#1E88E5]"
                          style={{ background: ele.colorScore, width: ele && ele.score ? (parseFloat(ele.score)).toString() + "%" : "0%" }}
                        >
                          <span className="text-xs font-medium text-white px-2">
                            {ele && ele.score ? ele.score + "%" : "NA"}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 text-sm text-right border-r-2 border-white  ">
                      Overall
                    </td>
                    <td className="p-2 text-sm border-r-2 border-white">
                      <div className="h-6 w-full bg-[#CFD8DC]">
                        <div
                          className="w-full flex items-center  h-6 bg-[#673AB7]"
                          style={{ background: ele.colorOverall, width: ele && ele.overall ? (parseFloat(ele.overall)).toString() + "%" : "0%" }}
                        >
                          <span className="text-xs font-medium text-white px-2">
                            {ele && ele.overall ? ele.overall + "%" : "NA"}
                          </span>
                        </div>
                      </div>

                    </td>
                  </tr>
                  {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["HWScore"] > 0 ?
                    <tr>
                      <td className="p-2 text-sm text-right border-r-2 border-white  ">
                        HW Benchmark
                      </td>
                      <td className="p-2 text-sm border-r-2 border-white">
                        <div className="h-6 w-full bg-[#CFD8DC]">
                          <div
                            className="w-full flex items-center  h-6 bg-[#009688]"
                            style={{
                              width: getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["HWScore"] === "-" ? "0%" :
                                getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["HWScore"]
                                + "%"
                            }}
                          >
                            <span className="text-xs font-medium text-white px-2">
                              {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["HWScore"] === "-" ? "0%" :
                                getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["HWScore"]
                                + "%"}
                            </span>
                          </div>
                        </div>

                      </td>
                    </tr>
                    : null}

                  {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["OverallScore"] > 0 ?
                    <tr>
                      <td className="p-2 text-sm text-right border-r-2 border-white  ">
                        Overall Average
                      </td>
                      <td className="p-2 text-sm border-r-2 border-white">
                        <div className="h-6 w-full bg-[#CFD8DC]">
                          <div
                            className="w-full flex items-center  h-6 bg-[#c51162]"
                            style={{
                              width: getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["OverallScore"] === "-" ? "0%" :
                                getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["OverallScore"]
                                + "%"
                            }}
                          >
                            <span className="text-xs font-medium text-white px-2">
                              {getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["OverallScore"] === "-" ? "0%" :
                                getSelectiveScores(getAllBenchMarkStaticData, ele.name, "dimension")["OverallScore"]
                                + "%"}
                            </span>
                          </div>
                        </div>

                      </td>
                    </tr>
                    : null}

                </>
              ) : null}


          </tbody>

        </table>




        {/* <OverallDistLabels Overall={Overall} />  */}

        {/* <div className="stack-body !w-full"  >
          {true ?
            <>
              <ReactEcharts
                style={{
                  height: '160px',
                }}

                option={{
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow'
                    },
                    formatter: function (a, b, c, d) {
                      return (a && a[0] && a[0].name) ? a[0].name + ' = ' + a[0].value + '%' : ''
                    }


                  },
                  legend: {
                    data: []
                  },
                  grid: {
                    left: '2%',
                    right: '7%',
                    bottom: '0%',
                    top: '0%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'value',
                    splitLine: {
                      show: false,
                      lineStyle: {
                        color: '#f2f2f2'
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#0099ff'
                      }
                    },
                    max: 100
                  },
                  yAxis: {
                    type: 'category',
                    data: nameList.reverse(),
                    axisLine: {
                      lineStyle: {
                        color: '#e6e6e6'
                      }
                    },
                  },
                  axisLabel: {
                    color: "#000",
                    interval: 0,
                    formatter: function (value) {
                      if (value.length > 15) {
                        // return value.substring(0, 15) + "..";
                        return value
                      } else {
                        return value;
                      }
                    }
                  },
                  series: [
                    {
                      name: 'Score',
                      type: 'bar',
                      barWidth: '20px',
                      stack: '总量',
                      label: {
                        show: true,
                        position: 'insideRight'
                      },
                      data: scoreList.reverse()

                    },

                  ]
                }}
              />
            </>
            : null}
        </div> */}


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
