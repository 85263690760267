import React, { Component } from "react";
// import EmpChart from './Chart/EmpChart';
// import BarDistribution from './Chart/BarDistribution';
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      setPDF,
      getQuestionFavorabilityData,
      getRespondantDetailsData,
      GetParameterLabel,
      surveyLogo,
      surveyNAME,
      createdAt
    } = this.props;

    let totalCount = 0;
    if (getRespondantDetailsData && getRespondantDetailsData.length > 0) {
      if (
        getRespondantDetailsData &&
        getRespondantDetailsData[0] &&
        getRespondantDetailsData[0].completed
      ) {
        totalCount = getRespondantDetailsData[0].completed;
      }
    }

    ////////console.log("getQuestionFavorabilityData--->",getQuestionFavorabilityData)
    // let isAllow = getOrgIndexV2Data? (CountRestrict(getOrgIndexV2Data && getOrgIndexV2Data["count"]?getOrgIndexV2Data["count"]:0)?false:true ): false;

    function Score(question) {
      let count1 = question && question.count ? question.count : 0;
      let total1 = question && question.total ? question.total : 0;
      let percentageValue = (count1 * 100) / total1;
      return percentageValue !== "NA"
        ? percentageValue
          ? percentageValue.toFixed(2)
          : 0
        : "NA";
    }

    return (
      <div className="space-y-6 px-8 py-6 page-break">
        {/* report header */}
        <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
          <p class="font-medium italic text-base ">{surveyNAME}</p>
          <p class="font-medium italic text-base">{createdAt}</p>
        </div>

        <div className=" ">
          <Header surveyLogo={surveyLogo} />



          <div className>
            <div className="capitalize w-full   py-6 ">
              <div className="text-indigo-700 opacity-90"></div>
              <h1 className="text-2xl text-[#212121] font-medium  capitalize">
                Emerging Areas of Weakness/Focus for Lowest Scoring Experiences{" "}

              </h1>
            </div>
          </div>
        </div>

        {true ? (
          <>
            {setPDF ? (
              <>
                <table className="w-full border" id="Weakness">
                  <tbody>
                    <tr className="text-left capitalize border-b-2 border-white bg-[#FCD2CF] text-sm border-l border-t">
                      <th className="w-[15%] font-medium p-2 text-left border-r-2 border-white text-[#212121] ">
                        Dimension
                      </th>
                      <th className="w-[50%] font-medium p-2 text-left border-r-2 border-white text-[#212121] ">
                        Question
                      </th>
                      <th className="w-[35%] font-medium p-2 text-left text-[#212121]   ">
                        Bottom 10
                      </th>
                    </tr>

                    {getQuestionFavorabilityData &&
                      getQuestionFavorabilityData.length > 0
                      ? getQuestionFavorabilityData.map((question, index) => (

                        <tr className="border-b-2 border-white  text-sm border-l even:bg-[#f5f5f5] odd:bg-[#e9e9e9]">
                          <td className="p-2 capitalize border-r-2 border-white text-[#212121] ">
                            {question &&
                              question._id &&
                              question._id.dimension
                              ? GetParameterLabel(question._id.dimension)
                              : "Any"}
                          </td>
                          <td className="p-2 capitalize border-r-2 border-white text-[#212121] ">
                            {question &&
                              question._id &&
                              question._id.question
                              ? question._id.question
                              : "NA"}
                          </td>
                          <td className="p-2 text-left ">
                            <div className="h-6 w-full bg-[#CFD8DC]">
                              <div
                                className="h-6 w-full bg-[#F44336] flex items-center justify-start "
                                style={{
                                  background: "#F44336",
                                  width:
                                    (Score(question) &&
                                      Score(question) !== "NA"
                                      ? Score(question)
                                      : 0) + "%",
                                }}
                              >
                                <span className="px-2 text-white"> {Score(question)}%</span>
                              </div>
                            </div>

                          </td>
                        </tr>

                      ))
                      : null}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <table id="Weakness">
                  <thead>
                    <tr>
                      <th
                        data-pptx-min-width="2.6"
                        style={{
                          width: "15%",
                          fontSize: 10,
                          background: "#e6e6e6",
                        }}
                      >
                        Dimension
                      </th>

                      <th
                        data-pptx-min-width="3.6"
                        style={{
                          width: "50%",
                          fontSize: 10,
                          background: "#e6e6e6",
                        }}
                      >
                        Question
                      </th>
                      <th
                        data-pptx-min-width="0.8"
                        style={{
                          width: "10%",
                          fontSize: 10,
                          background: "#e6e6e6",
                        }}
                      >
                        Bottom 10
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getQuestionFavorabilityData &&
                      getQuestionFavorabilityData.length > 0
                      ? getQuestionFavorabilityData.map((question, index) => (
                        <tr>
                          <td
                            data-pptx-min-width="2.6"
                            style={{
                              width: "15%",
                              fontSize: 10,
                              background:
                                index % 2 === 0 ? "#dbefff" : "#ffffff",
                            }}
                          >
                            {question &&
                              question._id &&
                              question._id.dimension
                              ? GetParameterLabel(question._id.dimension)
                              : "Any"}
                          </td>
                          <td
                            data-pptx-min-width="3.6"
                            style={{
                              width: "50%",
                              fontSize: 10,
                              background:
                                index % 2 === 0 ? "#dbefff" : "#ffffff",
                            }}
                          >
                            {" "}
                            {question &&
                              question._id &&
                              question._id.question
                              ? question._id.question
                              : "NA"}
                          </td>

                          <td
                            data-pptx-min-width="0.8"
                            style={{
                              textAlign: "center",
                              width: "10%",
                              fontSize: 10,
                              background:
                                index % 2 === 0 ? "#dbefff" : "#ffffff",
                            }}
                          >
                            {Score(question) + "%"}
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </>
            )}
          </>
        ) : (
          <div className="text-3xl text-gray-400 p-4 text-center">
            Data not available!
          </div>
        )}

      </div>
    );
  }
}
export default Employee;
