import React, { Component } from "react";
import { connect } from "react-redux";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import moment from "moment";

import Summary from "./SubComponent/Summary/Summary";
import SummaryTwo from "./SubComponent/Summary/SummaryTwo";


// import RWAIndex from './SubComponent/RWAIndex/RWAIndex';
import Strength from "./SubComponent/Strength/Strength";
import Weakness from "./SubComponent/Weakness/Weakness";



import ManagerOverall from "./SubComponent/ManagerOverall/ManagerOverall";

import Behaviors from "./SubComponent/Behaviors/Behaviors";

import OrgCoreDriver from "./SubComponent/OrgCoreDrivers/OrgCoreDrivers";


import EngagementDMG from "../../../Report/Components/OverallReport/SubComponent/EngagementDMG/EngagementDMG";
import OrgDMG from "../../../Report/Components/OverallReport/SubComponent/OrgDMG/OrgDMG";
import OrgCoreItems from "../../../Report/Components/OverallReport/SubComponent/OrgCoreItems/OrgCoreItems";




import Header from "./SubComponent/Components/Header";

import PageTemplate from "./PageTemplate";

import ReportIntro from "./SubComponent/ReportIntro/ReportIntro";

import EngagementComparison from "./SubComponent/EngagementComparison/EngagementComparison";
// import QuestionComments from './SubComponent/QuestionComments/QuestionComments';
import FilterSummary from "./SubComponent/FilterSummary/FilterSummary";

import Filter from "./Filter";

import NPSChart from "./SubComponent/NPS/NPSChart";
import NPSDmg from "./SubComponent/NPS/NPSDmg";

// let ref = React.useRef(null);
// let pdfExportComponent = ref;

class OverallReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();

    this.state = {
      optionType: 1,

      ReportFilterObj: {
        level: "Overall",
        value: "",
      },

      showExport: false,

      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      isSelectTypeOverall: false,
      errorMsg: "",
      setPDF: true,
    };
  }
  componentDidMount() { }

  handleExportWithComponent = (event) => {
    this.setState({ setPDF: true }, () => {
      this.pdfExportComponent.current.save();
    });
  };

  render() {
    let { setPDF } = this.state;
    let {
      CurrentSurveyId,
      showLoadedReport,
      getQuestionFilterData,
      getDemographicOrgReportData,
      getDemographicIndexMultiFilterData,
      loadReport,
      getQuestionFavorability2Data,
      getRespondantDetailsData,
      GetParameterLabel,
      CountRestrict,
      getOrgIndexV2Data,
      getSurveyData,
      getEngagementRWAData,
      getQuestionFavorabilityData,
      getIndexData,
      getAllBenchMarkData,
      loadIndexReport,
      showExport,
      handleExportPPT,

      getQuestionOthersMultiResultFilterData,

      getHappinessQuestion,
      getEngagementQuestion,
      getHopeQuestion,
      getStressQuestion,
      getManagerQuestion,
      getLeaderQuestion,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getHopeIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,
      getLeaderIndexV2Data,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getHopeIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,

      DMGLevelsList,
      DMGValuesList,
      ReportFilterObj,

      isFilter,

      surveyLogo,

      getDemographicIndexMultiFilterEngCompData,

      getEMPFilterLabel,

      // currentSelectValue,
      FilterValues,
      showFilter,
      showValue,
      closeFilter,
      handleMultifilterIndexScore2,

      isSelectTypeOverall,
      // handleSelectTypeOverall,

      errorMsg,

      getIndexByFilterDMGData,

      getOverallIndexData,

      //Overall
      getHappinessQuestionOverall,
      getEngagementQuestionOverall,
      getStressQuestionOverall,
      getManagerQuestionOverall,
      getLeaderQuestionOverall,
      getOrgQuestionOverall,

      getOrgDriverOverallData,

      getHappinessDistributionOverall,
      getEngagementDistributionOverall,
      getStressDistributionOverall,

      getHappinessDriverOverall,
      getEngagementDriverOverall,
      getStressDriverOverall,

      getDemographicIndexEngCompOverallData,

      getQuestionOthersResultData,

      getQuestion2OthersResultFilterData,

      getAllBenchMarkStaticData,


    } = this.props;

    let allowAPI = true;

    function getIndexNew(numberIndex) {
      let check = false;
      let IndexValidList = getOverallIndexData
        ? Object.keys(getOverallIndexData)
        : [];
      let getIndex = IndexValidList.findIndex(
        (prev) => prev.toString() === numberIndex.toString()
      );
      //////console.log("IndexValidList--->", IndexValidList)
      //////console.log("numberIndex--->", numberIndex)

      if (getIndex !== -1) {
        check = true;
      }
      return check;
    }

    let IndexNewValueList = [];
    if (getIndexNew(2)) {
      IndexNewValueList.push("2");
    }
    if (getIndexNew(1)) {
      IndexNewValueList.push("1");
    }
    if (getIndexNew(4)) {
      IndexNewValueList.push("4");
    }
    if (getIndexNew(5)) {
      IndexNewValueList.push("5");
    }
    if (getIndexNew(6)) {
      IndexNewValueList.push("6");
    }
    //////console.log("IndexNewValueList--->", IndexNewValueList)

    let IndexNumber = 0;
    let SummaryName = "";
    let getIndexV2Data = "";
    let getIndexDriverData = "";
    let getIndexQuestionData = "";
    let getIndexOverallQuesData = "";
    let getOverallDistribution = "";
    let getOverallDriver = "";

    let OutPutData1234 = [];
    let OutPutData56 = [];

    if (IndexNewValueList && IndexNewValueList.length > 0) {
      IndexNewValueList.forEach((item) => {
        ////////console.log("--------before IndexNumber-->",item)
        if (item && getOverallIndexData && getOverallIndexData[item]) {
          ////////console.log("--------after IndexNumber-->",item)

          IndexNumber = parseInt(item);

          if (parseInt(item) !== 5 && parseInt(item) !== 6) {
            if (parseInt(item) === 1) {
              SummaryName = "Happiness";
              getIndexV2Data = getHappinessIndexV2Data;
              getIndexDriverData = getHappinessIndexDriverData;
              getIndexQuestionData = getHappinessQuestion;
              getIndexOverallQuesData = getHappinessQuestionOverall;
              getOverallDistribution = getHappinessDistributionOverall;
              getOverallDriver = getHappinessDriverOverall;
            } else if (parseInt(item) === 2) {
              SummaryName = "Engagement";
              getIndexV2Data = getEngagementIndexV2Data;
              getIndexDriverData = getEngagementIndexDriverData;
              getIndexQuestionData = getEngagementQuestion;
              getIndexOverallQuesData = getEngagementQuestionOverall;
              getOverallDistribution = getEngagementDistributionOverall;
              getOverallDriver = getEngagementDriverOverall;
            } else if (parseInt(item) === 3) {
              SummaryName = "Hope";
              getIndexV2Data = getHopeIndexV2Data;
              getIndexDriverData = getHopeIndexDriverData;
              getIndexQuestionData = getHopeQuestion;
              getIndexOverallQuesData = [];
              getOverallDistribution = [];
              getOverallDriver = [];
            } else if (parseInt(item) === 4) {
              SummaryName = "Stress";
              getIndexV2Data = getStressIndexV2Data;
              getIndexDriverData = getStressIndexDriverData;
              getIndexQuestionData = getStressQuestion;
              getIndexOverallQuesData = getStressQuestionOverall;
              getOverallDistribution = getStressDistributionOverall;
              getOverallDriver = getStressDriverOverall;
            }

            if (
              parseInt(item) === 1 ||
              parseInt(item) === 2 ||
              parseInt(item) === 3 ||
              parseInt(item) === 4
            ) {
              OutPutData1234.push({
                IndexNumber: IndexNumber,
                SummaryName: SummaryName,
                getIndexV2Data: getIndexV2Data,
                getIndexDriverData: getIndexDriverData,
                getIndexQuestionData: getIndexQuestionData,
                getIndexOverallQuesData: getIndexOverallQuesData,
                getOverallDistribution: getOverallDistribution,
                getOverallDriver: getOverallDriver,
              });
            }
          } else if (parseInt(item) === 5) {
            SummaryName = "Manager";
            getIndexV2Data = getManagerIndexV2Data;
            getIndexDriverData = getManagerIndexDriverData;
            getIndexQuestionData = getManagerQuestion;
            getIndexOverallQuesData = getManagerQuestionOverall;

            OutPutData56.push({
              IndexNumber: IndexNumber,
              SummaryName: SummaryName,
              getIndexV2Data: getIndexV2Data,
              getIndexDriverData: getIndexDriverData,
              getIndexQuestionData: getIndexQuestionData,
              getIndexOverallQuesData: getIndexOverallQuesData,
            });
          } else if (parseInt(item) === 6) {
            SummaryName = "Leader";
            getIndexV2Data = getLeaderIndexV2Data;
            getIndexDriverData = getLeaderIndexDriverData;
            getIndexQuestionData = getLeaderQuestion;
            getIndexOverallQuesData = getLeaderQuestionOverall;

            OutPutData56.push({
              IndexNumber: IndexNumber,
              SummaryName: SummaryName,
              getIndexV2Data: getIndexV2Data,
              getIndexDriverData: getIndexDriverData,
              getIndexQuestionData: getIndexQuestionData,
              getIndexOverallQuesData: getIndexOverallQuesData,
            });
          }
        }
      });
    }
    ////////console.log("IndexValidList",IndexValidList)

    // let temp = [DMGLevelsList, DMGValuesList]

    ////////console.log("ReportFilterObj---->",ReportFilterObj)

    // let level = ReportFilterObj && ReportFilterObj["level"] ? ReportFilterObj["level"] : "";
    // let DMGValuesObjList = DMGValuesList && DMGValuesList[level] ? DMGValuesList[level] : []

    let QueTemplate = 6;

    function FormattFun(inputData, isSummary) {
      let LIST = [];
      let prevTemList = [];
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {
          let templateId = ques.total[0]._id.qtemplate;
          if (QueTemplate === templateId) {
            let temp = {
              name:
                ques && ques.question[0] && ques.question[0].name
                  ? ques.question[0].name
                  : "",
              answerList: [],
              templateId: templateId,
            };
            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                let ans = {
                  count: item.count,
                  answerText: item._id.answer,
                  heading:
                    item && item._id && item._id.heading
                      ? item._id.heading
                      : "",
                  parentId:
                    item && item._id && item._id.parentId
                      ? item._id.parentId
                      : "",
                  template: item._id.qtemplate,
                  QuesId: item._id.questionId,
                };
                temp["answerList"].push(ans);
              });
            }

            if (isSummary) {
              let getIndex = prevTemList.findIndex(
                (prev) => prev === templateId
              );
              if (getIndex === -1) {
                LIST.push(temp);
                prevTemList.push(templateId);
              }
            } else {
              LIST.push(temp);
            }
          }
        });
      }
      return LIST;
    }

    let OthersQuesList = FormattFun(getQuestionOthersResultData, true);

    //console.log('---------- OthersQuesList-6---------: ', OthersQuesList);

    let item =
      OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList[0] : {};

    let AnswerObj = {};
    if (item && item.answerList && item.answerList.length > 0) {
      ////console.log('item.answerList (STAR)',item.answerList)

      item.answerList.forEach((ans) => {
        if (ans && ans.answerText) {
          if (AnswerObj && AnswerObj[ans.answerText]) {
            AnswerObj[ans.answerText] += ans.count;
          } else {
            AnswerObj[ans.answerText] = ans.count;
          }
        }
      });
    }

    //console.log("FilterValues----", FilterValues)

    let name = "NPS";




    function getSelectiveScores(list, item, type) {
      let scoreObj = {
        "HWScore": 0,
        "OverallScore": 0
      };
      if (list && list.length > 0) {

        if (type === "dimension") {
          let newList = list.filter(prev => prev.type === "dimension");
          let getIndex = newList.findIndex(prev => prev.name === item);
          if (getIndex !== -1) {
            scoreObj["HWScore"] = newList[getIndex]["HWScore"];
            scoreObj["OverallScore"] = newList[getIndex]["OverallScore"];
          }
        } else if (type === "index") {
          let newList = list.filter(prev => prev.type === "index");
          console.log("newList------x------>", newList)
          console.log("item------x------>", item)

          let getIndex = newList.findIndex(prev => prev.name === item);
          if (getIndex !== -1) {
            scoreObj["HWScore"] = Number(newList[getIndex]["HWScore"]);
            scoreObj["OverallScore"] = Number(newList[getIndex]["OverallScore"]);
          }
        } else if (type === "distribution") {
          let newList = list.filter(prev => prev.type === "distribution");
          console.log("newList------x------>", newList)
          console.log("item------x------>", item)

          let getIndex = newList.findIndex(prev => prev.name === item);
          if (getIndex !== -1) {
            scoreObj["HWScore"] = Number(newList[getIndex]["HWScore"]);
            scoreObj["OverallScore"] = Number(newList[getIndex]["OverallScore"]);
          }
        }

      }
      return {
        "HWScore": scoreObj["HWScore"] > 0 ? scoreObj["HWScore"] : "",
        "OverallScore": scoreObj["OverallScore"] > 0 ? scoreObj["OverallScore"] : ""
      }
    }



    let surveyNAME = getSurveyData && getSurveyData.name ? getSurveyData.name : "";
    let createdAt = getSurveyData && getSurveyData.createdAt
      ? moment
        .unix(getSurveyData.createdAt / 1000)
        .format("DD/MM/YYYY")
      : ""



    return (
      <>
        {!loadReport ? (
          <div className=" bg-[#f9fafb] ">
            <main
              className="flex-1 w-full overflow-hidden overflow-y-auto  bg-[#f9fafb]  "
              style={{ height: "calc(100vh - 8rem)" }}
            >
              <div className="w-full p-6">
                <div className=" p-6  -m-6  bg-gradient-to-l from-sky-500  to-[#2196f3] h-60 space-y-6 flex flex-col ">
                  <h1 className="text-2xl font-medium text-white lg:text-3xl ">
                    Reports
                  </h1>
                  <p className="text-white">
                    This analytical report uses qualitative and quantitative statistical methods to analyze company data and empower the managers and business leaders to make data-driven decisions based on evidence and analytics.
                  </p>
                </div>

                <div className="-mt-10 md:-mt-16">
                  <div className="grid grid-cols-1 gap-10 p-4 bg-white border rounded-md lg:grid-cols-2 md:p-8 ">
                    <div className="flex items-center w-full h-full p-6 border ">
                      <div className="w-full space-y-6">
                        <div className="space-y-3 ">
                          <div className="flex items-center space-x-4 ">
                            <img
                              alt="card"
                              src="/img/modules/assessment.svg"
                              class="w-12 h-12"
                            />
                            <div className="text-[#3D405B]">
                              <h1 className="text-xl font-medium md:text-2xl">
                                Report
                              </h1>
                              <p className="text-sm font-normal md:text-base ">
                                Select Filter Name and it's values
                              </p>
                            </div>
                          </div>

                          <div />
                        </div>

                        {!isSelectTypeOverall ? (
                          <div className="rounded-md">
                            <Filter
                              showFilter={showFilter}
                              showValue={showValue}
                              closeFilter={closeFilter}
                              EmpFilterData2={DMGLevelsList}
                              getIndexFilterData={getIndexByFilterDMGData}
                              handleMultifilterIndexScore={
                                handleMultifilterIndexScore2
                              }
                              getEMPFilterLabel={getEMPFilterLabel}
                            />
                          </div>
                        ) : (
                          <div className="" />
                        )}
                        <div className="space-y-4 ">
                          <p className="text-red-500 ">{errorMsg}</p>
                          <button
                            onClick={() => loadIndexReport(2, !loadReport)}
                            className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-base  border-[#2196F3] flex justify-center items-center px-4 py-2.5 rounded-md w-full "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-circle-plus"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                              <rect x="9" y="3" width="6" height="4" rx="2" />
                              <line x1="9" y1="12" x2="9.01" y2="12" />
                              <line x1="13" y1="12" x2="15" y2="12" />
                              <line x1="9" y1="16" x2="9.01" y2="16" />
                              <line x1="13" y1="16" x2="15" y2="16" />
                            </svg>
                            <span> VIEW REPORT</span>
                          </button>

                          {/* <button
                          onClick={() => loadIndexReport(2, !loadReport)}
                          className="cursor-pointer border  text-[#EF4444] space-x-1 text-base flex justify-center items-center px-4 py-2.5 rounded-md w-full "
                        >
                   
                          <span> Clear All</span>
                        </button> */}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end w-full">
                      <img src="/img/report.png" className="w-5/6 mx-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        ) : (
          <div className="fixed top-0 left-0 z-20 w-full h-screen p-6 bg-black bg-opacity-40 2xl:p-10">
            <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md App 2xl:w-3/6 xl:w-4/6 lg:w-5/6 customscroll5">
              {/* report header */}
              <div className="justify-between px-4 py-2 space-y-2 border-b border-gray-200 md:flex md:space-y-0">
                <div className="flex items-center space-x-4">
                  <span
                    onClick={() => loadIndexReport(0, !loadReport, true)}
                    className="p-2 text-blue-500 duration-150 rounded-full cursor-pointer material-symbols-outlined bg-blue-50 hover:bg-blue-100 trasition"
                    style={{ fontSize: "18px" }}
                  >
                    arrow_back
                  </span>

                  <h1 className="text-lg font-semibold md:text-xl line-clamp-1 text-slate-800">
                    {"Survey Report"}
                  </h1>
                </div>

                <div className="flex justify-between md:space-x-4">
                  {showLoadedReport ? (
                    <>
                      {/* <div className="px-4 py-1 text-white bg-purple-500 border border-gray-300 rounded-md cursor-pointer "
                          onClick={() => {
                            this.setState({ setPDF: false }, () => {
                              handleExportPPT();
                            });
                          }}>{"Export PPT"}</div> */}
                      <button
                        className="flex items-center p-2 px-4 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer "
                        onClick={() => {
                          this.setState({ setPDF: true }, () => {
                            this.handleExportWithComponent();
                          });
                        }}
                      >
                        <span class="material-symbols-outlined mr-1">
                          file_download
                        </span>
                        {"Export PDF"}
                      </button>
                    </>
                  ) : (
                    <div className="text-gray-500 fontbold">
                      Loading Report....
                    </div>
                  )}
                </div>
              </div>
              <div
                className="overflow-y-auto divide-y "
                style={{ height: "calc(100vh - 8rem)" }}
              >
                <PDFExport ref={this.pdfExportComponent}
                  pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} surveyLogo={surveyLogo} />}
                  scale={0.7}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                  margin={0}
                  author="Happyplus Team"
                  landscape={false}
                  fileName={`${ReportFilterObj && ReportFilterObj["value"]
                    ? (getSurveyData && getSurveyData.name
                      ? getSurveyData.name +
                      (getSurveyData && getSurveyData.createdAt
                        ? moment
                          .unix(getSurveyData.createdAt / 1000)
                          .format(" - YYYY")
                        : "")
                      : "Dashboard Report") + ReportFilterObj["value"]
                    : getSurveyData && getSurveyData.name
                      ? getSurveyData.name +
                      (getSurveyData && getSurveyData.createdAt
                        ? moment
                          .unix(getSurveyData.createdAt / 1000)
                          .format(" - YYYY")
                        : "")
                      : "Dashboard Report"
                    }`}

                  // className="space-y-4 divide-y"
                >
                  <ReportIntro
                    CurrentSurveyId={CurrentSurveyId}
                    surveyLogo={surveyLogo}
                    ReportFilterObj={ReportFilterObj}
                    FilterValues={FilterValues}
                    getEMPFilterLabel={getEMPFilterLabel}
                    surveyNAME={surveyNAME}
                    createdAt={createdAt}
                  />

                  <h3 className="page-break"></h3>

                  <FilterSummary

                    getEMPFilterLabel={getEMPFilterLabel}
                    FilterValues={FilterValues}
                    surveyNAME={surveyNAME}
                    createdAt={createdAt}

                  />
                  <h3 className="page-break"></h3>

                  {allowAPI ? (
                    <>
                      {OutPutData1234 && OutPutData1234.length > 0
                        ? OutPutData1234.map((item, index) => (
                          <>
                            {index !== 0 ? (
                              <h3 className="page-break"></h3>
                            ) : null}

                            <Summary
                              SummaryName={item["SummaryName"]}
                              getIndexV2Data={item["getIndexV2Data"]}
                              getIndexData={getIndexData}
                              getRespondantDetailsData={
                                getRespondantDetailsData
                              }
                              getAllBenchMarkData={getAllBenchMarkData}
                              getEngagementIndexDriverData={
                                item["getIndexDriverData"]
                              }
                              indexTemplate={item["IndexNumber"]}
                              isFilter={isFilter}
                              surveyLogo={surveyLogo}
                              GetParameterLabel={GetParameterLabel}
                              getOverallIndexData={getOverallIndexData}
                              getOverallDistribution={
                                item["getOverallDistribution"]
                              }
                              getOverallDriver={item["getOverallDriver"]}
                              getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                              getSelectiveScores={getSelectiveScores}
                              surveyNAME={surveyNAME}
                              createdAt={createdAt}
                            />


                            <SummaryTwo
                              SummaryName={item["SummaryName"]}
                              getIndexV2Data={item["getIndexV2Data"]}
                              getIndexData={getIndexData}
                              getRespondantDetailsData={
                                getRespondantDetailsData
                              }
                              getAllBenchMarkData={getAllBenchMarkData}
                              getEngagementIndexDriverData={
                                item["getIndexDriverData"]
                              }
                              indexTemplate={item["IndexNumber"]}
                              isFilter={isFilter}
                              surveyLogo={surveyLogo}
                              GetParameterLabel={GetParameterLabel}
                              getOverallIndexData={getOverallIndexData}
                              getOverallDistribution={
                                item["getOverallDistribution"]
                              }
                              getOverallDriver={item["getOverallDriver"]}
                              getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                              getSelectiveScores={getSelectiveScores}
                              surveyNAME={surveyNAME}
                              createdAt={createdAt}
                            />

                            {item["IndexNumber"] !== 4 ? (
                              <>
                                <Behaviors
                                  SummaryName={item["SummaryName"]}
                                  getQuestionData={
                                    item["getIndexQuestionData"]
                                  }
                                  GetParameterLabel={GetParameterLabel}
                                  CountRestrict={CountRestrict}
                                  surveyLogo={surveyLogo}
                                  indexTemplate={item["IndexNumber"]}
                                  setPDF={setPDF}
                                  getQuesOverallData={
                                    item["getIndexOverallQuesData"]
                                  }
                                  surveyNAME={surveyNAME}
                                  createdAt={createdAt}
                                />

                                <OrgCoreItems
                                  SummaryName={item["SummaryName"]}
                                  getIndexData={getIndexData}
                                  indexTemplate={item["IndexNumber"]}
                                  GetParameterLabel={GetParameterLabel}
                                  CountRestrict={CountRestrict}
                                  getQuestionFilterData={
                                    item["getIndexQuestionData"]
                                  }
                                  surveyLogo={surveyLogo}
                                  setPDF={setPDF}
                                  getQuesOverallData={
                                    item["getIndexOverallQuesData"]
                                  }
                                  surveyNAME={surveyNAME}
                                  createdAt={createdAt}
                                />
                              </>
                            ) : null}
                          </>
                        ))
                        : null}

                      {item &&
                        item.answerList &&
                        item.answerList[0] &&
                        item.answerList[0].template &&
                        item.answerList[0].template === 6 ? (
                        <>
                          <h3 className="page-break"></h3>

                          {/* <div className="pt-4">
                            <Header surveyLogo={surveyLogo} />

                            <div className>
                              <div className="w-full px-8 py-6 capitalize ">
                                <div className="text-indigo-700 opacity-90"></div>
                                <h1 className="pb-2 text-xl font-semibold text-gray-800">
                                  NPS Summary
                                </h1>
                              </div>
                            </div>
                          </div> */}


                          <div className="relative py-6 px-8">
                            <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
                              <p class="font-medium italic text-base ">{surveyNAME}</p>
                              <p class="font-medium italic text-base">{createdAt}</p>
                            </div>
                            <div className="">
                              <Header surveyLogo={surveyLogo} />
                              <div className>
                                <div className="capitalize w-full   py-6 ">
                                  <div className="text-indigo-700 opacity-90"></div>
                                  <h1 className="text-2xl text-[#212121] font-medium  capitalize">
                                    NPS Summary
                                  </h1>
                                </div>
                              </div>
                            </div>


                            {item &&
                              item.answerList &&
                              item.answerList[0] &&
                              item.answerList[0].template &&
                              item.answerList[0].template === 6 ? (
                              <NPSChart
                                AnswerObj={AnswerObj}
                                name={name}
                                templateId={6}
                                questionName={item.name}
                                stage={1}
                                index={1}
                                surveyNAME={surveyNAME}
                                createdAt={createdAt}
                              />
                            ) : null}
                          </div>


                          {/* 
                            <h3 className="page-break"></h3>

                            <NPSDmg
                              getDemographicIndexMultiFilterEngCompData={
                                getDemographicIndexMultiFilterEngCompData
                              }
                              surveyLogo={surveyLogo}
                              GetParameterLabel={GetParameterLabel}
                              getEMPFilterLabel={getEMPFilterLabel}
                              CountRestrict={CountRestrict}
                              getQuestionOthersMultiResultFilterData={
                                getQuestionOthersMultiResultFilterData
                              }
                              getQuestion2OthersResultFilterData={
                                getQuestion2OthersResultFilterData
                              }
                            /> */}



                        </>
                      ) : null}

                      <OrgCoreDriver
                        getOrgIndexV2Data={getOrgIndexV2Data}
                        getSurveyData={getSurveyData}
                        isFilter={isFilter}
                        surveyLogo={surveyLogo}
                        GetParameterLabel={GetParameterLabel}
                        CountRestrict={CountRestrict}
                        getOrgDriverOverallData={getOrgDriverOverallData}
                        surveyNAME={surveyNAME}
                        createdAt={createdAt}
                      />


                      <Strength
                        getOrgIndexV2Data={getOrgIndexV2Data}
                        getSurveyData={getSurveyData}
                        getEngagementRWAData={getEngagementRWAData}
                        getQuestionFavorabilityData={
                          getQuestionFavorabilityData
                        }
                        getRespondantDetailsData={getRespondantDetailsData}
                        GetParameterLabel={GetParameterLabel}
                        surveyLogo={surveyLogo}
                        CountRestrict={CountRestrict}
                        setPDF={setPDF}
                        surveyNAME={surveyNAME}
                        createdAt={createdAt}
                      />

                      <Weakness
                        getOrgIndexV2Data={getOrgIndexV2Data}
                        getSurveyData={getSurveyData}
                        getEngagementRWAData={getEngagementRWAData}
                        getQuestionFavorabilityData={
                          getQuestionFavorability2Data
                        }
                        getRespondantDetailsData={getRespondantDetailsData}
                        GetParameterLabel={GetParameterLabel}
                        surveyLogo={surveyLogo}
                        CountRestrict={CountRestrict}
                        setPDF={setPDF}
                        surveyNAME={surveyNAME}
                        createdAt={createdAt}
                      />

                      {!isFilter || true ? (
                        <>
                          <EngagementDMG
                            getDemographicIndexMultiFilterData={
                              getDemographicIndexMultiFilterData
                            }
                            surveyLogo={surveyLogo}
                            GetParameterLabel={GetParameterLabel}
                            getEMPFilterLabel={getEMPFilterLabel}
                            CountRestrict={CountRestrict}
                            surveyNAME={surveyNAME}
                            createdAt={createdAt}
                          />
                          <OrgDMG
                            getSurveyData={getSurveyData}
                            getOrgIndexV2Data={getOrgIndexV2Data}
                            getDemographicOrgReportData={
                              getDemographicOrgReportData
                            }
                            GetParameterLabel={GetParameterLabel}
                            surveyLogo={surveyLogo}
                            getEMPFilterLabel={getEMPFilterLabel}
                            CountRestrict={CountRestrict}
                            setPDF={setPDF}
                            getOrgDriverOverallData={getOrgDriverOverallData}
                            surveyNAME={surveyNAME}
                            createdAt={createdAt}
                          />
                        </>
                      ) : null}

                      {OutPutData56 && OutPutData56.length > 0
                        ? OutPutData56.map((item) => (
                          <>
                            <ManagerOverall
                              SummaryName={item["SummaryName"]}
                              getIndexData={getIndexData}
                              indexTemplate={item["IndexNumber"]}
                              GetParameterLabel={GetParameterLabel}
                              CountRestrict={CountRestrict}
                              getQuestionFilterData={
                                item["getIndexQuestionData"]
                              }
                              surveyLogo={surveyLogo}
                              getOverallIndexData={getOverallIndexData}
                              setPDF={setPDF}
                              getQuesOverallData={
                                item["getIndexOverallQuesData"]
                              }
                              getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                              getSelectiveScores={getSelectiveScores}
                              surveyNAME={surveyNAME}
                              createdAt={createdAt}
                              getRespondantDetailsData={getRespondantDetailsData}
                            />

                            <Behaviors
                              SummaryName={item["SummaryName"]}
                              getQuestionData={
                                item["getIndexQuestionData"]
                              }
                              GetParameterLabel={GetParameterLabel}
                              CountRestrict={CountRestrict}
                              surveyLogo={surveyLogo}
                              indexTemplate={item["IndexNumber"]}
                              setPDF={setPDF}
                              getQuesOverallData={
                                item["getIndexOverallQuesData"]
                              }
                              surveyNAME={surveyNAME}
                              createdAt={createdAt}
                            />



                            <OrgCoreItems
                              SummaryName={item["SummaryName"]}
                              getIndexData={getIndexData}
                              indexTemplate={item["IndexNumber"]}
                              GetParameterLabel={GetParameterLabel}
                              CountRestrict={CountRestrict}
                              getQuestionFilterData={
                                item["getIndexQuestionData"]
                              }
                              surveyLogo={surveyLogo}
                              setPDF={setPDF}
                              getQuesOverallData={
                                item["getIndexOverallQuesData"]
                              }
                              surveyNAME={surveyNAME}
                              createdAt={createdAt}
                            />
                          </>
                        ))
                        : null}


                      <OrgCoreItems
                        SummaryName={"Organization Core"}
                        getIndexData={getIndexData}
                        indexTemplate={8}
                        GetParameterLabel={GetParameterLabel}
                        CountRestrict={CountRestrict}
                        getQuestionFilterData={getQuestionFilterData}
                        surveyLogo={surveyLogo}
                        setPDF={setPDF}
                        getQuesOverallData={getOrgQuestionOverall}
                        surveyNAME={surveyNAME}
                        createdAt={createdAt}
                      />
                    </>
                  ) : null}

                  {/* Engagement Comparison */}
                  {/* <h3 className="page-break"></h3> */}

                  <EngagementComparison
                    getDemographicIndexMultiFilterEngCompData={
                      getDemographicIndexMultiFilterEngCompData
                    }
                    surveyLogo={surveyLogo}
                    GetParameterLabel={GetParameterLabel}
                    getEMPFilterLabel={getEMPFilterLabel}
                    CountRestrict={CountRestrict}
                    getDemographicIndexEngCompOverallData={
                      getDemographicIndexEngCompOverallData
                    }
                    surveyNAME={surveyNAME}
                    createdAt={createdAt}
                  />

                  {/* <h3 className="page-break"></h3>
                  <QuestionComments

                    surveyLogo={surveyLogo}
                    GetParameterLabel={GetParameterLabel}

                    getQuestionCommentsData={getQuestionCommentsData}

                  /> */}
                </PDFExport>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(OverallReport);
