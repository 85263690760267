import React, { Component } from "react";
import { connect } from "react-redux";
import QuestionStack from "./Charts/QuestionStack";
import Commentary from "../Commentary/Commentary";
import Filter from "./Filter/Filter";
import { CSVLink } from "react-csv";
import DataNotFound from "../../../Components/DataNotFound";
class QuestionAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: "none",
      currFilterName: "none",

      currentSelectValue: "",
      FilterValues: [],
      colorTheme: 1,
      showFilter: false,
      showValue: "",
      showDownloadAll: false,
    };
  }
  componentDidMount() { }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleDownloadAll = (check) => {
    this.setState({ showDownloadAll: check }, () => {
      let { handleQuesDistributionIndexOrg } = this.props;
      handleQuesDistributionIndexOrg();
    });
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetResQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultiFilterGetResQuestion(finalFilter);
  };

  changeColorTheme = (value) => {
    this.setState({ colorTheme: value });
  };

  render() {
    let {
      selectedFilterValue,
      loading,
      getQuestionDistributionData,
      ErrorIndexObj,
      getQuesDistributionIndexOrgData,
      indexType,
      optionType,
      getCommentData,
      CountRestrict,
      GetParameterLabel,
      getEMPFilterLabel,

      getQuestionData,
      XAxis,
      getQuestionFilterData,
      handleQuestionFilter,
      EmpFilterData,
      getIndexFilterData,
      getIndexByFilterDMGData,
    } = this.props;

    let { showDownloadAll, colorTheme, FilterValues } = this.state;

    let QuestionData = [];
    if (this.state.currFilterValue === "none") {
      QuestionData = getQuestionDistributionData;
    } else {
      QuestionData = getQuestionFilterData;
    }

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }

      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;
    }

    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split(".")[0];
        let second = str.split(".")[1];
        let second2 = second ? second.substring(0, 2) : "";
        let final = first + "." + second2;
        if (second2) {
          return parseFloat(final).toFixed(2);
        } else {
          return no;
        }
      } else {
        return 0;
      }
    }

    let ScoreExcel = [];

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((question, index) => {
        let tempDataV1 = {
          Question: question && question.name ? question.name : "",
        };
        let total =
          question["Strongly Agree"] +
          question["Agree"] +
          question["Slightly Agree"] +
          question["Slightly Disagree"] +
          question["Disagree"] +
          question["Strongly Disagree"];

        tempDataV1["Top 2"] = spliceDecimal(
          ((question["Strongly Agree"] + question["Agree"]) * 100) / total
        );

        tempDataV1["6"] = spliceDecimal(
          (question["Strongly Disagree"] * 100) / total
        );
        tempDataV1["5"] = spliceDecimal((question["Disagree"] * 100) / total);
        tempDataV1["4"] = spliceDecimal(
          (question["Strongly Disagree"] * 100) / total
        );
        tempDataV1["3"] = spliceDecimal(
          (question["Slightly Agree"] * 100) / total
        );
        tempDataV1["2"] = spliceDecimal((question["Agree"] * 100) / total);
        tempDataV1["1"] = spliceDecimal(
          (question["Strongly Agree"] * 100) / total
        );

        tempDataV1["Bottom 2"] = spliceDecimal(
          ((question["Strongly Disagree"] + question["Disagree"]) * 100) / total
        );

        ScoreExcel.push(tempDataV1);
      });
    }

    let ScoreExcelV1 = [];

    console.log(
      "getQuesDistributionIndexOrgData--->",
      getQuesDistributionIndexOrgData
    );

    const groupByCategory =
      getQuesDistributionIndexOrgData &&
        getQuesDistributionIndexOrgData.length > 0
        ? getQuesDistributionIndexOrgData.reduce((group, product) => {
          const { parameterBucketName } = product;
          group[parameterBucketName] = group[parameterBucketName] ?? [];
          group[parameterBucketName].push(product);
          return group;
        }, {})
        : {};

    console.log("getQuesDistributionIndexOrgData--->", groupByCategory);

    if (
      groupByCategory &&
      Object.keys(groupByCategory) &&
      Object.keys(groupByCategory).length > 0
    ) {
      Object.keys(groupByCategory).forEach((IndexName) => {
        let questionList = groupByCategory[IndexName];
        if (questionList && questionList.length > 0) {
          questionList.forEach((question) => {
            let tempDataV2 = {};
            tempDataV2["Index"] = IndexName;
            tempDataV2["Dimension"] =
              question && question.parameterDimensionName
                ? question.parameterDimensionName
                : "";
            tempDataV2["Question"] =
              question && question.name ? question.name : "";
            let total =
              question["Strongly Agree"] +
              question["Agree"] +
              question["Slightly Agree"] +
              question["Slightly Disagree"] +
              question["Disagree"] +
              question["Strongly Disagree"];

            tempDataV2["Top 2"] = spliceDecimal(
              ((question["Strongly Agree"] + question["Agree"]) * 100) / total
            );

            tempDataV2["6"] = spliceDecimal(
              (question["Strongly Disagree"] * 100) / total
            );
            tempDataV2["5"] = spliceDecimal(
              (question["Disagree"] * 100) / total
            );
            tempDataV2["4"] = spliceDecimal(
              (question["Strongly Disagree"] * 100) / total
            );
            tempDataV2["3"] = spliceDecimal(
              (question["Slightly Agree"] * 100) / total
            );
            tempDataV2["2"] = spliceDecimal((question["Agree"] * 100) / total);
            tempDataV2["1"] = spliceDecimal(
              (question["Strongly Agree"] * 100) / total
            );

            tempDataV2["Bottom 2"] = spliceDecimal(
              ((question["Strongly Disagree"] + question["Disagree"]) * 100) /
              total
            );
            ScoreExcelV1.push(tempDataV2);
          });
        }
      });
    }



    if (QuestionData && QuestionData.length > 0) {
      QuestionData.sort(
        (a, b) => GetParameterLabel(a.parameterDimensionName).trim().charCodeAt(0) - GetParameterLabel(b.parameterDimensionName).trim().charCodeAt(0)
      );
    }
    console.log("QuestionData----->", QuestionData);



    return (
      <>
        <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          <div className="flex flex-wrap gap-2 ">
            {ScoreExcel ? (
              <CSVLink data={ScoreExcel} filename={"DetailedDistribution.csv"}>
                <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M10 12l4 4m0 -4l-4 4" />
                  </svg>

                  <span>Download Excel</span>
                </button>
              </CSVLink>
            ) : (
              "..."
            )}

            {showDownloadAll ? (
              <div>
                {ScoreExcelV1 ? (
                  <CSVLink
                    data={ScoreExcelV1}
                    filename={"AllIndexDetailedDistribution.csv"}
                  >
                    <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        <path d="M10 12l4 4m0 -4l-4 4" />
                      </svg>

                      <span>Download Excel (All)</span>
                    </button>
                  </CSVLink>
                ) : (
                  "..."
                )}
              </div>
            ) : (
              <div onClick={() => this.handleDownloadAll(!showDownloadAll)}>
                <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-refresh"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                  </svg>

                  <span> {"Load Excel (All)"}</span>
                </button>
              </div>
            )}

            {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />
          </div>
        </div>

        {true ? (
          <>
            {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}
            {/* 
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}

            {/* </div> */}

            {/* {FilterValues && FilterValues.length > 0 ?
              <div className="items-center h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex justify-left">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null} */}

            <div onClick={() => this.closeFilter(false)} className="">
              {/* <div className="flex w-full" >


                <div className="w-full px-4 m-4 bg-white rounded shadow-lg">
                  <div className="flex justify-between">
                    <div className="py-4 text-lg font-semibold">Detailed Distribution</div> */}

              <div className="w-full">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">
                  <div className="p-4 space-y-4 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                      Detailed Distribution
                    </h1>

                    <div className="w-full mb-4 whitespace-nowrap overflow-x-auto customscroll2">
                      <table className="w-full border whitespace-nowrap ">
                        <thead>
                          <tr className="bg-gray-100 border-b text-sm  text-[#3D405B]">
                            <th className="py-2 px-4 border-r font-bold  md:w-[20%] w-32">
                              <div className="flex items-center justify-left">
                                Dimension
                              </div>
                            </th>
                            <th className="py-2 px-4 border-r font-bold md:w-[40%] w-60">
                              <div className="flex items-center justify-left">
                                Question
                              </div>
                            </th>
                            <th className="py-2 px-4 border-r font-bold md:w-[40%] w-60">
                              <div className="flex items-center justify-left">
                                Distribution (%)
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {QuestionData && QuestionData.length > 0
                            ? QuestionData.map((question, index) => (
                              <tr
                                key={index}
                                className="bg-white  mb-10 lg:mb-0 text-sm text-[#3D405B]"
                              >
                                <td className="py-2 px-4 border border-b   ">
                                  {question && question.parameterDimensionName
                                    ? GetParameterLabel(
                                      question.parameterDimensionName
                                    )
                                    : ""}
                                </td>
                                <td className="tooltip-response-question1 py-2 px-4 border border-b  ">
                                  <div className=" md:w-auto w-60 whitespace-normal">
                                    {question.name}
                                  </div>
                                  {/* <div className="tooltip-response-question2">
                              {question.name}
                            </div> */}
                                </td>
                                <td className="py-2 px-4 border border-b   ">
                                  <div className="tooltip-response1 md:w-auto w-60">
                                    {CountRestrict(question.count) ? (
                                      <div className="p-3 font-semibold text-center text-purple-500">
                                        NaN
                                      </div>
                                    ) : (
                                      <QuestionStack
                                        colorTheme={colorTheme}
                                        question={question}
                                        currFilterValue={
                                          this.state.currFilterValue
                                        }
                                        currFilterName={
                                          this.state.currFilterName
                                        }
                                        selectedFilterValue={
                                          selectedFilterValue
                                        }
                                        getQuestionFilterData={
                                          getQuestionFilterData
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-sm text-[#212121]/70">
                      * We are not showing data where employee count is less
                      than 5
                    </div>

                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="bg-white p-6 border rounded-lg hover:shadow-lg">
                <DataNotFound />
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(QuestionAnalysis);
