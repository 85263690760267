import React from "react";
import moment from "moment";

export default function SubDomains(props) {
  let { userName, createdAt } = props;

  // const leadershipAttributes0 = [
  //   {
  //     attribute: "Thinking",
  //     description: "Thinking in the context of leadership assessment refers to a leaders approach to processing information and making decisions, balancing between intellectual and practical orientations.",
  //   },
  //   {
  //     attribute: "Speaking",
  //     description: "Speaking in leadership refers to a leaders preferred style of communication and engagement with others, ranging from extroverted to introverted approaches.",
  //   },
  //   {
  //     attribute: "Logic",
  //     description: "Logic in leadership describes a leaders approach to decision-making and problem-solving, balancing between rational analysis and emotional intuition.",
  //   },
  //   {
  //     attribute: "Authenticity",
  //     description: "Authenticity in leadership reflects a leaders way of expressing their true self and values, ranging from honest openness to purposeful adaptability.",
  //   },
  //   {
  //     attribute: "Self-Awareness",
  //     description: "Self-Awareness in leadership represents a leaders understanding of their own strengths, weaknesses, and behaviors, existing on a spectrum from high self-awareness to blind spots.",
  //   }
  // ];
  // const leadershipAttributes1 = [
  //   {
  //     attribute: "Relate",
  //     description: "Relate in leadership describes a leader’s approach to building relationships, spanning from forming deep connections to broad networking."
  //   },
  //   {
  //     attribute: "Developing",
  //     description: "Developing in leadership refers to a leaders approach to nurturing talent and potential in others, ranging from focusing on everyone's growth to prioritizing a select few. "
  //   },
  //   {
  //     attribute: "Caring",
  //     description: "Caring in leadership reflects a leader’s focus on either nurturing individuals or prioritizing task outcomes."
  //   },
  //   {
  //     attribute: "Feedback",
  //     description: "Feedback in leadership pertains to a leader’s style of offering guidance and critique, ranging from constructive to critical approaches."
  //   },
  //   {
  //     attribute: "Trusting",
  //     description: "Trusting in leadership describes a leaders approach to building trust with others, characterized by a spectrum from easy trust to cautious trust."
  //   }
  // ];
  // const leadershipAttributes2 = [
  //   {
  //     attribute: "Orientation",
  //     description: "Orientation in leadership refers to a leaders approach to collaboration and teamwork, ranging from a collaborative mindset to a more independent, self-reliant perspective."
  //   },
  //   {
  //     attribute: "Conflict Resolution",
  //     description: "Conflict Resolution in leadership describes a leaders approach to managing and addressing conflicts within a team or organization, characterized by a spectrum from direct confrontation to a more harmonious, thoughtful approach."
  //   },
  //   {
  //     attribute: "Leading",
  //     description: "Leading in leadership refers to a leaders preferred style of guiding and influencing others, characterized by a spectrum from leading from the front to leading from the back"
  //   },
  //   {
  //     attribute: "Interests",
  //     description: "Interests in leadership reflect a leaders orientation towards prioritizing the success of others versus their own personal goals."
  //   },
  //   {
  //     attribute: "Personalisation",
  //     description: "Personalisation in leadership refers to a leaders approach to interacting with individuals, characterized by a spectrum from consistency in behavior to individualized engagement."
  //   }
  // ];
  const leadershipAttributes3 = [
    {
      attribute: "Ownership",
      description: "Ownership in leadership describes a leaders approach to responsibility and accountability for tasks and outcomes, ranging from taking personal responsibility to delegating effectively."
    },
    {
      attribute: "Solution",
      description: "Solution in leadership refers to a leaders approach to problem-solving and innovation, characterized by a spectrum from disruptive change to incremental improvement"
    },
    {
      attribute: "Change",
      description: "Change in leadership refers to a leaders attitude and approach toward organizational and personal transformations, characterized by a spectrum from transformational change to transactional change."
    },
    {
      attribute: "Structure",
      description: "Structure in leadership describes a leaders preference for order and organization in their approach to work and decision-making, characterized by a spectrum from rigidity to fluidity."
    },
    {
      attribute: "Achievement",
      description: "Achievement in leadership refers to a leaders focus on success and performance metrics, characterized by a spectrum from external benchmarks to internal benchmarks."
    }
  ];
  const leadershipAttributes4 = [
    {
      attribute: "World View",
      description: "World View in leadership reflects a leaders perspective on the interconnectedness of individuals and their actions within the broader context of society, characterized by a spectrum from connectedness to disjointedness."
    },
    {
      attribute: "Outlook",
      description: "Outlook in leadership refers to a leaders general attitude and perspective toward life and situations, characterized by a spectrum from positivity to caution."
    },
    {
      attribute: "Transactions",
      description: "Transactions in leadership describe a leaders approach to interpersonal interactions and exchanges, characterized by a spectrum from being a giver to being a taker."
    },
    {
      attribute: "Community Thinking",
      description: "Community Thinking in leadership refers to a leaders consideration of the broader societal implications of their decisions and actions, characterized by a spectrum from high community awareness to a non-existent focus on community impact."
    },
    {
      attribute: "Social Concern",
      description: "Social Concern in leadership refers to a leaders prioritization of societal well-being in their decision-making, characterized by a spectrum from altruism to self-care."
    }
  ];

  return (
    <>
      <main className="p-10 px-12 space-y-6 bg-white page-break">
        <div className='page-break'>
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">Leadership Report</h1>
            <p className="text-lg font-semibold">{userName + " | " + createdAt}</p>
          </div>

          <div className="flex flex-col space-y-4 w-full pt-5">
            <h1 className="text-2xl font-medium capitalize bg-[#212121]/90 text-white px-4 py-3">Leadership 25 Sub-Domains Definition</h1>
            <div className="flex flex-wrap justify-start gap-4 text-sm text-[#3C3C3C]   ">
              <div className="flex items-center ">
                <div className="p-1.5 rounded-sm bg-[#FFC107]" />
                <p className="ml-1.5">Leading Self</p>
              </div>
              <div className="flex items-center ">
                <div className="p-1.5 rounded-sm bg-[#4CAF50]" />
                <p className="ml-1.5">Leading Others</p>
              </div>
              <div className="flex items-center ">
                <div className="p-1.5 rounded-sm bg-[#2196F3]" />
                <p className="ml-1.5">Leading Team</p>
              </div>
              <div className="flex items-center ">
                <div className="p-1.5 rounded-sm bg-[#673AB7]" />
                <p className="ml-1.5">Leading Results</p>
              </div>
              <div className="flex items-center ">
                <div className="p-1.5 rounded-sm bg-[#3F51B5]" />
                <p className="ml-1.5">Leading Community</p>
              </div>

            </div>

            <div className="space-y-1">

              <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                <thead className="">
                  <tr>
                    <th className="p-2 px-4 text-sm font-bold text-[#212121] text-left w-[20%] border-r-2 border-b-2 border-white">
                      Sub-Domains
                    </th>
                    <th className="p-2 text-sm font-bold text-[#212121] w-[80%] text-left border-r-2 border-b-2 border-white">
                      Definition
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y-2 divide-white">
                  {leadershipAttributes3.map((item, index) => (
                    <tr key={index} className="text-sm bg-[#EDE7F6]">
                      <td className="p-2 px-4 text-left border-r-2 border-b-2 border-white capitalize font-bold text-sm text-[#3C3C3C]">
                        {item.attribute}
                      </td>
                      <td className="px-2 py-2 text-left text-sm border-r-2 border-b-2 border-white">
                        {item.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <table className="w-full bg-[#F1F5F9]">
                <thead className="">
                  <tr>
                    <th className="w-[20%]">
                      {/* Sub-Domains */}
                    </th>
                    <th className="w-[80%]">
                      {/* Definition */}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y-2 divide-white">
                  {leadershipAttributes4.map((item, index) => (
                    <tr key={index} className="text-sm bg-[#E8EAF6]">
                      <td className="p-2 px-4 text-left border-r-2 border-b-2 border-white capitalize font-bold text-sm text-[#3C3C3C]">
                        {item.attribute}
                      </td>
                      <td className="px-2 py-2 text-left text-sm border-r-2 border-b-2 border-white">
                        {item.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


          </div>

        </div >
      </main >
    </>
  );
}
