import React, { Component } from "react";
import BarDistribution from "./Chart/BarDistribution";
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      getOrgDriverOverallData,
      getOrgIndexV2Data,
      getSurveyData,
      isFilter,
      surveyLogo,
      GetParameterLabel,
      CountRestrict,
      surveyNAME,
      createdAt
    } = this.props;

    let OrgCoreDriverData = {};
    // if (getOrgIndexV2Data && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {
    //     getSurveyData.orgList.forEach((org) => {
    //         OrgCoreDriverData[org.name] = getOrgIndexV2Data[org.name];
    //     })
    // }

    let getSurveyOrgList = [];
    if (
      getOrgIndexV2Data &&
      getSurveyData &&
      getSurveyData.orgList &&
      getSurveyData.orgList.length > 0
    ) {
      getSurveyData.orgList.forEach((org) => {
        let getIndex1 = getSurveyOrgList.findIndex((prev) => prev === org.name);
        if (getIndex1 === -1) {
          getSurveyOrgList.push(org.name);
        }
      });
    }

    if (getOrgIndexV2Data && getSurveyOrgList && getSurveyOrgList.length > 0) {
      getSurveyOrgList.forEach((org) => {
        OrgCoreDriverData[org] = getOrgIndexV2Data[org];
      });
    }

    let isAllow = getOrgIndexV2Data
      ? CountRestrict(
        getOrgIndexV2Data && getOrgIndexV2Data["count"]
          ? getOrgIndexV2Data["count"]
          : 0
      )
        ? false
        : true
      : false;

    let valueList = [];
    if (OrgCoreDriverData) {
      valueList = Object.keys(OrgCoreDriverData).map((key) => [
        key,
        isAllow ? OrgCoreDriverData[key] : "NA",
      ]);
    }

    valueList.sort((b, a) => a[1] - b[1]);

    //////console.log("getOrgIndexV2Data---->",getOrgIndexV2Data);
    //////console.log("valueList---->",valueList);
    function calcOverall(itemNamw) {
      return getOrgDriverOverallData &&
        getOrgDriverOverallData[itemNamw] &&
        getOrgDriverOverallData[itemNamw].toFixed(2)
        ? getOrgDriverOverallData[itemNamw].toFixed(2)
        : 0;
    }



    function chunkList(array) {
      const chunkSize = 11;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      //console.log("list--->", list);
      return list;
    }



    return (
      <>
        {valueList && valueList.length > 0 ?
          chunkList(valueList).map((NewQues, indexX) =>
            <section className=" w-full px-8  py-6 page-break">
              {/* report header */}
              <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
              <p class="font-medium italic text-base ">{surveyNAME}</p>
            <p class="font-medium italic text-base">{createdAt}</p>
              </div>
              <table className="w-full ">
                <tbody className="divide-y-2 divide-white ">
                  {NewQues && NewQues.length > 0
                    ? NewQues.map((itemData, index) => (
                      <>
                        {index % 13 === 0 ? (
                          <>
                            {index ? (
                              <tr className="text-lg text-gray-500 capitalize ">
                                <th
                                  className="p-2 font-bold text-left text-gray-700 w-12/12 "
                                  colSpan={4}
                                >
                                  Continue...
                                </th>
                              </tr>
                            ) : null}

                            <tr className="my-2 page-break">
                              <td colSpan={4} className="text-left ">
                                <div className="-mx-8">
                                  <Header surveyLogo={surveyLogo} />

                                  <div className>
                                    <div className="w-full px-8 py-6 capitalize ">
                                      <h1 className="text-2xl text-[#212121] font-medium  capitalize">
                                        {"Employee Experience across Organizational core"+(indexX>0?" (Continue)":"")}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr className="border-b bg-[#DBEEFD] text-[#212121] text-sm border-l border-r border-white ">
                              <th className="w-[25%] font-semibold p-2  text-left border-r border-white ">
                                Dimension
                              </th>
                              <th
                                className="w-[15%] font-semibold p-2  text-right border-r border-white "
                              >
                                Comparison
                              </th>
                              <th className="w-[35%] font-semibold p-2  text-center border-r border-white ">
                                Score
                              </th>
                              <th className="w-[15%] font-semibold p-2  text-center border-r border-white ">
                                Difference
                              </th>
                            </tr>
                          </>
                        ) : null}

                        <tr className="border-b bg-[#F5F5F5] text-[#212121] text-sm border-l border-r border-white ">
                          <td rowSpan={2} className="p-2 font-medium capitalize border-r border-white ">
                            {GetParameterLabel(itemData[0])}
                          </td>
                          <td className="p-2 text-sm text-right border-r-2 border-white">score</td>
                          <td className="p-2 font-medium capitalize border-r-2 border-white ">
                            <div className="h-6 w-full bg-[#CFD8DC]">
                              <div
                                className="w-full h-6 bg-blue-400 flex items-center"
                                style={{
                                  background: "#1E88E5",
                                  width:
                                    (itemData &&
                                      itemData[1] &&
                                      itemData[1] !== "NA"
                                      ? itemData[1]
                                      : "0") + "%",
                                }}
                              >
                                <span className="text-sm font-medium  text-white px-2">
                                  {itemData &&
                                    itemData[1] &&
                                    itemData[1] !== "NA" &&
                                    itemData[1].toFixed(2)
                                    ? itemData[1].toFixed(2) + "%"
                                    : "0"}

                                </span>
                              </div>
                            </div>
                          </td>
                          <td rowSpan={2} className=" px-2 text-sm font-medium text-center text-[#4CAF50] ">

                            +{calcOverall(itemData[0])}{" "}

                          </td>
                        </tr>
                        <tr className="bg-[#F5F5F5]">
                          <td className="pp-2 text-sm text-right border-r-2 border-white">Overall</td>

                          <td className="p-2 text-sm text-right border-r-2 border-white ">

                            <div className="h-6 w-full bg-[#CFD8DC]">
                              <div
                                className="w-full h-6 bg-blue-400 flex items-center"
                                style={{
                                  background: "#9C27B0",
                                  width:
                                    (itemData &&
                                      itemData[1] &&
                                      itemData[1] !== "NA"
                                      ? itemData[1]
                                      : "0") + "%",
                                }}
                              >
                                <span className="text-sm font-medium  text-white px-2">
                                  {itemData &&
                                    itemData[1] &&
                                    itemData[1] !== "NA" &&
                                    itemData[1].toFixed(2)
                                    ? itemData[1].toFixed(2) + "%"
                                    : "0"}

                                </span>
                              </div>
                            </div>


                          </td>
                        </tr>
                      </>
                    ))
                    : null}



                  {indexX < chunkList(valueList).length - 1 ? (
                    <tr className="text-lg text-gray-500 capitalize bg-white">
                      <td
                        colSpan={5}
                        className="font-normal p-2 text-sm text-left text-[#3D405B]/70 "
                      >
                        Continue...
                      </td>
                    </tr>
                  ) : null}



                </tbody>
              </table>
            </section>
          ) : null}
      </>


    );
  }
}
export default Employee;
