import * as React from 'react';

const PageTemplate = props => {

  let { onlyForClient } = props;
  let Number1 = props && props.pageNum ? props.pageNum : 0;
  let SurveyLogo = props && props.surveyLogo ? props.surveyLogo : "/img/logo/happypluslogo.png";



  let pageNo = 0
  if (Number1 > 3) {
    pageNo = Number1 - 3
  }

  return (
    <>

      <div className='absolute flex items-center justify-between bottom-10 left-2'>
        <div className='pt-4 mr-96 w-32'>
          <img src={onlyForClient ? "/img/logo/culturecredologo.png" : SurveyLogo} className="object-cover w-32 mx-4" alt="default_survey_logo" />
        </div>
        <div className='pt-2 ml-64 mr-6'>
          {
            pageNo > 0 ?
              <p className='text-center text-[#212121]/85 font-bold pb-1'>{pageNo}</p> : null
          }
        </div>
      </div>
    </>);
};

export default PageTemplate;