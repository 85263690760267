import * as React from 'react';

export default function IntroPage(props) {
  let { surveyId, surveyIdMatch, userName, createdAt, DimensionNameList,GetParameterLabel } = props;
  return (
    <>
      <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
        {/* report header */}
        <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
          <p className='font-medium italic text-base '>360 Feedback</p>
          <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
        </div>

        <div className="flex flex-col space-y-4 w-full ">
          <h1 className="text-2xl font-medium capitalize  text-[#212121]">
            Introduction
          </h1>

          <p className='w-full text-sm text-[#212121]'>
           {`This report offers comprehensive insight on your areas of impact as an HR leader HDFC Ergo. This report draw from various perspectives including your own, as well as those of your manager, peers, direct reports, or skip-level contacts. It’s essential to understand that the feedback provided reflects individual perceptions rather than an immutable truth. The aim of this analysis is to enhance self-awareness, enabling you to leverage your strengths effectively, understand others’ perspectives, and identify areas for improvement that may be impacting your success. Utilize this report to optimize your effectiveness and realize your full potential. `}

          </p>
          <div className='space-y-4 text-sm text-slate-800'>
            <p className='text-xl font-semibold text-[#212121] '>Key Impact Themes:</p>

            {(surveyId === surveyIdMatch) && false ?
              <div className=' grid grid-cols-2 gap-1 '>
                <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-gradient-to-r from-[#2196F3]/10 font-semibold text-base text-[#212121] italic'>1. Lead With Strategy & Critical Thinking</div>
                <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-[#2196F3]/10 font-semibold text-base text-[#212121] italic'>2. Stakeholder & Customer Focus</div>
                <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-[#2196F3]/10 font-semibold text-base text-[#212121] italic'>3. Champion Innovation</div>
                <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-[#2196F3]/10 font-semibold text-base text-[#212121] italic'>4. Change Leadership</div>
                <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-[#2196F3]/10 font-semibold text-base text-[#212121] italic'>5. Developing Others</div>
                <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-[#2196F3]/10 font-semibold text-base text-[#212121] italic'>6. Communication</div>
                <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-[#2196F3]/10 font-semibold text-base text-[#212121] italic'>7. Values</div>
              </div>
              :
              <div className=' grid grid-cols-2 gap-1  grid-flow-col grid-rows-4'>
                {DimensionNameList && DimensionNameList.length > 0 ?
                  DimensionNameList.map((dimen, index) =>
                    <div className='border-l-4 border-[#2196F3] px-4 py-2 bg-gradient-to-r from-[#2196F3]/10 font-semibold text-base text-[#212121] italic '>{(index + 1) + ". " + GetParameterLabel(dimen)}</div>
                  ) : null}
              </div>}


            <p className='text-[#212121] text-sm'>{"Close ended questions are asked on six-point scale ranging from Strongly Disagree (1) to Strongly Agree (6) Percentage scores indicate the extent of satisfaction (Agree & Strongly Agree) on the indicated aspect."}</p>
            <div className='grid grid-cols-3 gap-4'>
              <div className='text-base grid grid-cols-1 grid-rows-6 gap-1'>
                <p className='bg-[#212121]/5 px-4 py-2.5 font-semibold italic'>Strongly Disagree</p>
                <p className='bg-[#212121]/5 px-4 py-2.5 font-semibold italic'>Disagree</p>
                <p className='bg-[#212121]/5 px-4 py-2.5 font-semibold italic'>Slightly Disagree</p>
                <p className='bg-[#212121]/5 px-4 py-2.5 font-semibold italic'>Slightly Agree</p>
                <p className='bg-[#212121]/5 px-4 py-2.5 font-semibold italic'>Agree</p>
                <p className='bg-[#212121]/5 px-4 py-2.5 font-semibold italic'>Strongly Agree</p>
              </div>
              <div className='text-base grid grid-cols-1 grid-rows-6 gap-1'>
                <div className=' px-4 py-2 font-semibold italic flex items-center space-x-4  '>
                  <div className='w-8 h-8 rounded-full bg-[#212121]/5 flex justify-center items-center flex-shrink-0'>1</div>
                  <div className='flex items-center w-full'>
                    <span className='w-full h-0.5 bg-[#F44336] flex-shrink-0 '></span>
                    <svg className='flex-shrink-0 text-[#F44336]' xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 9 11" fill="none">
                      <path d="M8.33398 5.24996L0.0839844 0.486816V10.0131L8.33398 5.24996Z" fill="currentColor" />
                    </svg>
                  </div>
                </div>


                <div className=' px-4 py-2 font-semibold italic flex items-center space-x-4  '>
                  <div className='w-8 h-8 rounded-full bg-[#212121]/5 flex justify-center items-center flex-shrink-0'>2</div>
                  <div className='flex items-center w-full'>
                    <span className='w-full h-0.5 bg-[#F44336] flex-shrink-0 '></span>
                    <svg className='flex-shrink-0 text-[#F44336]' xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 9 11" fill="none">
                      <path d="M8.33398 5.24996L0.0839844 0.486816V10.0131L8.33398 5.24996Z" fill="currentColor" />
                    </svg>
                  </div>
                </div>


                <div className=' px-4 py-2 font-semibold italic flex items-center space-x-4'>
                  <div className='w-8 h-8 rounded-full bg-[#212121]/5 flex justify-center items-center flex-shrink-0'>3</div>
                  <div className='flex items-center w-full'>
                    <span className='w-full h-0.5 bg-[#FFC107] flex-shrink-0 '></span>
                    <svg className='flex-shrink-0 text-[#FFC107]' xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 9 11" fill="none">
                      <path d="M8.33398 5.24996L0.0839844 0.486816V10.0131L8.33398 5.24996Z" fill="currentColor" />
                    </svg>
                  </div>
                </div>
                <div className=' px-4 py-2 font-semibold italic flex items-center space-x-4'>
                  <div className='w-8 h-8 rounded-full bg-[#212121]/5 flex justify-center items-center flex-shrink-0'>4</div>
                  <div className='flex items-center w-full'>
                    <span className='w-full h-0.5 bg-[#8BC34A] flex-shrink-0 '></span>
                    <svg className='flex-shrink-0 text-[#8BC34A]' xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 9 11" fill="none">
                      <path d="M8.33398 5.24996L0.0839844 0.486816V10.0131L8.33398 5.24996Z" fill="currentColor" />
                    </svg>
                  </div>
                </div>
                <div className=' px-4 py-2 font-semibold italic flex items-center space-x-4'>
                  <div className='w-8 h-8 rounded-full bg-[#212121]/5 flex justify-center items-center flex-shrink-0'>5</div>
                  <div className='flex items-center w-full'>
                    <span className='w-full h-0.5 bg-[#4CAF50] flex-shrink-0 '></span>
                    <svg className='flex-shrink-0 text-[#4CAF50]' xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 9 11" fill="none">
                      <path d="M8.33398 5.24996L0.0839844 0.486816V10.0131L8.33398 5.24996Z" fill="currentColor" />
                    </svg>
                  </div>
                </div>
                <div className=' px-4 py-2 font-semibold italic flex items-center space-x-4  '>
                  <div className='w-8 h-8 rounded-full bg-[#212121]/5 flex justify-center items-center flex-shrink-0'>6</div>
                  <div className='flex items-center w-full'>
                    <span className='w-full h-0.5 bg-[#4CAF50] flex-shrink-0 '></span>
                    <svg className='flex-shrink-0 text-[#4CAF50]' xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 9 11" fill="none">
                      <path d="M8.33398 5.24996L0.0839844 0.486816V10.0131L8.33398 5.24996Z" fill="currentColor" />
                    </svg>
                  </div>
                </div>

              </div>
              <div className='text-base grid grid-cols-1 grid-rows-6 gap-1'>
                <div className='px-4 py-2 font-medium italic bg-[#F44336] row-span-2 flex justify-center items-center'>
                  <p className='text-white text-center'>Disagreeing Respondents</p>
                </div>
                <div className='px-4 py-2 font-medium italic bg-[#FFC107] flex justify-center items-center'>
                  <p className='text-white text-center'>Negative Hisitants</p>
                </div>
                <div className='px-4 py-2 font-medium italic bg-[#8BC34A] flex justify-center items-center'>
                  <p className='text-white text-center'>Positive Hesitants</p>
                </div>
                <div className='px-4 py-2 font-medium italic bg-[#4CAF50] row-span-2 flex justify-center items-center'>
                  <p className='text-white text-center'>Agreeing Respondents</p>
                </div>
              </div>
            </div>
            <p className='text-[#212121] text-sm'>
              {`As a first step, we recommend that you go through the results in the subsequent pages and spend some time reflecting on them. While you do that, we urge you to see this reflection as an opportunity for self awareness and not as a judgement of you as a person. At the end of the report, your individual top 5 strengths (based on your strength assessment) are also mentioned. This is to help you to reflect and understand how there may be blind spot due to excesses usage of strength. As a next step you will create development plans that contribute to key development theme your career success. Your coach will support you in assimilating insights from the report and will be your partner on your development journey ahead.`}
            </p>
          </div>
        </div>

      </section>
    </>);
};