import * as React from 'react';

export default function IntroPageHDFC(props) {
  let { userName, createdAt, getSurveyData } = props;
  return (
    <>
      <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
        {/* report header */}
        <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
          <p className='font-medium italic text-base '>360 Feedback</p>
          <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
        </div>

        <div className="flex justify-center space-y-4 w-full">
          <h1 className="text-xl font-bold capitalize py-4 mt-2 text-[#212121]">
            {'Message from Culture Credo'}
          </h1>
        </div>


        <div className="space-y-6 w-full">
          <div className="flex justify-center py-4">
            <img src={"/img/logo/culturecredologo.png"} className="h-16" />
          </div>
          <div className="text-left">
            <p className="text-base text-[#212121]">
              We at Culture Credo are pleased to partner with you on your development journey with technology
              support from HappyPlus. The development
              themes in this report have been designed to provide you with opportunities for reflection
              on your impact as a leader at HDFC Ergo.
              The 360 degree feedback gives you a holistic view from team members, peers and managers that aim
              to enhance awareness of your impact in the organization. As a next step, a coach from Culture Credo
              will support you to identify and progress on your developmental goals relevant to your career journey
              ahead.
            </p>
          </div>
          <p className="text-left text-base text-[#212121] font-medium py-2">
            Good luck!
          </p>
          <p className="text-left text-base text-[#212121] font-medium py-4">
            For any questions or clarifications, please contact.
          </p>
        </div>

      </section >
    </>);
};