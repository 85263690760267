import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import { CSVLink } from "react-csv";  
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import DimensionReviewer from './DimensionReviewerReport/DimensionReviewer';
import Summary from './SummaryReport/Summary';
import EmployeeDropdown from '../Filter/EmployeeDropdown';
import Filter from './Filter/Filter';
import RespondantSummary from './RespondantSummary/RespondantSummary';
import CompetencySummary from './CompetencySummary/CompetencySummary';
import StrengthsWeakness from './StrengthsWeakness/StrengthsWeakness';
import HiddenBlind from './HiddenBlind/HiddenBlind';
import FeedbackResponse from './FeedbackResponse/FeedbackResponse';
import PageTemplate from './PageTemplate';
import PageTemplateHDFC from './PageTemplateHDFC';

import GuidingPrinciples from './GuidingPrinciples/GuidingPrinciples';
import DevelopmentActionPlan from './DevelopmentActionPlan/DevelopmentActionPlan';
import ActionPlanContinue from './ActionPlanContinue/ActionPlanContinue';
import DevelopmentActionPlanConitnue from './DevelopmentActionPlanConitnue/DevelopmentActionPlanConitnue';
import TempEmpStrength from './TempEmpStrength/TempEmpStrength';
import HitachiReport from './HitachiReport/HitachiReport';
import IntroPage from './IntroPage/IntroPage';
import IntroPageHDFC from './IntroPage/IntroPageHDFC';
import MessageHDFC from './IntroPage/MessageHDFC';

import Introduction from './Introduction/Introduction';
import IntroductionHDFC from './Introduction/IntroductionHDFC';


// const pdfExportComponent = React.useRef(null);
class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }
  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview, handle360GetQuestionFilter } = this.props;
    this.setState({ FilterValues: finalFilter, crrSelectedEmp: {} });
    get360Overview(finalFilter, "", "", false);
    handle360GetQuestionFilter(finalFilter, "", "", false);


  }
  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { getTemp360EmpStrengthCall, get360Overview, handle360GetQuestionFilter, getFeedbackAndQuestionName, get360RelationResponse } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360Overview(FilterValues, "", "", false, emp_id);
      handle360GetQuestionFilter(FilterValues, "", "", false, emp_id);
      get360RelationResponse(FilterValues, "", "", false, emp_id);
      getFeedbackAndQuestionName(FilterValues, "", "", false, emp_id);
      getTemp360EmpStrengthCall(emp_id);

    })
  }


  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };

  render() {


    let { onlyForClient, closeReportModal, isCheckSurvey } = this.props;
    let { } = this.state;

    let { getTemp360EmpStrengthData, surveyLogo, getSurveyData, GetParameterDesc, get360RelationResponseData, getFeedbackAndQuestionNameData, getTextAndQuestionNameData, get360DimensionRelationScoreData, getEMPFilterLabel, CountRestrict, GetParameterLabel, loading, indexType, EmpFilterData, getQuestionData,
      getIndexByFilterDMGData, getIndexFilterData, get360Overview, getRespondantDetailsData, get360DimensionQuestionsData, optionType, getQuestionFilterData, getCommentData, handle360GetQuestionFilter, getAnsweredEmployeesData } = this.props;
    let { FilterValues } = this.state;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }
    }


    // let colorList = ["#E57373","#BA68C8","#BA68C8","#9575CD","#7986CB","#64B5F6","#4FC3F7","#4DD0E1","#4DB6AC","#81C784","#DCE775","#FFB74D","#FF8A65","#90A4AE","#F06292"]
    let colorList = [
      "#3F51B5", "#1E88E5", "#9C27B0", "#00ACC1", "#EC3472",
      "#FFB74D", "#FF8A65", "#90A4AE", "#996633",
      "#FFD54F", "#E57373", "#81C784", "#64B5F6", "#DCE775",
      "#BA68C8", "#9575CD", "#7986CB", "#4FC3F7", "#4DD0E1"
    ]




    let colorListA = []
    if (getQuestionData && getQuestionData.length > 0) {
      let relation1List = getQuestionData && getQuestionData[0] && getQuestionData[0]["relationList"] ? getQuestionData[0]["relationList"] : [];
      if (relation1List && relation1List.length > 0) {
        relation1List.forEach((element, index) => {
          colorListA.push({
            "relation": element,
            "color": colorList[index] ? colorList[index] : "#FFD54F"
          })
        })
      }
    }


    console.log("{{{{{{{colorListA", colorListA)



    // let colorRelList = []
    // if(RelationList && RelationList.length>0){
    //   RelationList.forEach((item)=>{
    //     colorRelList.push({
    //       "name":item.name,
    //       "color":color
    //     })
    //   })
    // }


    function getColorByRelation(name) {
      let defaultColor = "#FFD54F";

      let getIndex = colorListA && colorListA.length > 0 ? colorListA.findIndex(prev => prev.relation === name) : -1;
      if (getIndex !== -1) {
        defaultColor = colorListA && colorListA[getIndex] && colorListA[getIndex]["color"] ? colorListA[getIndex]["color"] : "#FFD54F"
      }

      return defaultColor
    }



    let SurveyLogo = surveyLogo ? surveyLogo : "/img/logo/happypluslogo.png";




    function sortRelation(list) {


      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev.reviewer_relation === "Self")
        let List2 = list.filter(prev => prev.reviewer_relation !== "Self")

        let List3 = []
        if (List2 && List2.length > 0) {

          let sortingArr = ["Team Member", "Peer", "Skip 1", "Direct Manager"]
          if (sortingArr && sortingArr.length > 0) {
            sortingArr.forEach((ele) => {
              let GetINdex = List2.findIndex(prev => prev.reviewer_relation.trim() === ele.trim());
              if (GetINdex !== -1) {
                List3.push(List2[GetINdex])
              }
            })
          }

        }

        let List4 = List2.filter(x => !List3.includes(x))


        let list5 = List3.concat(List4)
        let listMerged = List1.concat(list5)
        console.log("List2--->", List2)
        console.log("List3--->", List3)
        console.log("List4--->", List4)
        console.log("list5--->", list5)

        console.log("listMerged--->", listMerged)




        return listMerged
      } else {
        return []
      }
    }

    let get360RelationResponseD1 = []
    if (get360RelationResponseData && get360RelationResponseData.length > 0) {
      get360RelationResponseData.forEach((element) => {
        if (element && element["completeCount"] > 0) {
          get360RelationResponseD1.push(element)
        }
      })
    }

    let relListSorted = get360RelationResponseD1 && get360RelationResponseD1.length > 0 ?
      sortRelation(get360RelationResponseD1).map((prev) => (prev.reviewer_relation)) : [];


    let surveyId = getSurveyData && getSurveyData.id ? getSurveyData.id.toString() : "";
    let surveyIdMatch = "63d754f650a5f4d496779086";



    let get360DimensionRelationScoreList1 = []
    if (get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0) {
      get360DimensionRelationScoreList1 = get360DimensionRelationScoreData.map((element) => (element))
      get360DimensionRelationScoreList1.sort((a, b) => ((a && a.item && a.item._id ? a.item._id : "").charCodeAt(0) - (b && b.item && b.item._id ? b.item._id : "").charCodeAt(0)))
    }


    let DimensionNameList = [];
    if (get360DimensionRelationScoreList1 && get360DimensionRelationScoreList1.length > 0) {
      get360DimensionRelationScoreList1.forEach((item) => {
        let dimension1 = item && item._id ? item._id : "";
        DimensionNameList.push(GetParameterLabel(dimension1));
      })
    }


    let userName = (this.state.crrSelectedEmp && this.state.crrSelectedEmp["name"] ? this.state.crrSelectedEmp["name"] : "Overall");
    // let createdAt = moment(Date.now()).format("DD MMMM, YYYY");
    let createdAt = moment(Date.now()).format("DD/MM/YYYY");




    function getScoreValueX(relation, questionX) {
      let scoreMean = 0;
      if (relation !== "Self") {
        let otherSum = 0;
        let otherCount = 0;

        if (questionX && questionX.relationList && questionX.relationList.length > 0) {
          questionX.relationList.forEach((relation) => {
            if (relation !== "Self") {
              otherCount += Number(questionX[relation + "_count"])


              otherSum += Number(questionX[relation + "_sum"]);
            }
          })
        }
        scoreMean = Number((otherSum / otherCount).toFixed(2))
      }
      else {
        scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);
      }
      return [Number((scoreMean).toFixed(2))]

    }



    function isShowHiddenStrength(getQX, isStr) {
      let questionTableList = []
      if (getQX && getQX.length > 0) {
        getQX.forEach((element) => {
          let temp = {
            "name": element.name,
            "dimension": element.parameterDimensionName,
            "Self": getScoreValueX("Self", element),
            "Others": getScoreValueX("Others", element)
          }
          questionTableList.push(temp)
        })

      }


      let listXY = []
      if (questionTableList && questionTableList.length > 0) {
        questionTableList.forEach((element) => {
          if (isStr) {
            if (element.Self < element.Others) {
              listXY.push(element)
            }
          } else {
            if (element.Self > element.Others) {
              listXY.push(element)
            }
          }


        })
      }
      console.log("listXY---->", listXY)

      if (listXY && listXY.length > 0) {
        return false
      } else {
        return true
      }
    }


    let tableOfContent = [
      {
        "sno": "1",
        "name": "Introduction",
        "children": []
      },
      {
        "sno": "2",
        "name": "Respondent Summary",
        "children": []
      },
      {
        "sno": "3",
        "name": "Summary of Impact Themes",
        "children": []
      },
      {
        "sno": "4",
        "name": "Summary of Impact Themes (Bar Chart)",
        "children": []
      },
      {
        "sno": "5",
        "name": "Theme Wise Evaluation Summary",
        "children": [
          {
            "sno": "1",
            "name": "Strengths",
            "hide": 0
          },
          {
            "sno": "2",
            "name": "Areas of Development",
            "hide": 0
          },
          {
            "sno": "3",
            "name": "Hidden Strengths",
            "hide": isShowHiddenStrength(getQuestionData, true) ? 1 : 0
          },
          {
            "sno": "4",
            "name": "Blind Spots",
            "hide": 0
          }
        ]
      },
      {
        "sno": "6",
        "name": "Open Ended Feedback",
        "children": []
      },
      {
        "sno": "7",
        "name": "Detailed Feedback",
        "children": []
      },
      {
        "sno": "8",
        "name": "Guiding Principles",
        "children": []
      },
      {
        "sno": "9",
        "name": "My Personal Reflections",
        "children": []
      }
    ]



    let tableOfContent_v2 = []
    if (tableOfContent && tableOfContent.length > 0) {
      tableOfContent.forEach((ele) => {

        let temp = {
          "sno": ele.sno,
          "name": ele.name,
          "children": ele && ele.children && ele.children.length > 0 ? ele.children.filter(prev => prev.hide !== 1) : []
        };

        tableOfContent_v2.push(temp)

      })
    }


    console.log("tableOfContent_v2---->", tableOfContent_v2)


    let sequence_rel = [
      "Self",
      "Direct Manager",
      "Peer",
      "Team Member"
    ]

    let sequence_list = [
      "Safety",
      "Integrity",
      "Accountability",
      "Collaboration and Inclusion",
      "Value Consciousness"
    ]

    function sortOrder(fun1, fun2) {
      let list = []
      if (fun1 && fun1.length > 0) {
        list = fun1.sort((a, b) => fun2.indexOf(GetParameterLabel(a)) - fun2.indexOf(GetParameterLabel(b)));
      }
      return list
    }



    console.log("DimensionNameList===>", DimensionNameList);


    function renameWords(word) {
      let list = [
        {
          "name": "Self",
          "new_name": "Self"
        },
        {
          "name": "RM",
          "new_name": "RM"
        },
        {
          "name": "PEER",
          "new_name": "Peer"
        }
      ];
      let getIndex = list.findIndex(prev => prev.name === word);
      if (getIndex !== -1) {
        return list[getIndex]["new_name"]
      } else {
        return word
      }

    }



    return (


      <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-black bg-opacity-60" >
        <div className="flex items-start justify-center h-screen p-4 overflow-hidden">

          <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-3/6 xl:w-4/6 lg:w-5/6 customscroll5" style={{ height: 'calc(100vh - 4rem)' }} >

            <div className="sticky top-0 z-20 flex flex-col items-center justify-between p-4 px-6 bg-white border-b md:flex-row"  >
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B] line-clamp-1">
                360 Feedback Report
              </h1>

              <div className='flex items-center space-x-4'>
                {/* <div onClick={() => this.exportPDFWithComponent()} className="flex items-center p-2 px-4 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer " >
                  <span class="material-symbols-outlined mr-2 " >
                    file_downloa
                  </span> Download Report
                </div> */}

                <button onClick={() => this.exportPDFWithComponent()} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"><svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path><path d="M10 12l4 4m0 -4l-4 4"></path></svg><span> Download Report</span></button>

                <div onClick={() => closeReportModal(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>
              </div>

            </div>

            <div className=''>
              <div className="flex-wrap items-center justify-between w-full gap-4 px-6 py-4 space-y-4 md:flex md:space-y-0">
                <Filter
                  showFilter={this.state.showFilter}
                  showValue={this.state.showValue}
                  closeFilter={this.closeFilter}

                  EmpFilterData2={EmpFilterData2}
                  getIndexFilterData={getIndexByFilterDMGData}
                  handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                  getEMPFilterLabel={getEMPFilterLabel}
                />


                <div>
                  <EmployeeDropdown
                    getAnsweredEmployeesData={getAnsweredEmployeesData}
                    crrSelectedEmp={this.state.crrSelectedEmp}
                    handleSelectEmployee={this.handleSelectEmployee}
                  />
                </div>
              </div>

              <PDFExport ref={this.pdfExportComponent}
                pageTemplate={(pageObj) =>
                  onlyForClient ?
                    <PageTemplateHDFC onlyForClient={onlyForClient} pageNum={pageObj.pageNum} surveyLogo={surveyLogo} />
                    :
                    <PageTemplate onlyForClient={onlyForClient} pageNum={pageObj.pageNum} surveyLogo={surveyLogo} />
                }
                scale={0.7}
                forcePageBreak=".page-break"
                paperSize="A4"
                margin={0}
                fileName={`360 Report ${userName} - ${createdAt}`}
                author="Happyplus Team"
                landscape={false}
              >

                {/* page 1 */}

                {onlyForClient ?
                  <IntroPageHDFC
                    getSurveyData={getSurveyData}
                    userName={userName}
                    createdAt={createdAt}
                  />
                  :
                  <IntroPage
                    getSurveyData={getSurveyData}
                    userName={userName}
                    createdAt={createdAt}
                  />}

                <MessageHDFC
                  getSurveyData={getSurveyData}
                  userName={userName}
                  createdAt={createdAt}
                />

                {/* page 2 */}
                <section className='p-6 px-6 flex flex-col justify-between page-break'>
                  {/* report header */}
                  <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>360 Feedback</p>
                    <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
                  </div>
                  <div className='flex flex-row space-x-8 items-center h-full'>
                    <div className='bg-[#8BC34A] p-5  flex flex-col justify-center h-screen items-center  flex-shrink-0'>
                      <img src='/img/questiontype/star/table-of-content.png' className='w-16' />
                    </div>

                    <div className='text-[#212121] divide-y divide-dashed italic w-full '>

                      {tableOfContent_v2 && tableOfContent_v2.length > 0 ?
                        tableOfContent_v2.map((ele, index1) =>

                          <div className=''>
                            <div className='flex items-center justify-between py-4'>
                              <div className='flex space-x-4 capitalize'><span>{(index1 + 1)}.</span> <p className='text-[#212121]'>{ele.name}</p></div> <p></p>
                            </div>

                            <div className='divide-y divide-dashed pl-6'>
                              {ele && ele.children && ele.children.length > 0 ?
                                ele.children.map((inner, index2) =>
                                  <div className='flex items-center justify-between py-3 '>
                                    <div className='flex w-full space-x-4 capitalize'><span>{(index1 + 1)}.{index2 + 1}</span> <p className='text-[#212121]'>{inner.name}  </p></div> <p></p>
                                  </div>
                                ) : null}
                            </div>
                          </div>


                        ) : null}
                    </div>

                  </div>
                </section>


                {/* page 3 */}

                {onlyForClient ?
                  <IntroductionHDFC
                    surveyId={surveyId}
                    surveyIdMatch={surveyIdMatch}
                    userName={userName}
                    createdAt={createdAt}
                    DimensionNameList={DimensionNameList}
                    GetParameterLabel={GetParameterLabel}
                  />
                  :
                  <Introduction
                    surveyId={surveyId}
                    surveyIdMatch={surveyIdMatch}
                    userName={userName}
                    createdAt={createdAt}
                    DimensionNameList={DimensionNameList}
                    GetParameterLabel={GetParameterLabel}
                  />
                }




                {/* page 4 */}
                <RespondantSummary
                  get360RelationResponseData={get360RelationResponseData}
                  GetParameterLabel={GetParameterLabel}
                  getColorByRelation={getColorByRelation}
                  relListSorted={relListSorted}
                  userName={userName}
                  createdAt={createdAt}
                  sequence_rel={sequence_rel}
                  renameWords={renameWords}
                />

                <CompetencySummary
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                  GetParameterLabel={GetParameterLabel}
                  GetParameterDesc={GetParameterDesc}
                  getColorByRelation={getColorByRelation}
                  relListSorted={relListSorted}
                  userName={userName}
                  createdAt={createdAt}
                  sequence_list={sequence_list}
                  sequence_rel={sequence_rel}
                  renameWords={renameWords}
                />

                <Summary
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                  EmpFilterData={EmpFilterData}
                  getIndexFilterData={getIndexFilterData}
                  getIndexByFilterDMGData={getIndexByFilterDMGData}
                  get360Overview={get360Overview}
                  getEMPFilterLabel={getEMPFilterLabel}
                  getRespondantDetailsData={getRespondantDetailsData}

                  GetParameterLabel={GetParameterLabel}
                  getQuestionData={getQuestionData}
                  getColorByRelation={getColorByRelation}
                  relListSorted={relListSorted}

                  userName={userName}
                  createdAt={createdAt}
                  sequence_list={sequence_list}
                  sequence_rel={sequence_rel}
                  renameWords={renameWords}
                />


                <StrengthsWeakness
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                  GetParameterLabel={GetParameterLabel}
                  isStrengths={true}
                  GetParameterDesc={GetParameterDesc}
                  getQuestionData={getQuestionData}

                  getColorByRelation={getColorByRelation}

                  userName={userName}
                  createdAt={createdAt}
                />

                <StrengthsWeakness
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                  GetParameterLabel={GetParameterLabel}
                  isStrengths={false}
                  GetParameterDesc={GetParameterDesc}
                  getQuestionData={getQuestionData}

                  getColorByRelation={getColorByRelation}

                  userName={userName}
                  createdAt={createdAt}
                />

                <HiddenBlind
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                  GetParameterLabel={GetParameterLabel}
                  isStrengths={true}
                  GetParameterDesc={GetParameterDesc}
                  getQuestionData={getQuestionData}

                  getColorByRelation={getColorByRelation}

                  userName={userName}
                  createdAt={createdAt}
                />

                <HiddenBlind
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                  GetParameterLabel={GetParameterLabel}
                  isStrengths={false}
                  GetParameterDesc={GetParameterDesc}
                  getQuestionData={getQuestionData}

                  getColorByRelation={getColorByRelation}

                  userName={userName}
                  createdAt={createdAt}
                />

                <FeedbackResponse
                  getFeedbackAndQuestionNameData={getFeedbackAndQuestionNameData}
                  getTextAndQuestionNameData={getTextAndQuestionNameData}

                  getColorByRelation={getColorByRelation}

                  userName={userName}
                  createdAt={createdAt}
                />


                <DimensionReviewer
                  getIndexByFilterDMGData={getIndexByFilterDMGData}
                  name={"Dimension Reviewer Details"}
                  getEMPFilterLabel={getEMPFilterLabel}
                  CountRestrict={CountRestrict}
                  GetParameterLabel={GetParameterLabel}
                  loading={loading}
                  indexType={indexType} optionType={optionType} getCommentData={getCommentData}
                  EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
                  getQuestionData={getQuestionData}
                  getQuestionFilterData={getQuestionData}
                  handle360GetQuestionFilter={this.handle360QuestionFilter}
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                  relListSorted={relListSorted}

                  getColorByRelation={getColorByRelation}

                  userName={userName}
                  createdAt={createdAt}
                  sequence_list={sequence_list}
                  sequence_rel={sequence_rel}
                  renameWords={renameWords}
                />


                <GuidingPrinciples
                  userName={userName}
                  createdAt={createdAt}

                />
                <DevelopmentActionPlan
                  userName={userName}
                  createdAt={createdAt}

                />

                <DevelopmentActionPlanConitnue
                  userName={userName}
                  createdAt={createdAt}

                />

                {getTemp360EmpStrengthData && getTemp360EmpStrengthData.length > 0 ?
                  <TempEmpStrength
                    getTemp360EmpStrengthData={getTemp360EmpStrengthData}
                    userName={userName}
                    createdAt={createdAt}
                  />
                  : null}

                <div className='flex flex-col p-6'>
                  <div className='flex flex-col space-y-6 '>
                    <p className='font-bold underline'>Confidentiality</p>
                    <p className='py-2'>
                      This report is confidential. Please do not share your individual report with others. This report provides
                      powerful information that needs to be interpreted relative to your unique position and situation.
                      Insights you gain from this feedforward should be translated into specific action plans for
                      strengthening your key developmental theme. It is recommended that you use this
                      information to strengthen your own Self-Improvement Plan . Implementing your specific goals
                      with planned action steps will increase your overall effectiveness which impacts the performance of
                      yours and your team.
                    </p>
                  </div>
                </div>
                {/* <HitachiReport/> */}
              </PDFExport>
            </div>

          </div>
        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportModal);
