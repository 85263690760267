import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";

class GrowLogin extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());

    this.props.dispatch(userActions.logoutGrowSession());
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      email: "",
      otp_code: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
    };
  }
  componentDidMount() {
    // this.props.dispatch(dashboardActions.emptyClientProfile());

    var slideIndex = 0;
    showSlides();

    function showSlides() {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slideIndex++;
      if (slideIndex > slides.length) {
        slideIndex = 1;
      }
      for (i = 0; i < dots.length; i++) {
        // ////console.log("dots",dots.length)
        dots[i].className = dots[i].className.replace(" activeslide", "");
      }
      if (slides[slideIndex - 1] && slides[slideIndex - 1].style) {
        slides[slideIndex - 1].style.display = "block";
      }
      if (dots[slideIndex - 1] && dots[slideIndex - 1].className) {
        dots[slideIndex - 1].className += " activeslide";
      }

      setTimeout(showSlides, 5000); // Change image every 5 seconds
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.UserLoginFailure) {
      return {
        ...nextProps,
        failureMSG:
          "If this is registered username/Mail ID you will recieve one time password reset link",
      };
    }
    if (nextProps.user.UserLoginOTPFailure) {
      return {
        ...nextProps,
        failureOTPMSG: "Invalid or expired OTP !",
      };
    }
    if (nextProps.user.UserLoginEmailSuccess) {
      return {
        ...nextProps,
        // showLogin:false
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // handleKeyDown(e) {
  //   if (e.key === "Enter") {
  //     if (this.state.showLogin) {
  //       if (!this.validateEmail(this.state.email)) {
  //         this.setState({ failureMSG: "Please enter a valid email address." });
  //         return;
  //       }
  //       this.loginSubmit();
  //     } else {
  //       if (this.state.otp_code.length === 0) {
  //         this.setState({ failureOTPMSG: "Please enter the OTP." });
  //         return;
  //       }
  //       this.OTPSubmit();
  //     }
  //   }
  // }

  validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  handleKeyDown(e) {
    console.log("Key pressed:", e.key); // Log key pressed
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action
      if (this.state.showLogin) {
        if (!this.validateEmail(this.state.email)) {
          this.setState({ failureMSG: "Please enter a valid email address." });
          return;
        }
        this.loginSubmit();
      } else {
        if (this.state.otp_code.length === 0) {
          this.setState({ failureOTPMSG: "Please enter the OTP." });
          return;
        }
        this.OTPSubmit();
      }
    }
  }




  loginSubmit = () => {
    let { email } = this.state;
    this.props.dispatch(userActions.userGrowlogin({ username: email }, this.props));
    this.setState({ failureMSG: "", showLogin: false });
  };

  changeUsername = () => {
    this.setState({ showLogin: true, failureMSG: "", otp_code: "", email: "" });
  };

  OTPSubmit = () => {
    const { user } = this.props;
    const { UserEmailToken } = user;
    // ////console.log("UserEmailToken: ",UserEmailToken)
    let { otp_code } = this.state;
    let data = {
      token: UserEmailToken,
      otp: otp_code,
    };
    // ////console.log("data: ",data)
    this.setState({ failureOTPMSG: "" });

    this.props.dispatch(userActions.validateGrowOtp(data, this.props));
  };

  render() {
    const { user } = this.props;
    const { loginOtpErrorMSG, UserLoginErrorMSG, userLoginStatus } = user;

    let exceedMSG = "";
    if (loginOtpErrorMSG === "Exceeded no of attempts.") {
      exceedMSG = "Exceeded no of attempts, OTP section is locked for 60 min.";
    }

    let lockedMSG = "";
    if (UserLoginErrorMSG === "Your Account is locked for some time.") {
      lockedMSG = "Your Account is locked for some time.";
    } else if (
      UserLoginErrorMSG &&
      UserLoginErrorMSG.message &&
      UserLoginErrorMSG.message === "Too Many Requests"
    ) {
      lockedMSG = "Too many request, please try again after 10 min";
    }

    let emailAttemptExceed = "";
    if (userLoginStatus === 429) {
      emailAttemptExceed = "Too many request, please try again after 10 min";
    }

    ////console.log("UserLoginErrorMSG------>", UserLoginErrorMSG);
    ////console.log("emailAttemptExceed", emailAttemptExceed);

    return (
      <section className="h-screen flex relative ">
        <div className="  bg-gradient-to-b from-[#00BFA5] to-[#009688] 2xl:w-[25%] xl:w-[30%] lg:w-[40%] h-full lg:flex hidden flex-col justify-between flex-shrink-0   ">
          <div className="text-center px-10 pt-20 space-y-8">
            <h1 className="xl:text-5xl lg:text-4xl font-semibold text-white xl:leading-tight    ">
              Leadership Development Program
            </h1>
            <p className="text-white text-xl">A Reflection Journey to <br />Transformative Leadership.</p>
          </div>
          <img src="/img/grow/grow-plus-login-img.svg" />

        </div>
        <div className="bg-white h-sceen w-full relative flex items-center">

          {this.state.showLogin ?
            <>
              <div className="2xl:w-6/12 xl:w-7/12 md:w-10/12 mx-auto md:px-0 px-6 ">
                <div className="space-y-10">
                  <img src="/img/grow/groplus-logo.svg" alt="groplus-logo" className="object-cover cursor-pointer md:w-56 w-40" />
                  <div className="space-y-3 ">
                    <h1 className="md:text-4xl text-3xl font-semibold text-[#212121]">Sign in to GrowPlus</h1>
                    <p>Enter your email address, We will send you an OTP to verified Email ID.</p>
                  </div>
                  <div className="space-y-2 ">
                    <p className="text-lg text-[#212121] font-medium">Enter Email</p>
                    <input className="border p-4 w-full rounded-md"
                      type="email"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={this.inputChange}
                      onKeyDown={this.handleKeyDown}
                      placeholder="example@gmail.com"
                    />

                    {lockedMSG ? (
                      <p className="text-gray-500 text-sm py-2 ">
                        We will send you an OTP (One Time Password) on this email
                        address.
                      </p>
                    ) : (
                      <>
                      </>
                    )}
                  </div>
                  <button
                    disabled={this.state.email.length === 0}
                    onClick={() => this.loginSubmit()}
                    className='flex items-center justify-center space-x-2 bg-[#009688] px-8 py-3 rounded-md text-white uppercase w-full text-lg text-center font-medium cursor-pointer'>
                    GET OTP
                  </button>
                </div>

              </div>
            </>
            :
            <>
              <div className="w-6/12 mx-auto ">
                <div className="space-y-10">
                  <img src="/img/grow/groplus-logo.svg" alt="groplus-logo" className="object-cover cursor-pointer md:w-56 w-28" />
                  <div className="space-y-3 ">
                    <h1 className="text-3xl font-semibold text-[#212121]">Verification</h1>
                    <p>We have sent a 6-digit OTP to your registered email address.</p>
                  </div>
                  <div className="space-y-2 ">
                    <p className="text-lg text-[#212121] font-medium">Enter OTP</p>
                    <input className="border p-4 w-full rounded-md no-scrollbar"
                      type="number"
                      name="otp_code"
                      required
                      value={this.state.otp_code}
                      onChange={this.inputChange}
                      onKeyDown={this.handleKeyDown}
                      placeholder="Enter OTP"
                    />
                    {exceedMSG ? (
                      <p className="text-gray-500 text-sm py-2 ">
                        {exceedMSG}
                      </p>
                    ) : (
                      <>
                      </>
                      // <p className="text-gray-500 text-sm py-2 ">
                      //   {this.state.failureOTPMSG}
                      // </p>}
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-[#3D405B] font-medium text-sm py-2">Didn't receive an OTP message?</p>
                    <button
                      onClick={() => this.loginSubmit()}
                      className="border border-[#009688] text-[#009688] rounded-md  px-4 py-2 text-sm hover:bg-[#2196F3]/10 transition duration-150 font-medium"
                    >
                      Resend OTP
                    </button>
                  </div>
                  <div
                    disabled={this.state.otp_code.length === 0}
                    onClick={() => this.OTPSubmit()}
                    className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#009688] text-white overflow-hidden"
                  >
                    <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                    VERIFY
                  </div>
                </div>

              </div>
            </>
          }

          <div className='border-t flex items-center justify-center py-4 bottom-0 bg-white absolute w-full'>
            <p className="text-[#3D405B] px-4 md:text-base text-sm text-center">All Rights Reserved | Copyright © 2024 GrowPlus.</p>
          </div>


        </div>

        {/* Modal */}
        {/* <div className="fixed top-0 w-full left-0 flex items-center justify-center h-screen bg-black/60 hidden">
            <div className="bg-white w-[36rem] p-8 py-10 rounded-2xl space-y-8">
              <div className="text-center space-y-2">
                <h1 className="text-[#212121] font-semibold text-4xl">
                  Well Done! <b className="text-[#009688] font-semibold">Hari</b>
                </h1>
                <p className="text-[#212121]">You have successfully completed your <b>Month 1 - Planning journey!</b>  You are on the right track.</p>

              </div>

              <img src="/img/grow/grow-plus-login-modal.svg" className="mx-auto w-5/6" />
              <div className="flex items-center space-x-4">
                <button className='flex items-center justify-center uppercase space-x-2   px-8 py-2.5 rounded-md text-[#212121]/70  w-full text-base text-center font-semibold border border-[#CFD8DC]'>
                  Close
                </button>
                <button className='flex items-center justify-center uppercase space-x-2 bg-[#009688] px-8 py-2.5 rounded-md text-white  w-full text-base text-center font-medium'>
                  Continue
                </button>
              </div>

            </div>

          </div> */}


      </section>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard,
  };
}
export default connect(mapStateToProps)(GrowLogin);
