import React, { Component } from "react";
import { connect } from "react-redux";
// import Filter from './Filter';
import Filter from "./Filter/Filter";
import Commentary from "../../../Index/Components/Commentary/Commentary";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,
      showFilter: false,
      showValue: "",
      DimensionList: [
        {
          index: 1,
          name: "Happiness",
          cutOff: 59,
          range: 72,
          type: "",
        },
        {
          index: 2,
          name: "Engagement",
          cutOff: 78,
          range: 100,
          type: "%",
        },
        {
          index: 4,
          name: "Stress",
          cutOff: 28,
          range: 60,
          type: "%",
        },
        {
          index: 5,
          name: "Manager",
          cutOff: 59,
          range: 100,
          type: "%",
        },
        {
          index: 6,
          name: "Leader",
          cutOff: 50,
          range: 100,
          type: "%",
        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1: {
        index: 1,
        name: "Happiness",
        cutOff: 59,
        range: 72,
        type: "",
      },
      selectedItem2: {
        index: 2,
        name: "Engagement",
        cutOff: 78,
        range: 100,
        type: "%",
      },

      currentSelectValue: "",
      FilterValues: [],
      showQueDrop: false,
      storeQues1: "",
      storeQues2: "",
    };
  }
  componentDidMount() { }

  handleViewScore = (data) => {
    this.setState({ viewScore: data });
  };

  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false });
  };
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false });
  };

  changeItem1 = (item) => {
    this.setState({ selectedItem1: item });
    this.setState({ showDropDown1: false, showDropDown2: false });
  };

  changeItem2 = (item) => {
    this.setState({ selectedItem2: item });
    this.setState({ showDropDown1: false, showDropDown2: false });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultiFilterAdvanc4Quadrant } = this.props;
    this.setState({ FilterValues: finalFilter });
    // handleMultiFilterAdvanc4Quadrant(finalFilter,true, name);
  };

  openQuesDrop = (check, no) => {
    if (no === 1) {
      this.setState({ showQueDrop1: check, showQueDrop2: false });
    }
    if (no === 2) {
      this.setState({ showQueDrop2: check, showQueDrop1: false });
    }
  };

  handleSelectQues = (Ques, no) => {
    if (no === 1) {
      this.setState({ storeQues1: Ques, showQueDrop1: false });
    }
    if (no === 2) {
      this.setState({ storeQues2: Ques, showQueDrop2: false });
    }
  };

  handleSaveCrossTab = () => {
    let { handleCrossTabApi } = this.props;
    let { storeQues1, storeQues2, FilterValues } = this.state;

    let list = [];

    if (storeQues1 && storeQues2) {
      let NewFilter = [];
      if (FilterValues && FilterValues.length > 0) {
        FilterValues.forEach((filter) => {
          if (filter.value !== "All") {
            NewFilter.push(filter);
          }
        });
      }
      handleCrossTabApi(storeQues1, storeQues2, NewFilter);
    }
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  render() {
    let {
      crossTabAnalysisData,
      indexType,
      optionType,
      loading,
      getCommentData,
      getIndexData,
      getDemographicIndexData,
      EmpFilterData,
      getIndexFilterData,
    } = this.props;

    let {
      showQueDrop1,
      showQueDrop2,
      showDropDown1,
      showDropDown2,
      DimensionList,
      selectedItem1,
      selectedItem2,
      currentSelectValue,
      FilterValues,
    } = this.state;

    console.log(
      "crossTabAnalysisData:-----------------------------------------------------> ",
      crossTabAnalysisData
    );

    let optionList1 = [];
    let optionList2x = [];
    if (crossTabAnalysisData && crossTabAnalysisData.length > 0) {
      crossTabAnalysisData.forEach((item) => {
        let getIndex1 = optionList1.findIndex((prev) => prev === item[0]);
        if (getIndex1 === -1) {
          optionList1.push(item[0]);
        }

        let getIndex2 = optionList2x.findIndex((prev) => prev === item[1]);
        if (getIndex2 === -1) {
          optionList2x.push(item[1]);
        }
      });
    }

    console.log(
      "optionList1:-----------------------------------------------------> ",
      optionList1
    );
    console.log(
      "optionList2x:-----------------------------------------------------> ",
      optionList2x
    );

    function ordering(name) {
      let order = 111;
      if (name === "<25%") {
        order = 1;
      } else if (name === "25-50%") {
        order = 2;
      } else if (name === "50 - 75%") {
        order = 3;
      } else if (name === "More than 75%") {
        order = 4;
      } else if (name === "Yes") {
        order = 1;
      } else if (name === "No") {
        order = 2;
      } else if (name === "0") {
        order = 0;
      } else if (name === "1") {
        order = 1;
      } else if (name === "2") {
        order = 2;
      } else if (name === "3") {
        order = 3;
      } else if (name === "4") {
        order = 4;
      } else if (name === "5") {
        order = 5;
      } else if (name === "6") {
        order = 6;
      } else if (name === "7") {
        order = 7;
      } else if (name === "8") {
        order = 8;
      } else if (name === "9") {
        order = 9;
      } else if (name === "10") {
        order = 10;
      }

      return order;
    }

    let tempDat = [];
    if (optionList2x && optionList2x.length > 0) {
      optionList2x.forEach((item) => {
        tempDat.push({
          name: item,
          number: ordering(item),
        });
      });
    }

    let optionList2 = [];
    tempDat.sort((a, b) => a.number - b.number);

    if (tempDat && tempDat.length > 0) {
      tempDat.forEach((item1) => {
        optionList2.push(item1.name);
      });
    }

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    // let DistributionIndexData = [];
    //  let DemographicDataDistribution = [];
    console.log("EmpFilterData: ", EmpFilterData);

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }
    }

    let { questionsList, crossTabData } = this.props;
    let { showQueDrop, storeQues1, storeQues2 } = this.state;

    let storeQuesIDs = [];

    if (storeQues1) {
      storeQuesIDs.push(storeQues1);
    }
    if (storeQues2) {
      storeQuesIDs.push(storeQues2);
    }

    console.log("storeQues1", storeQues1 && storeQues1.id ? storeQues1.id : "");
    console.log("storeQues2", storeQues2 && storeQues2.id ? storeQues2.id : "");

    // let OptionList = [
    //   "Strongly Agree",
    //   "Agree",
    //   "Slightly Agree",
    //   "Slightly Disagree",
    //   "Disagree",
    //   "Strongly Disagree"
    // ];

    let QuestionObj = {};
    if (storeQuesIDs && storeQuesIDs.length > 0) {
      storeQuesIDs.forEach((QUE) => {
        QuestionObj[QUE.id] = {};
      });

      storeQuesIDs.forEach((QUE) => {
        if (crossTabData && crossTabData.length > 0) {
          optionList1.forEach((opt) => {
            crossTabData.forEach((cross) => {
              if (cross._id.questionId === QUE.id) {
                let temp = {
                  name: cross._id.answerText,
                  count: cross.count,
                };
                if (opt === cross._id.answerText) {
                  QuestionObj[QUE.id][opt] = cross.count;
                }
              }
            });
          });
        }
      });
    }

    console.log("Cross crossTabAnalysisData: ", crossTabAnalysisData);

    let ScoreListNew = [];
    // ScoreListNew = [storeQues1,storeQues2];

    if (crossTabAnalysisData && crossTabAnalysisData.length > 0) {
      crossTabAnalysisData.forEach((item) => {
        let AnsQue1 = item[0];
        let AnsQue2 = item[1];
        let AnsScore = item[2];
        let AnsPerc = item[3];

        let getIndex = ScoreListNew.findIndex((prev) => prev.q1 === AnsQue1);

        if (getIndex === -1) {
          let temp = {
            q1: AnsQue1,
          };
          temp[AnsQue2] = {
            count: AnsScore,
            per: AnsPerc,
          };
          ScoreListNew.push(temp);
        } else {
          if (
            !(
              ScoreListNew &&
              ScoreListNew[getIndex] &&
              ScoreListNew[getIndex][AnsQue2]
            )
          ) {
            ScoreListNew[getIndex][AnsQue2] = {
              count: AnsScore,
              per: AnsPerc,
            };
          }
        }
      });
    }


    let ScoreList = [];
    if (optionList1 && optionList1.length > 0) {
      optionList1.forEach((opt) => {
        if (ScoreListNew && ScoreListNew.length > 0) {
          let flag = 0;
          ScoreListNew.forEach((item) => {
            if (item.q1 === opt) {
              ScoreList.push({
                ...item,
                number: ordering(opt),
              });
              flag++;
            }
          });
          if (!flag) {
            let temp = {
              q1: opt,
              number: ordering(opt),
            };
            optionList2.forEach((opt1) => {
              temp[opt1] = {
                count: 0,
                per: 0,
              };
            });
            ScoreList.push(temp);
          }
        }
      });
    }
    if (ScoreList && ScoreList.length > 0) {
      ScoreList.sort((a, b) => a.number - b.number);
    }

    // 0:
    // Agree:
    // count: 258
    // per: 22.872340425531913
    // __proto__: Object
    // Disagree: {count: 0,

    function valueFn(storeId, no, option) {
      let count = 0;
      if (storeId && storeId[no] && storeId[no].id) {
        let Id = storeId[no].id;
        if (QuestionObj && QuestionObj[Id] && QuestionObj[Id][option]) {
          count = QuestionObj[Id][option];
        }
      }
      return "-";
    }


    function getColor(dmgScore) {
      let score = parseFloat(dmgScore);
      if (score >= 0 && score < 1) {
        return { Color: "linear-gradient(90deg, #cfddfc, #e7eefd)", diffTextColor: "#000" };
      } else if (score >= 1 && score < 5) {
        return { Color: "linear-gradient(90deg, #9fbaf9, #cfddfc)", diffTextColor: "#000" };
      } else if (score >= 5 && score < 10) {
        return { Color: "linear-gradient(90deg, #6f98f6, #9fbaf9)", diffTextColor: "#000" };
      } else if (score >= 10 && score < 25) {
        return { Color: "linear-gradient(90deg, #3f75f3, #6f98f6)", diffTextColor: "#ffffff" };
      } else if (score >= 25 && score <= 100) {
        return { Color: "linear-gradient(90deg, #0f53f0, #3f75f3)", diffTextColor: "#ffffff" };
      }
    }




    console.log("Cross ScoreList3:--------------------> ", ScoreList);

    let sortedOptions = ['Slightly Disagree', 'Disagree', 'Slightly Disagree', 'Slightly Agree', 'Agree', 'Strongly Agree']

    let optionList3 = []
    if (optionList2 && optionList2.length > 0) {
      optionList3 = optionList2.sort((a, b) => sortedOptions.indexOf(a) - sortedOptions.indexOf(b));
    }

    let ScoreList3 = []
    if (ScoreList && ScoreList.length > 0) {
      ScoreList3 = ScoreList.sort((a, b) => sortedOptions.indexOf(a.q1) - sortedOptions.indexOf(b.q1));
    }

    return (
      <>
        <div className="flex flex-wrap items-center justify-between gap-4">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          <Commentary
            indexType={indexType}
            optionType={optionType}
            getCommentData={getCommentData}
          />
        </div>

        {true ? (
          <>
            {/* 
      {FilterValues && FilterValues.length > 0 ?
          <div className="items-center h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex justify-left">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null} */}

            <div onClick={() => this.closeFilter(false)} className="">
              <div className="w-full space-y-4 ">
                <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B]  ">
                    CrossTab Question Selection
                  </h1>

                  <div className="flex flex-col gap-4 md:flex-row">
                    <div
                      onClick={() => this.openQuesDrop(!showQueDrop1, 1)}
                      className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 relative w-full justify-between"
                    >
                      <h1 className="">
                        {storeQues1 && storeQues1.name
                          ? storeQues1.name
                          : "Select Question 1"}
                      </h1>

                      <span
                        className={
                          showQueDrop
                            ? "material-symbols-outlined rotate-180 "
                            : "material-symbols-outlined rotate-0 "
                        }
                      >
                        arrow_drop_down
                      </span>

                      {showQueDrop1 ? (
                        <div className="absolute z-10 py-2 overflow-y-auto font-normal bg-white border rounded-lg shadow-lg -left-2 w-fit top-12 h-60 scollar-xs-blue ">
                          {questionsList && questionsList.length > 0
                            ? questionsList.map((question, index) =>
                              storeQues2.id !== question.id ? (
                                <span
                                  key={index}
                                  onClick={() =>
                                    this.handleSelectQues(question, 1)
                                  }
                                  className={
                                    storeQues1.id === question.id
                                      ? "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                      : "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                  }
                                >
                                  {question.name}
                                </span>
                              ) : null
                            )
                            : null}
                        </div>
                      ) : null}
                    </div>

                    <div
                      onClick={() => this.openQuesDrop(!showQueDrop2, 2)}
                      className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 relative w-full justify-between"
                    >
                      <h1 className="">
                        {storeQues2 && storeQues2.name
                          ? storeQues2.name
                          : "Select Question 2"}
                      </h1>
                      <span
                        className={
                          showQueDrop
                            ? "material-symbols-outlined rotate-180 "
                            : "material-symbols-outlined rotate-0 "
                        }
                      >
                        arrow_drop_down
                      </span>

                      {showQueDrop2 ? (
                        <div className="absolute z-10 py-2 overflow-y-auto font-normal bg-white border rounded-lg shadow-lg -left-2 w-fit top-12 h-60 scollar-xs-blue ">
                          {questionsList && questionsList.length > 0
                            ? questionsList.map((question, index) =>
                              storeQues1.id !== question.id ? (
                                <span
                                  key={index}
                                  onClick={() =>
                                    this.handleSelectQues(question, 2)
                                  }
                                  className={
                                    storeQues2.id === question.id
                                      ? "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                      : "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                                  }
                                >
                                  {question.name}
                                </span>
                              ) : null
                            )
                            : null}
                        </div>
                      ) : null}
                    </div>

                    <button
                      onClick={() => this.handleSaveCrossTab()}
                      className=" cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md border border-[#2196f3]"
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="md:p-6 space-y-6 p-4 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] ">
                    CrossTab Comparison
                  </h1>

                  <div className=" ">
                    {ScoreList3 && ScoreList3.length > 0 ? (
                      <div className="w-full overflow-x-auto customscroll2 ">
                        <table className="w-full border border-white  whitespace-nowrap  ">
                          <thead className>
                            <tr className="  border-b border-white text-sm  text-[#212121]">
                              <th className="p-3 border-r border-white font-bold lg:w-[10%] w-[20%]">
                                <div className="flex items-center justify-center"></div>
                              </th>
                              <th className="p-3 border-r border-white font-bold lg:w-[10%]">
                                <div className="flex items-center justify-center"></div>
                              </th>
                              <th
                                colSpan={
                                  optionList2 && optionList2.length > 0
                                    ? optionList2.length
                                    : 0
                                }
                                className="p-3 border-r border-white font-medium text-center text-base bg-[#F1F5F9] whitespace-normal lg:w-[80%]"
                              >
                                {storeQues2 && storeQues2.name
                                  ? storeQues2.name
                                  : "Question 2"}

                              </th>
                            </tr>

                            <tr className=" border-b border-white text-sm  text-[#212121]">
                              <th className="p-3 font-bold border-r border-white   ">
                                <div className="flex items-center justify-center"></div>
                              </th>

                              <th className="p-3 font-bold border-r border-white">
                                <div className="flex items-center justify-center"></div>
                              </th>

                              {optionList3 && optionList3.length > 0
                                ? optionList3.map((option) => (
                                  <th className="p-3 border-r border-white font-medium  w-[10%] text-center"> 
                                    {option} 
                                  </th>
                                ))
                                : null}
                            </tr>
                          </thead>
                          <tbody>
                            {/* one row table */}
                            {ScoreList3 && ScoreList3.length > 0
                              ? ScoreList3.map((item, index) => (
                                <tr className=" border-b border-white text-sm  text-[#212121]">
                                  {index === 0 ? (
                                    <td
                                      className="p-3 text-base font-medium  text-center  border-r border-white bg-[#F1F5F9]  "
                                      rowSpan={
                                        ScoreList3 && ScoreList3.length > 0
                                          ? ScoreList3.length
                                          : 0
                                      }
                                    >
                                      <p className="rotate-180 writing-mode h-96 text-center flex justify-center items-center mx-auto lg:w-fit w-20 whitespace-normal "> {storeQues1 && storeQues1.name
                                        ? storeQues1.name
                                        : "Question 1"}</p>
                                    </td>
                                  ) : null}

                                  <td className="text-right border-r border-white whitespace-nowrap">
                                    {" "}
                                    <p className="p-3 font-medium ">
                                      {item.q1}
                                    </p>
                                  </td>

                                  {optionList3 && optionList3.length > 0
                                    ? optionList3.map((option) => (
                                      <>
                                        <td className="p-3 border-r border-white text-center"
                                          style={{
                                            minWidth: "60px",
                                            minHeight: "50px",
                                            color: getColor(
                                              item && item[option] && item[option].per ? item[option].per : "0"
                                            ).diffTextColor,
                                            background: getColor(
                                              item && item[option] && item[option].per ? item[option].per : "0"
                                            ).Color,
                                          }}>


                                          <div className="flex space-x-2 items-center justify-center">
                                            <span className="font-medium text-base">
                                              {item &&
                                                item[option] &&
                                                item[option].count
                                                ? item[option].count
                                                : 0}
                                            </span>
                                            {" "}
                                            <span className="text-text-900 text-sm ">
                                              ({item &&
                                                item[option] &&
                                                item[option].per
                                                ? item[option].per.toFixed(
                                                  2
                                                ) + "%"
                                                : "0%"})
                                            </span>
                                          </div>
                                        </td>
                                      </>
                                    ))
                                    : null}
                                </tr>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="text-xl text-center text-gray-500">
                          Please select both questions
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400">
                We have not calculated this section!
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(SummaryData);
