import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
// import Filter from './Filter/Filter';
// import { CSVLink } from "react-csv";
// import EmployeeDropdown from '../Filter/EmployeeDropdown';
// import DimensionList from './Filter/DimensionList';



class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {},
      crrSelectedDimension: ""
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handle360GetQuestionFilter } = this.props;
    this.setState({ FilterValues: finalFilter });
    handle360GetQuestionFilter(finalFilter, "", "", false);

  }
  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { handle360GetQuestionFilter } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      handle360GetQuestionFilter(FilterValues, "", "", false, emp_id)

    })
  }

  handleSelectDimension = (value) => {
    this.setState({ crrSelectedDimension: value })
  }

  render() {
    let { selectedFilterValue, loading,
      indexType, optionType, getCommentData, CountRestrict, getEMPFilterLabel, getAnsweredEmployeesData,

      getQuestionData, getQuestionFilterData, EmpFilterData, getIndexFilterData, getIndexByFilterDMGData,
      sequence_rel

    } = this.props;
    let { GetParameterLabel, name } = this.props;

    let { currentSelectValue, FilterValues } = this.state;
    let { crrSelectedDimension } = this.state;

    console.log('crrSelectedDimension---------', crrSelectedDimension)


    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    let parameterList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((quesX) => {
        if (quesX && quesX.parameterDimensionName) {
          let getIndex = parameterList.findIndex(prev => prev === quesX.parameterDimensionName);
          if (getIndex === -1) {

            parameterList.push(quesX.parameterDimensionName);



          }
        }
      })
    }


    function getRelationScore(question, relation, type, answer) {
      let mean = 0;
      // let sum = question && question[relation + "_sum"] ? question[relation + "_sum"] : 0;
      let count = question && question[relation + "_count"] ? question[relation + "_count"] : 0;

      if (type === "mean") {

        function getScore(answerA) {
          return question && question[relation + "_answer"] && question[relation + "_answer"][answerA] ? question[relation + "_answer"][answerA] : 0
        }

        let sum = 0
        let listX = [1, 2, 3, 4, 5, 6];
        listX.forEach((element) => {
          let score = getScore(element.toString())
          if (score) {
            sum += (element * score)
          }
        })

        mean = Number(sum / count);
      } else if (type === "count") {
        mean = Number(count);
      }
      else if (type === "answer") {
        mean = question && question[relation + "_answer"] && question[relation + "_answer"][answer] ? question[relation + "_answer"][answer] : ""
      }

      return mean
    }



    function sortRelation(list) {
      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev === "Self")
        let List2 = list.filter(prev => prev !== "Self")
        let listMerged = List1.concat(List2)
        // var first = "Self";
        // list.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });
        return listMerged
      } else {
        return []
      }
    }

    function getQuesByDimen(dimen) {
      if (QuestionData && QuestionData.length > 0) {
        let quesList = QuestionData.filter(prev => prev.parameterDimensionName === dimen);
        return quesList
      } else {
        return []
      }
    }
    function getDimenScore(list) {
      let overall = 0;
      let oSum = 0
      let oCount = 0

      if (list && list.length > 0) {
        list.forEach((question) => {
          let mean1 = 0;
          let sum1 = 0;
          let coun1 = 0;
          if (question.relationList && question.relationList.length > 0) {
            question.relationList.forEach((relation, index2) => {
              if (relation !== 'Self') {
                sum1 += Number(getRelationScore(question, relation, "mean"));
                coun1++
              }
            })
          }
          mean1 = (sum1 / coun1)
          oSum += mean1;
          oCount++

        })
      }
      overall = Number(oSum / oCount)
      return overall ? overall.toFixed(2) : 0
    }


    let ParameterNameList = []
    let DimensionV2List = []
    let SelfScoreListOrdered = [];
    let OtherScoreListOrdered = [];

    function getScoreValueX(questionXList, relation) {
      let scoreCalc = 0
      let oSum = 0;
      let oCount = 0;
      if (questionXList && questionXList.length > 0) {
        questionXList.forEach((item) => {
          let questionX = item;
          let scoreMean = 0;
          if (relation !== "Self") {
            let otherSum = 0;
            let otherCount = 0;

            if (questionX && questionX.relationList && questionX.relationList.length > 0) {
              questionX.relationList.forEach((relation) => {
                if (relation !== "Self") {
                  otherCount += Number(questionX[relation + "_count"])


                  otherSum += Number(questionX[relation + "_sum"]);
                }
              })
            }
            scoreMean = Number((otherSum / otherCount))
          }
          else {
            scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);

          }
          // return [Number((scoreMean))]
          // scoreList.push(Number((scoreMean)))
          oSum += Number((scoreMean))
          oCount += 1
        })
      }
      scoreCalc = oSum / oCount
      return scoreCalc ? scoreCalc.toFixed(2) : 0
    }



    if (getQuestionData && getQuestionData.length > 0) {
      getQuestionData.forEach((element) => {
        let getIndex6 = ParameterNameList.findIndex(prev => prev.name === element.parameterDimensionName);
        if (getIndex6 === -1) {
          DimensionV2List.push(GetParameterLabel(element.parameterDimensionName))

          let SelfScore = getScoreValueX(getQuesByDimen(element.parameterDimensionName), 'Self');
          let OtherScore = getScoreValueX(getQuesByDimen(element.parameterDimensionName), 'Others');

          SelfScoreListOrdered.push(SelfScore)
          OtherScoreListOrdered.push(OtherScore)


          ParameterNameList.push({
            "name": element.parameterDimensionName,
            "self": SelfScore,
            "others": OtherScore
          })


        }

      })
    }




    function getDimenScoreNew(dimen) {
      let score = 0
      if (ParameterNameList && ParameterNameList.length > 0) {
        let getIndex11 = ParameterNameList.findIndex(prev => prev.name === dimen);
        if (getIndex11 !== -1) {
          score = ParameterNameList[getIndex11]["others"]
        }
      }
      return score
    }






    let newQuestionlist = []
    // if (parameterList && parameterList.length > 0) {
    //   parameterList.forEach((item, index) => {


    //     if (getQuesByDimen(item) && getQuesByDimen(item).length > 0) {
    //       if (getQuesByDimen(item).length > 2) {
    //         newQuestionlist.push({
    //           "dimension": item,
    //           "score": getScoreValueX(getQuesByDimen(item), 'Others'),
    //           "questionList": getQuesByDimen(item).slice(0, 3),
    //           "alpha": 0
    //         })
    //         newQuestionlist.push({
    //           "dimension": item,
    //           "score": getScoreValueX(getQuesByDimen(item), 'Others'),
    //           "questionList": getQuesByDimen(item).slice(3, 6),
    //           "alpha": 3
    //         })
    //         newQuestionlist.push({
    //           "dimension": item,
    //           "score": getScoreValueX(getQuesByDimen(item), 'Others'),
    //           "questionList": getQuesByDimen(item).slice(6, getQuesByDimen(item).length > 6?9:getQuesByDimen(item).length),
    //           "alpha": 6
    //         })

    //         if(getQuesByDimen(item).length > 9){
    //           newQuestionlist.push({
    //             "dimension": item,
    //             "score": getScoreValueX(getQuesByDimen(item), 'Others'),
    //             "questionList": getQuesByDimen(item).slice(9, getQuesByDimen(item).length),
    //             "alpha": 9
    //           })

    //         }


    //       } else {
    //         newQuestionlist.push({
    //           "dimension": item,
    //           "score": getScoreValueX(getQuesByDimen(item), 'Others'),
    //           "questionList": getQuesByDimen(item),
    //           "alpha": 0
    //         })
    //       }

    //     }

    //     // if (crrSelectedDimension) {
    //     //   if (crrSelectedDimension === item) {
    //     //     newQuestionlist.push({
    //     //       "dimension": item,
    //     //       "questionList": getQuesByDimen(item)
    //     //     })
    //     //   }


    //     // } else {
    //     //   if (index < 1) {
    //     //     newQuestionlist.push({
    //     //       "dimension": item,
    //     //       "questionList": getQuesByDimen(item)
    //     //     })
    //     //   }


    //     // }



    //   })
    // }


    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item, index) => {


        if (getQuesByDimen(item) && getQuesByDimen(item).length > 0) {
          if (getQuesByDimen(item).length > 2) {

            const chunkSize = 3;
            for (let i = 0; i < getQuesByDimen(item).length; i += chunkSize) {
              const chunk = getQuesByDimen(item).slice(i, i + chunkSize);

              newQuestionlist.push({
                "dimension": item,
                "score": getScoreValueX(chunk, 'Others'),
                "questionList": chunk,
                "alpha": i
              })

            }


          } else {
            newQuestionlist.push({
              "dimension": item,
              "score": getScoreValueX(getQuesByDimen(item), 'Others'),
              "questionList": getQuesByDimen(item),
              "alpha": 0
            })
          }

        }

      })
    }


    ////console.log("parameterList====>", parameterList)
    ////console.log("newQuestionlist====>", newQuestionlist)
    ////console.log("QuestionData====>", QuestionData)






    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }
    }




    function getParaQues(QuestionData22, item) {
      if (QuestionData22 && QuestionData22.length > 0) {
        return QuestionData.filter(prev => prev.parameterDimensionName === item)
      }
      else {
        return []
      }
    }


    function getParaQDetail(newQuestionlist, item) {
      if (newQuestionlist && newQuestionlist.length > 0) {
        return newQuestionlist.filter(prev => prev.dimension === item)
      }
      else {
        return []
      }
    }



    function getOverallScore(question, list) {
      let newList = sortRelation(list);
      let mean = 0;
      let count = 0;
      if (newList && newList.length > 0) {
        newList.forEach((relation) => {
          mean += getRelationScore(question, relation, "mean")
          count++

        })
      }
      let score = (mean / count);
      return score ? score.toFixed(2) : 0
    }

    // 1. #4CAF50  4 - 5
    // 2. #8BC34A 3 - 4
    // 3. #FFC107 2 - 3
    // 4. #EF5350 0- 2 

    function getColor(value) {
      let color = "#757575"
      if (value > 0 && value <= 2) {
        color = "#EF5350"
      } else if (value > 2 && value <= 3) {
        color = "#FFC107"
      } else if (value > 3 && value <= 5) {
        color = "#8BC34A"
      } else if (value > 5 && value <= 6) {
        color = "#4CAF50"
      }
      return color
    }

    let { userName, createdAt } = this.props;

    function sort2Order(fun1, fun2) {
      let list = []
      if (fun1 && fun1.length > 0) {
        list = fun1.sort((a, b) => fun2.indexOf(a) - fun2.indexOf(b));
      }
      return list
    }

    return (
      <>

        {newQuestionlist && newQuestionlist.length > 0 ?
          newQuestionlist.map((item, index11) =>

            (item && item.questionList && item.questionList.length > 0) ?

              <section className='p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                  <p className='font-medium italic text-base '>360 Feedback</p>
                  <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
                </div>
                <div className='space-y-4 flex flex-col  '>

                  <h1 className='text-2xl text-slate-800 font-medium capitalize'>{"Detailed  Feedback " + (index11 > 0 ? "(Continued)" : "")}</h1>

                  <div className='text-[#212121]  space-y-4 text-sm'>
                    <p className='italic'>The detailed statement-wise rating provides your complete group-wise breakdown of your feedback on each statement.</p>


                    <div key={index11} className="">
                      <div className='flex justify-between p-2 items-center capitalize'><p className='font-medium text-lg'>{GetParameterLabel(item.dimension)}</p>
                        <div className='flex'>
                          <span className='mx-2'>{"( Exclude Self ) "}</span>
                          <span className='bg-green-500 px-2 text-sm text-white' style={{ background: getColor(getDimenScoreNew(item.dimension)) }}>{getDimenScoreNew(item.dimension)}</span>
                        </div>

                      </div>
                      <table className=" border-collapse w-full">

                        <thead className="text-sm  capitalize bg-[#8BC34A]/20">
                          <tr className="border-b border-white">
                            <th className="font-semibold p-2 px-4 border-r-2 border-white text-left w-6/12">Question</th>
                            <th className="font-semibold p-2 px-4 border-r-2 border-white text-left w-3/12">rater</th>
                            <th className="font-semibold p-2 px-4  text-left w-3/12">Ratings</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y-8 divide-white">
                          {item && item.questionList && item.questionList.length > 0 ?
                            item.questionList.map((question, index) =>

                              <tr className='bg-[#F9F9F9]'>
                                <td className="p-2 px-4 border-r-2 border-white ">
                                  <p className='text-[#212121] '> {String.fromCharCode(97 + index + (item.alpha)) + ") " + question.name}</p>
                                </td>

                                <td className="p-2 " colSpan={2}>
                                  <div>
                                    <table className="">
                                      <tbody className="divide-y-2 divide-white">
                                        {sortRelation(question.relationList) && sortRelation(question.relationList).length > 0 ?
                                          sort2Order(sortRelation(question.relationList), sequence_rel).map((relation, index2) =>
                                            <tr className=''>
                                              <td className='flex w-40 items-center' style={{ width: '200px' }}>
                                                <p className='text-right px-3 space-x-4 h-8 pt-1.5'>{relation.trim()}</p>
                                              </td>
                                              <td className='ml-2 '>
                                                <div className="flex items-center justify-center space-x-4 h-8" style={{ width: '150px' }}>
                                                  <div className="bg-gray-200 w-40 h-4 overflow-hidden">
                                                    <div style={{
                                                      width: (((parseFloat(getRelationScore(question, relation, "mean")) * 50) / 6) + "%"),
                                                      background: getColor(getRelationScore(question, relation, "mean"))
                                                    }}
                                                      className="bg-green-500 w-3/6 h-4" /></div>
                                                </div>
                                              </td>
                                              <td className='ml-2 px-2' style={{ marginLeft: '10px' }}>
                                                <p>{getRelationScore(question, relation, "mean").toFixed(2)}</p>
                                              </td>
                                            </tr>
                                          ) : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            ) : null}


                        </tbody>
                      </table>

                    </div>



                  </div>


                </div>

                {/* <div className='py-4 flex justify-between items-center'>
                  <img src="/img/logo/happypluslogo.png" class=" w-24 object-cover" alt="default_survey_logo" />
                  <p className='text-slate-600'>Page 1</p>
                  <div/>
                </div> */}

              </section>

              : null

          ) : null}


      </>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
