import React, { Component } from "react";
import { connect } from "react-redux";
import BarChart from "./Charts/BarChart";
import Commentary from "../../../Index/Components/Commentary/Commentary";
import Filter from "../../../../CommonComponents/Filters/IndexFilter/Filter";
import HeatMap from "./Charts/HeatMap";
import EDAOrgModal from "./EDAOrg/EDAModal";
import NudgesOrgOverview from "../../../../CommonComponents/Nudges/NudgesOrgOverview";
import DataNotFound from "../../../Components/DataNotFound";
class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentFilterName: "none",
      showTab: 1,
      FilterValues: [],
      ListName: "Demographic",
    };
  }
  componentDidMount() { }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterOrgDriverSummary } = this.props;
    this.setState({
      FilterValues: finalFilter,
      CurrentFilterName: name,
      ListName: name,
    });
    handleMultifilterOrgDriverSummary(finalFilter, true, name);
  };

  handleTab = (data) => {
    this.setState({ showTab: data });
  };

  handleFilternameSave = (name) => {
    this.setState({ CurrentFilterName: name });
  };

  handleSelectEDA = (data) => {
    let score = data && data.data ? data.data : "";
    let driverName = data && data.name ? data.name : "";
    console.log("score>>>-------->", score);
    console.log("driverName>>>-------->", driverName);

    if (score && score !== "NaN") {
      let { finalFilter, ListName } = this.state;
      let { getEDAOrgdata } = this.props;
      if (driverName) {
        getEDAOrgdata(driverName, "overall", finalFilter, ListName);
        this.setState({ showEDAModal: true, crrdriverName: driverName });

        // setTimeout(()=>{
        //   this.setState({ showEDAModal: true, crrdriverName: driverName});
        // },500)
      }
    }
  };

  handleDMGOrgFilter = (driverName, xAxisName, check) => {
    if (check && check !== "NaN") {
      let { finalFilter, ListName } = this.state;
      let { getEDAOrgdata } = this.props;
      if (driverName) {
        getEDAOrgdata(driverName, "heatmap", finalFilter, ListName, xAxisName);
        this.setState({ showEDAModal: true, crrdriverName: driverName });

        // setTimeout(()=>{
        //   this.setState({ showEDAModal: true, crrdriverName: driverName});
        // },500)
      }
    }
  };

  closeEDAModal = () => {
    this.setState({ showEDAModal: false, crrdriverName: "" });
  };

  render() {
    let {
      getDemographicIndexMultiAxisOrgData,
      ErrorIndexObj,
      getdmgOrgManyFilterData,
      getEMPFilterLabel,
      getIndexByFilterDMGData,
      CountRestrict,
      validateIndex,
      GetParameterLabel,
      getDistributionIndexData,
      getIndexFilterData,
      EmpFilterData,
      getIndexData,
      indexType,
      optionType,
      getCommentData,
      loading,
      getSurveyData,
    } = this.props;
    let { ListName, CurrentFilterName, showTab, FilterValues } = this.state;

    let validateExtraObj = {
      isManager: validateIndex && validateIndex["5"] ? true : false,
      isSeniorLeadership: validateIndex && validateIndex["6"] ? true : false,
    };

    let OrgCoreDriverData = {};
    if (validateExtraObj.isManager) {
      OrgCoreDriverData["Manager"] =
        getIndexData && getIndexData["Manager"] ? getIndexData["Manager"] : 0;
    }
    if (validateExtraObj.isSeniorLeadership) {
      OrgCoreDriverData["SeniorLeadership"] =
        getIndexData && getIndexData["SeniorLeadership"]
          ? getIndexData["SeniorLeadership"]
          : 0;
    }

    if (
      getIndexData &&
      Object.keys(getIndexData) &&
      Object.keys(getIndexData).length > 0
    ) {
      Object.keys(getIndexData).forEach((key) => {
        if (!key.includes("Count") && !key.includes("count")) {
          if (getIndexData[key]) {
            OrgCoreDriverData[key] = getIndexData[key];
          }
        }
      });
    }

    let valueList = [];
    if (OrgCoreDriverData && getIndexData) {
      valueList = Object.keys(OrgCoreDriverData).map((key) => [
        key,
        OrgCoreDriverData[key],
        getIndexData[key + "Count"],
      ]);
    }

    // console.log('getSurveyOrgList: ',getSurveyOrgList)
    console.log("getIndexData: ", getIndexData);
    console.log("OrgCoreDriverData: ", OrgCoreDriverData);
    console.log("valueList: ", valueList);

    let flag = 0;
    if (valueList && valueList.length > 0) {
      valueList.forEach((fil) => {
        if (fil && fil[1]) {
          flag += 1;
        }
      });
    }

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    let DemographicDataScore = [];
    let DemographicDataDistribution = [];

    // Download Excel

    let ScoreExcel = [];

    let filter =
      FilterValues && FilterValues.length > 0
        ? " (" +
        FilterValues.map((element) => {
          return element.value;
        }).join(",") +
        ")"
        : " (Filter)";

    if (valueList && valueList.length > 0) {
      let Overall = {};
      Overall[ListName + filter] = "Overall";

      if (valueList && valueList.length > 0) {
        valueList.forEach((data) => {
          if (data && data[0]) {
            Overall[data[0]] = data && data[1] ? data[1].toFixed(2) : 0;
          }
        });
      }

      ScoreExcel.push(Overall);
    }

    if (getDistributionIndexData && getDistributionIndexData.length > 0) {
      getDistributionIndexData.forEach((item) => {
        if (valueList && valueList.length > 0) {
          let Temp = {};
          Temp[ListName + filter] = item.name;

          if (valueList && valueList.length > 0) {
            valueList.forEach((data) => {
              if (data && data[0]) {
                let Driver = data[0] + "Avg";
                Temp[data[0]] =
                  item && item[Driver] ? item[Driver].toFixed(2) : 0;
              }
            });
          }

          ScoreExcel.push(Temp);
        }
      });
    }

    return (
      <>
        <div className="md:items-center justify-between flex md:flex-row flex-col gap-4">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />
          <div className="space-x-2 flex items-center ">

            <NudgesOrgOverview
              indexType={indexType}
              IndexNme={"Organisation"}
              name={"Organisation Overview"}
              dataItem={
                FilterValues && FilterValues.length > 0
                  ? {
                    show: true,
                  }
                  : {
                    show: false,
                  }
              }
              GetParameterLabel={GetParameterLabel}
              optionType={
                FilterValues && FilterValues.length > 0 ? "itemdetails" : null
              }
              CountRestrict={CountRestrict}
              handleDistributionFilterNameSave={
                this.handleDistributionFilterNameSave
              }
              handleDistributionFilterValueSave={
                this.handleDistributionFilterValueSave
              }
              getDistributionIndexData={getDistributionIndexData}
              valueList={valueList}
              currFilterName={CurrentFilterName}
              FilterValues={FilterValues}
              validateExtraObj={validateExtraObj}
            />

            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />
          </div>
        </div>

        {FilterValues && FilterValues.length > 0 ? (
          <>
            <div className=" ">
              <div className="w-full">
                <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">
                  <div className="bg-white hover:shadow-lg duration-150 transition  border md:p-4 p-4 rounded-lg space-y-6 cursor-pointer">
                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">Item Ranking</h1>

                    {ErrorIndexObj &&
                      ErrorIndexObj["getDemographicIndexOrgError"] ? (
                      <>
                        <div className="text-gray-400 font-medium text-center my-24 text-2xl">
                          {ErrorIndexObj["getDemographicIndexOrgError"]}
                        </div>
                      </>
                    ) : (
                      <>
                        {getDistributionIndexData ? (
                          <HeatMap
                            handleDistributionFilterNameSave={
                              this.handleDistributionFilterNameSave
                            }
                            handleDistributionFilterValueSave={
                              this.handleDistributionFilterValueSave
                            }
                            getDistributionIndexData={getDistributionIndexData}
                            valueList={valueList}
                            GetParameterLabel={GetParameterLabel}
                            currFilterName={CurrentFilterName}
                            FilterValues={FilterValues}
                            validateExtraObj={validateExtraObj}
                            CountRestrict={CountRestrict}
                            handleDMGOrgFilter={this.handleDMGOrgFilter}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {flag ? (
              <div className="w-full">
                <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">
                  <div className="md:p-4 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap  ">
                      Organization Core
                    </h1>


                    {ErrorIndexObj && ErrorIndexObj["getOrgIndexV2Error"] ? (
                      <>
                        <div className="text-gray-400 font-medium text-center my-24 text-2xl">
                          {ErrorIndexObj["getOrgIndexV2Error"]}
                        </div>
                      </>
                    ) : (
                      <BarChart
                        handleSelectEDA={this.handleSelectEDA}
                        valueList={valueList}
                        GetParameterLabel={GetParameterLabel}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!loading ? (
                  <div className="bg-white p-4 border rounded-lg hover:shadow-lg">
                    <DataNotFound />
                  </div>
                ) : null}
              </>
            )}
          </>
        )}

        <EDAOrgModal
          showModal={this.state.showEDAModal}
          closeModal={this.closeEDAModal}
          getdmgOrgManyFilterData={getDemographicIndexMultiAxisOrgData}
          indexType={indexType}
          dmgLevel={this.state.ListName}
          dmgValue={this.state.crrdriverName}
          GetParameterLabel={GetParameterLabel}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Overview);
